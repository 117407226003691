import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AntDesignModule } from 'src/app/modules/ant-design/ant-design.module';
import { HeaderComponent } from './header.component';
import { ConfigPanelHeaderComponent } from './components/config-panel-header/config-panel-header.component';
import { DynamicDataHostDirective } from './directives/dynamic-data-host.directive';

@NgModule({
  declarations: [HeaderComponent, ConfigPanelHeaderComponent, DynamicDataHostDirective],
  imports: [
    CommonModule,
    AntDesignModule,
    RouterModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [HeaderComponent]
})
export class HeaderModule { }
