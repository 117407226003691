import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'labelTransformer'
})
export class LabelTransformerPipe implements PipeTransform {

    transform(value: string): string {
        let words = (value.includes('_')) ? value.split('_') : value.split(' ');
        value = words.map(w => w.replace(w.charAt(0), w.charAt(0).toUpperCase())).join(' ')
        return value;
    }

}
