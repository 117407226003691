import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss'],
})
export class UserDetailsDialogComponent implements OnInit {
  userList = [];
  dataSource: any;
  userData = undefined;
  editUserForm: FormGroup;
  displayedColumns: string[] = ['name', 'value'];
  constructor(
    public dialogRef: MatDialogRef<UserDetailsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  }

  ngOnInit(): void {
    this.data.userDetails.map(temp=>{
      this.userList.push({ name: temp.key, value: temp.value });
    });
    this.dataSource = this.userList;
    
  }

}
