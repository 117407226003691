export const nationalities = [
    {
        "code": "AF",
        "en_short_name": "Afghanistan",
        "nationality": "Afghan"
    },
    {
        "code": "AX",
        "en_short_name": "Islands",
        "nationality": "Island"
    },
    {
    "code": "AL",
        "en_short_name": "Albania",
        "nationality": "Albanian"
    },
    {
        "code": "DZ",
        "en_short_name": "Algeria",
        "nationality": "Algerian"
    },
    {
        "code": "AS",
        "en_short_name": "American Samoa",
        "nationality": "American Samoan"
    },
    {
        "code": "AD",
        "en_short_name": "Andorra",
        "nationality": "Andorran"
    },
    {
        "code": "AO",
        "en_short_name": "Angola",
        "nationality": "Angolan"
    },
    {
        "code": "AI",
        "en_short_name": "Anguilla",
        "nationality": "Anguillan"
    },
    {
        "code": "AQ",
        "en_short_name": "Antarctica",
        "nationality": "Antarctic"
    },
    {
        "code": "AG",
        "en_short_name": "Antigua and Barbuda",
        "nationality": "Antiguan or Barbudan"
    },
    {
        "code": "AR",
        "en_short_name": "Argentina",
        "nationality": "Argentine"
    },
    {
        "code": "AM",
        "en_short_name": "Armenia",
        "nationality": "Armenian"
    },
    {
        "code": "AW",
        "en_short_name": "Aruba",
        "nationality": "Aruban"
    },
    {
        "code": "AU",
        "en_short_name": "Australia",
        "nationality": "Australian"
    },
    {
        "code": "AT",
        "en_short_name": "Austria",
        "nationality": "Austrian"
    },
    {
        "code": "AZ",
        "en_short_name": "Azerbaijan",
        "nationality": "Azerbaijani, Azeri"
    },
    {
        "code": "BS",
        "en_short_name": "Bahamas",
        "nationality": "Bahamian"
    },
    {
        "code": "BH",
        "en_short_name": "Bahrain",
        "nationality": "Bahraini"
    },
    {
        "code": "BD",
        "en_short_name": "Bangladesh",
        "nationality": "Bangladeshi"
    },
    {
        "code": "BB",
        "en_short_name": "Barbados",
        "nationality": "Barbadian"
    },
    {
        "code": "BY",
        "en_short_name": "Belarus",
        "nationality": "Belarusian"
    },
    {
        "code": "BE",
        "en_short_name": "Belgium",
        "nationality": "Belgian"
    },
    {
        "code": "BZ",
        "en_short_name": "Belize",
        "nationality": "Belizean"
    },
    {
        "code": "BJ",
        "en_short_name": "Benin",
        "nationality": "Beninese, Beninois"
    },
    {
        "code": "BM",
        "en_short_name": "Bermuda",
        "nationality": "Bermudian, Bermudan"
    },
    {
        "code": "BT",
        "en_short_name": "Bhutan",
        "nationality": "Bhutanese"
    },
    {
        "code": "BO",
        "en_short_name": "Bolivia (Plurinational State of)",
        "nationality": "Bolivian"
    },
    {
        "code": "BQ",
        "en_short_name": "Bonaire, Sint Eustatius and Saba",
        "nationality": "Bonaire"
    },
    {
        "code": "BA",
        "en_short_name": "Bosnia and Herzegovina",
        "nationality": "Bosnian or Herzegovinian"
    },
    {
        "code": "BW",
        "en_short_name": "Botswana",
        "nationality": "Motswana, Botswanan"
    },
    {
        "code": "BV",
        "en_short_name": "Bouvet Island",
        "nationality": "Bouvet Island"
    },
    {
        "code": "BR",
        "en_short_name": "Brazil",
        "nationality": "Brazilian"
    },
    {
        "code": "IO",
        "en_short_name": "British Indian Ocean Territory",
        "nationality": "BIOT"
    },
    {
        "code": "BN",
        "en_short_name": "Brunei Darussalam",
        "nationality": "Bruneian"
    },
    {
        "code": "BG",
        "en_short_name": "Bulgaria",
        "nationality": "Bulgarian"
    },
    {
        "code": "BF",
        "en_short_name": "Burkina Faso",
        "nationality": "Burkinab"
    },
    {
        "code": "BI",
        "en_short_name": "Burundi",
        "nationality": "Burundian"
    },
    {
        "code": "CV",
        "en_short_name": "Cabo Verde",
        "nationality": "Cabo Verdean"
    },
    {
        "code": "KH",
        "en_short_name": "Cambodia",
        "nationality": "Cambodian"
    },
    {
        "code": "CM",
        "en_short_name": "Cameroon",
        "nationality": "Cameroonian"
    },
    {
        "code": "CA",
        "en_short_name": "Canada",
        "nationality": "Canadian"
    },
    {
        "code": "KY",
        "en_short_name": "Cayman Islands",
        "nationality": "Caymanian"
    },
    {
        "code": "CF",
        "en_short_name": "Central African Republic",
        "nationality": "Central African"
    },
    {
        "code": "TD",
        "en_short_name": "Chad",
        "nationality": "Chadian"
    },
    {
        "code": "CL",
        "en_short_name": "Chile",
        "nationality": "Chilean"
    },
    {
        "code": "CN",
        "en_short_name": "China",
        "nationality": "Chinese"
    },
    {
        "code": "CX",
        "en_short_name": "Christmas Island",
        "nationality": "Christmas Island"
    },
    {
        "code": "CC",
        "en_short_name": "Cocos (Keeling) Islands",
        "nationality": "Cocos Island"
    },
    {
        "code": "CO",
        "en_short_name": "Colombia",
        "nationality": "Colombian"
    },
    {
        "code": "KM",
        "en_short_name": "Comoros",
        "nationality": "Comoran, Comorian"
    },
    {
        "code": "CG",
        "en_short_name": "Congo (Republic of the)",
        "nationality": "Congolese"
    },
    {
        "code": "CD",
        "en_short_name": "Congo (Democratic Republic of the)",
        "nationality": "Congolese"
    },
    {
        "code": "CK",
        "en_short_name": "Cook Islands",
        "nationality": "Cook Island"
    },
    {
        "code": "CR",
        "en_short_name": "Costa Rica",
        "nationality": "Costa Rican"
    },
    {
        "code": "CI",
        "en_short_name": "d'Ivoire",
        "nationality": "Ivorian"
    },
    {
        "code": "HR",
        "en_short_name": "Croatia",
        "nationality": "Croatian"
    },
    {
        "code": "CU",
        "en_short_name": "Cuba",
        "nationality": "Cuban"
    },
    {
        "code": "CW",
        "en_short_name": "Curao",
        "nationality": "Curoan"
    },
    {
        "code": "CY",
        "en_short_name": "Cyprus",
        "nationality": "Cypriot"
    },
    {
        "code": "CZ",
        "en_short_name": "Czech Republic",
        "nationality": "Czech"
    },
    {
        "code": "DK",
        "en_short_name": "Denmark",
        "nationality": "Danish"
    },
    {
        "code": "DJ",
        "en_short_name": "Djibouti",
        "nationality": "Djiboutian"
    },
    {
        "code": "DM",
        "en_short_name": "Dominica",
        "nationality": "Dominican"
    },
    {
        "code": "DO",
        "en_short_name": "Dominican Republic",
        "nationality": "Dominican"
    },
    {
        "code": "EC",
        "en_short_name": "Ecuador",
        "nationality": "Ecuadorian"
    },
    {
        "code": "EG",
        "en_short_name": "Egypt",
        "nationality": "Egyptian"
    },
    {
        "code": "SV",
        "en_short_name": "El Salvador",
        "nationality": "Salvadoran"
    },
    {
        "code": "GQ",
        "en_short_name": "Equatorial Guinea",
        "nationality": "Equatorial Guinean, Equatoguinean"
    },
    {
        "code": "ER",
        "en_short_name": "Eritrea",
        "nationality": "Eritrean"
    },
    {
        "code": "EE",
        "en_short_name": "Estonia",
        "nationality": "Estonian"
    },
    {
        "code": "ET",
        "en_short_name": "Ethiopia",
        "nationality": "Ethiopian"
    },
    {
        "code": "FK",
        "en_short_name": "Falkland Islands (Malvinas)",
        "nationality": "Falkland Island"
    },
    {
        "code": "FO",
        "en_short_name": "Faroe Islands",
        "nationality": "Faroese"
    },
    {
        "code": "FJ",
        "en_short_name": "Fiji",
        "nationality": "Fijian"
    },
    {
        "code": "FI",
        "en_short_name": "Finland",
        "nationality": "Finnish"
    },
    {
        "code": "FR",
        "en_short_name": "France",
        "nationality": "French"
    },
    {
        "code": "GF",
        "en_short_name": "French Guiana",
        "nationality": "French Guianese"
    },
    {
        "code": "PF",
        "en_short_name": "French Polynesia",
        "nationality": "French Polynesian"
    },
    {
        "code": "TF",
        "en_short_name": "French Southern Territories",
        "nationality": "French Southern Territories"
    },
    {
        "code": "GA",
        "en_short_name": "Gabon",
        "nationality": "Gabonese"
    },
    {
        "code": "GM",
        "en_short_name": "Gambia",
        "nationality": "Gambian"
    },
    {
        "code": "GE",
        "en_short_name": "Georgia",
        "nationality": "Georgian"
    },
    {
        "code": "DE",
        "en_short_name": "Germany",
        "nationality": "German"
    },
    {
        "code": "GH",
        "en_short_name": "Ghana",
        "nationality": "Ghanaian"
    },
    {
        "code": "GI",
        "en_short_name": "Gibraltar",
        "nationality": "Gibraltar"
    },
    {
        "code": "GR",
        "en_short_name": "Greece",
        "nationality": "Greek, Hellenic"
    },
    {
        "code": "GL",
        "en_short_name": "Greenland",
        "nationality": "Greenlandic"
    },
    {
        "code": "GD",
        "en_short_name": "Grenada",
        "nationality": "Grenadian"
    },
    {
        "code": "GP",
        "en_short_name": "Guadeloupe",
        "nationality": "Guadeloupe"
    },
    {
        "code": "GU",
        "en_short_name": "Guam",
        "nationality": "Guamanian, Guambat"
    },
    {
        "code": "GT",
        "en_short_name": "Guatemala",
        "nationality": "Guatemalan"
    },
    {
        "code": "GG",
        "en_short_name": "Guernsey",
        "nationality": "Channel Island"
    },
    {
        "code": "GN",
        "en_short_name": "Guinea",
        "nationality": "Guinean"
    },
    {
        "code": "GW",
        "en_short_name": "Guinea-Bissau",
        "nationality": "Bissau-Guinean"
    },
    {
        "code": "GY",
        "en_short_name": "Guyana",
        "nationality": "Guyanese"
    },
    {
        "code": "HT",
        "en_short_name": "Haiti",
        "nationality": "Haitian"
    },
    {
        "code": "HM",
        "en_short_name": "Heard Island and McDonald Islands",
        "nationality": "Heard Island or McDonald Islands"
    },
    {
        "code": "VA",
        "en_short_name": "Vatican City State",
        "nationality": "Vatican"
    },
    {
        "code": "HN",
        "en_short_name": "Honduras",
        "nationality": "Honduran"
    },
    {
        "code": "HK",
        "en_short_name": "Hong Kong",
        "nationality": "Hong Kong, Hong Kongese"
    },
    {
        "code": "HU",
        "en_short_name": "Hungary",
        "nationality": "Hungarian, Magyar"
    },
    {
        "code": "IS",
        "en_short_name": "Iceland",
        "nationality": "Icelandic"
    },
    {
        "code": "IN",
        "en_short_name": "India",
        "nationality": "Indian"
    },
    {
        "code": "ID",
        "en_short_name": "Indonesia",
        "nationality": "Indonesian"
    },
    {
        "code": "IR",
        "en_short_name": "Iran",
        "nationality": "Iranian, Persian"
    },
    {
        "code": "IQ",
        "en_short_name": "Iraq",
        "nationality": "Iraqi"
    },
    {
        "code": "IE",
        "en_short_name": "Ireland",
        "nationality": "Irish"
    },
    {
        "code": "IM",
        "en_short_name": "Isle of Man",
        "nationality": "Manx"
    },
    {
        "code": "IL",
        "en_short_name": "Israel",
        "nationality": "Israeli"
    },
    {
        "code": "IT",
        "en_short_name": "Italy",
        "nationality": "Italian"
    },
    {
        "code": "JM",
        "en_short_name": "Jamaica",
        "nationality": "Jamaican"
    },
    {
        "code": "JP",
        "en_short_name": "Japan",
        "nationality": "Japanese"
    },
    {
        "code": "JE",
        "en_short_name": "Jersey",
        "nationality": "Channel Island"
    },
    {
        "code": "JO",
        "en_short_name": "Jordan",
        "nationality": "Jordanian"
    },
    {
        "code": "KZ",
        "en_short_name": "Kazakhstan",
        "nationality": "Kazakhstani, Kazakh"
    },
    {
        "code": "KE",
        "en_short_name": "Kenya",
        "nationality": "Kenyan"
    },
    {
        "code": "KI",
        "en_short_name": "Kiribati",
        "nationality": "I-Kiribati"
    },
    {
        "code": "KP",
        "en_short_name": "Korea (Democratic People's Republic of)",
        "nationality": "North Korean"
    },
    {
        "code": "KR",
        "en_short_name": "Korea (Republic of)",
        "nationality": "South Korean"
    },
    {
        "code": "KW",
        "en_short_name": "Kuwait",
        "nationality": "Kuwaiti"
    },
    {
        "code": "KG",
        "en_short_name": "Kyrgyzstan",
        "nationality": "Kyrgyzstani, Kyrgyz, Kirgiz, Kirghiz"
    },
    {
        "code": "LA",
        "en_short_name": "Lao People's Democratic Republic",
        "nationality": "Lao, Laotian"
    },
    {
        "code": "LV",
        "en_short_name": "Latvia",
        "nationality": "Latvian"
    },
    {
        "code": "LB",
        "en_short_name": "Lebanon",
        "nationality": "Lebanese"
    },
    {
        "code": "LS",
        "en_short_name": "Lesotho",
        "nationality": "Basotho"
    },
    {
        "code": "LR",
        "en_short_name": "Liberia",
        "nationality": "Liberian"
    },
    {
        "code": "LY",
        "en_short_name": "Libya",
        "nationality": "Libyan"
    },
    {
        "code": "LI",
        "en_short_name": "Liechtenstein",
        "nationality": "Liechtenstein"
    },
    {
        "code": "LT",
        "en_short_name": "Lithuania",
        "nationality": "Lithuanian"
    },
    {
        "code": "LU",
        "en_short_name": "Luxembourg",
        "nationality": "Luxembourg, Luxembourgish"
    },
    {
        "code": "MO",
        "en_short_name": "Macao",
        "nationality": "Macanese, Chinese"
    },
    {
        "code": "MK",
        "en_short_name": "Macedonia (the former Yugoslav Republic of)",
        "nationality": "Macedonian"
    },
    {
        "code": "MG",
        "en_short_name": "Madagascar",
        "nationality": "Malagasy"
    },
    {
        "code": "MW",
        "en_short_name": "Malawi",
        "nationality": "Malawian"
    },
    {
        "code": "MY",
        "en_short_name": "Malaysia",
        "nationality": "Malaysian"
    },
    {
        "code": "MV",
        "en_short_name": "Maldives",
        "nationality": "Maldivian"
    },
    {
        "code": "ML",
        "en_short_name": "Mali",
        "nationality": "Malian, Malinese"
    },
    {
        "code": "MT",
        "en_short_name": "Malta",
        "nationality": "Maltese"
    },
    {
        "code": "MH",
        "en_short_name": "Marshall Islands",
        "nationality": "Marshallese"
    },
    {
        "code": "MQ",
        "en_short_name": "Martinique",
        "nationality": "Martiniquais, Martinican"
    },
    {
        "code": "MR",
        "en_short_name": "Mauritania",
        "nationality": "Mauritanian"
    },
    {
        "code": "MU",
        "en_short_name": "Mauritius",
        "nationality": "Mauritian"
    },
    {
        "code": "YT",
        "en_short_name": "Mayotte",
        "nationality": "Mahoran"
    },
    {
        "code": "MX",
        "en_short_name": "Mexico",
        "nationality": "Mexican"
    },
    {
        "code": "FM",
        "en_short_name": "Micronesia (Federated States of)",
        "nationality": "Micronesian"
    },
    {
        "code": "MD",
        "en_short_name": "Moldova (Republic of)",
        "nationality": "Moldovan"
    },
    {
        "code": "MC",
        "en_short_name": "Monaco",
        "nationality": "Mon\u00e9gasque, Monacan"
    },
    {
        "code": "MN",
        "en_short_name": "Mongolia",
        "nationality": "Mongolian"
    },
    {
        "code": "ME",
        "en_short_name": "Montenegro",
        "nationality": "Montenegrin"
    },
    {
        "code": "MS",
        "en_short_name": "Montserrat",
        "nationality": "Montserratian"
    },
    {
        "code": "MA",
        "en_short_name": "Morocco",
        "nationality": "Moroccan"
    },
    {
        "code": "MZ",
        "en_short_name": "Mozambique",
        "nationality": "Mozambican"
    },
    {
        "code": "MM",
        "en_short_name": "Myanmar",
        "nationality": "Burmese"
    },
    {
        "code": "NA",
        "en_short_name": "Namibia",
        "nationality": "Namibian"
    },
    {
        "code": "NR",
        "en_short_name": "Nauru",
        "nationality": "Nauruan"
    },
    {
        "code": "NP",
        "en_short_name": "Nepal",
        "nationality": "Nepali, Nepalese"
    },
    {
        "code": "NL",
        "en_short_name": "Netherlands",
        "nationality": "Dutch, Netherlandic"
    },
    {
        "code": "NC",
        "en_short_name": "New Caledonia",
        "nationality": "New Caledonian"
    },
    {
        "code": "NZ",
        "en_short_name": "New Zealand",
        "nationality": "New Zealand, NZ"
    },
    {
        "code": "NI",
        "en_short_name": "Nicaragua",
        "nationality": "Nicaraguan"
    },
    {
        "code": "NE",
        "en_short_name": "Niger",
        "nationality": "Nigerien"
    },
    {
        "code": "NG",
        "en_short_name": "Nigeria",
        "nationality": "Nigerian"
    },
    {
        "code": "NU",
        "en_short_name": "Niue",
        "nationality": "Niuean"
    },
    {
        "code": "NF",
        "en_short_name": "Norfolk Island",
        "nationality": "Norfolk Island"
    },
    {
        "code": "MP",
        "en_short_name": "Northern Mariana Islands",
        "nationality": "Northern Marianan"
    },
    {
        "code": "NO",
        "en_short_name": "Norway",
        "nationality": "Norwegian"
    },
    {
        "code": "OM",
        "en_short_name": "Oman",
        "nationality": "Omani"
    },
    {
        "code": "PK",
        "en_short_name": "Pakistan",
        "nationality": "Pakistani"
    },
    {
        "code": "PW",
        "en_short_name": "Palau",
        "nationality": "Palauan"
    },
    {
        "code": "PS",
        "en_short_name": "Palestine, State of",
        "nationality": "Palestinian"
    },
    {
        "code": "PA",
        "en_short_name": "Panama",
        "nationality": "Panamanian"
    },
    {
        "code": "PG",
        "en_short_name": "Papua New Guinea",
        "nationality": "Papua New Guinean, Papuan"
    },
    {
        "code": "PY",
        "en_short_name": "Paraguay",
        "nationality": "Paraguayan"
    },
    {
        "code": "PE",
        "en_short_name": "Peru",
        "nationality": "Peruvian"
    },
    {
        "code": "PH",
        "en_short_name": "Philippines",
        "nationality": "Philippine, Filipino"
    },
    {
        "code": "PN",
        "en_short_name": "Pitcairn",
        "nationality": "Pitcairn Island"
    },
    {
        "code": "PL",
        "en_short_name": "Poland",
        "nationality": "Polish"
    },
    {
        "code": "PT",
        "en_short_name": "Portugal",
        "nationality": "Portuguese"
    },
    {
        "code": "PR",
        "en_short_name": "Puerto Rico",
        "nationality": "Puerto Rican"
    },
    {
        "code": "QA",
        "en_short_name": "Qatar",
        "nationality": "Qatari"
    },
    {
        "code": "RE",
        "en_short_name": "Runion",
        "nationality": "Runionese, Runionnais"
    },
    {
        "code": "RO",
        "en_short_name": "Romania",
        "nationality": "Romanian"
    },
    {
        "code": "RU",
        "en_short_name": "Russian Federation",
        "nationality": "Russian"
    },
    {
        "code": "RW",
        "en_short_name": "Rwanda",
        "nationality": "Rwandan"
    },
    {
        "code": "BL",
        "en_short_name": "Saint Barthlemy",
        "nationality": "Barthlemois"
    },
    {
        "code": "SH",
        "en_short_name": "Saint Helena, Ascension and Tristan da Cunha",
        "nationality": "Saint Helenian"
    },
    {
        "code": "KN",
        "en_short_name": "Saint Kitts and Nevis",
        "nationality": "Kittitian or Nevisian"
    },
    {
        "code": "LC",
        "en_short_name": "Saint Lucia",
        "nationality": "Saint Lucian"
    },
    {
        "code": "MF",
        "en_short_name": "Saint Martin (French part)",
        "nationality": "Saint-Martinoise"
    },
    {
        "code": "PM",
        "en_short_name": "Saint Pierre and Miquelon",
        "nationality": "Saint-Pierrais or Miquelonnais"
    },
    {
        "code": "VC",
        "en_short_name": "Saint Vincent and the Grenadines",
        "nationality": "Saint Vincentian, Vincentian"
    },
    {
        "code": "WS",
        "en_short_name": "Samoa",
        "nationality": "Samoan"
    },
    {
        "code": "SM",
        "en_short_name": "San Marino",
        "nationality": "Sammarinese"
    },
    {
        "code": "ST",
        "en_short_name": "Sao Tome and Principe",
        "nationality": "Sao Toman"
    },
    {
        "code": "SA",
        "en_short_name": "Saudi Arabia",
        "nationality": "Saudi, Saudi Arabian"
    },
    {
        "code": "SN",
        "en_short_name": "Senegal",
        "nationality": "Senegalese"
    },
    {
        "code": "RS",
        "en_short_name": "Serbia",
        "nationality": "Serbian"
    },
    {
        "code": "SC",
        "en_short_name": "Seychelles",
        "nationality": "Seychellois"
    },
    {
        "code": "SL",
        "en_short_name": "Sierra Leone",
        "nationality": "Sierra Leonean"
    },
    {
        "code": "SG",
        "en_short_name": "Singapore",
        "nationality": "Singaporean"
    },
    {
        "code": "SX",
        "en_short_name": "Sint Maarten (Dutch part)",
        "nationality": "Sint Maarten"
    },
    {
        "code": "SK",
        "en_short_name": "Slovakia",
        "nationality": "Slovak"
    },
    {
        "code": "SI",
        "en_short_name": "Slovenia",
        "nationality": "Slovenian, Slovene"
    },
    {
        "code": "SB",
        "en_short_name": "Solomon Islands",
        "nationality": "Solomon Island"
    },
    {
        "code": "SO",
        "en_short_name": "Somalia",
        "nationality": "Somali, Somalian"
    },
    {
        "code": "ZA",
        "en_short_name": "South Africa",
        "nationality": "South African"
    },
    {
        "code": "GS",
        "en_short_name": "South Georgia and the South Sandwich Islands",
        "nationality": "South Georgia or South Sandwich Islands"
    },
    {
        "code": "SS",
        "en_short_name": "South Sudan",
        "nationality": "South Sudanese"
    },
    {
        "code": "ES",
        "en_short_name": "Spain",
        "nationality": "Spanish"
    },
    {
        "code": "LK",
        "en_short_name": "Sri Lanka",
        "nationality": "Sri Lankan"
    },
    {
        "code": "SD",
        "en_short_name": "Sudan",
        "nationality": "Sudanese"
    },
    {
        "code": "SR",
        "en_short_name": "Suriname",
        "nationality": "Surinamese"
    },
    {
        "code": "SJ",
        "en_short_name": "Svalbard and Jan Mayen",
        "nationality": "Svalbard"
    },
    {
        "code": "SZ",
        "en_short_name": "Swaziland",
        "nationality": "Swazi"
    },
    {
        "code": "SE",
        "en_short_name": "Sweden",
        "nationality": "Swedish"
    },
    {
        "code": "CH",
        "en_short_name": "Switzerland",
        "nationality": "Swiss"
    },
    {
        "code": "SY",
        "en_short_name": "Syrian Arab Republic",
        "nationality": "Syrian"
    },
    {
        "code": "TW",
        "en_short_name": "Taiwan, Province of China",
        "nationality": "Chinese, Taiwanese"
    },
    {
        "code": "TJ",
        "en_short_name": "Tajikistan",
        "nationality": "Tajikistani"
    },
    {
        "code": "TZ",
        "en_short_name": "Tanzania, United Republic of",
        "nationality": "Tanzanian"
    },
    {
        "code": "TH",
        "en_short_name": "Thailand",
        "nationality": "Thai"
    },
    {
        "code": "TL",
        "en_short_name": "Timor-Leste",
        "nationality": "Timorese"
    },
    {
        "code": "TG",
        "en_short_name": "Togo",
        "nationality": "Togolese"
    },
    {
        "code": "TK",
        "en_short_name": "Tokelau",
        "nationality": "Tokelauan"
    },
    {
        "code": "TO",
        "en_short_name": "Tonga",
        "nationality": "Tongan"
    },
    {
        "code": "TT",
        "en_short_name": "Trinidad and Tobago",
        "nationality": "Trinidadian or Tobagonian"
    },
    {
        "code": "TN",
        "en_short_name": "Tunisia",
        "nationality": "Tunisian"
    },
    {
        "code": "TR",
        "en_short_name": "Turkey",
        "nationality": "Turkish"
    },
    {
        "code": "TM",
        "en_short_name": "Turkmenistan",
        "nationality": "Turkmen"
    },
    {
        "code": "TC",
        "en_short_name": "Turks and Caicos Islands",
        "nationality": "Turks and Caicos Island"
    },
    {
        "code": "TV",
        "en_short_name": "Tuvalu",
        "nationality": "Tuvaluan"
    },
    {
        "code": "UG",
        "en_short_name": "Uganda",
        "nationality": "Ugandan"
    },
    {
        "code": "UA",
        "en_short_name": "Ukraine",
        "nationality": "Ukrainian"
    },
    {
        "code": "AE",
        "en_short_name": "United Arab Emirates",
        "nationality": "Emirati, Emirian, Emiri"
    },
    {
        "code": "GB",
        "en_short_name": "United Kingdom of Great Britain and Northern Ireland",
        "nationality": "British, UK"
    },
    {
        "code": "UM",
        "en_short_name": "United States Minor Outlying Islands",
        "nationality": "American"
    },
    {
        "code": "US",
        "en_short_name": "United States of America",
        "nationality": "American"
    },
    {
        "code": "UY",
        "en_short_name": "Uruguay",
        "nationality": "Uruguayan"
    },
    {
        "code": "UZ",
        "en_short_name": "Uzbekistan",
        "nationality": "Uzbekistani, Uzbek"
    },
    {
        "code": "VU",
        "en_short_name": "Vanuatu",
        "nationality": "Ni-Vanuatu, Vanuatuan"
    },
    {
        "code": "VE",
        "en_short_name": "Venezuela (Bolivarian Republic of)",
        "nationality": "Venezuelan"
    },
    {
        "code": "VN",
        "en_short_name": "Vietnam",
        "nationality": "Vietnamese"
    },
    {
        "code": "VG",
        "en_short_name": "Virgin Islands (British)",
        "nationality": "British Virgin Island"
    },
    {
        "code": "VI",
        "en_short_name": "Virgin Islands (U.S.)",
        "nationality": "U.S. Virgin Island"
    },
    {
        "code": "WF",
        "en_short_name": "Wallis and Futuna",
        "nationality": "Wallis and Futuna, Wallisian or Futunan"
    },
    {
        "code": "EH",
        "en_short_name": "Western Sahara",
        "nationality": "Sahrawi, Sahrawian, Sahraouian"
    },
    {
        "code": "YE",
        "en_short_name": "Yemen",
        "nationality": "Yemeni"
    },
    {
        "code": "ZM",
        "en_short_name": "Zambia",
        "nationality": "Zambian"
    },
    {
        "code": "ZW",
        "en_short_name": "Zimbabwe",
        "nationality": "Zimbabwean"
    }
]