import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-bootstrap-spinner';
import { CaseManagementService } from 'projects/case-manager/src/app/services/caseManagementService';
import { environment } from 'projects/case-manager/src/environments/environment';
import { Subscription } from 'rxjs';
import { ForcedDirectedGraphComponent } from '../../forcedDirected/forcedDirected.component';

@Component({
  selector: 'app-case-view-detail',
  templateUrl: './case-view-detail.component.html',
  styleUrls: ['./case-view-detail.component.scss'],
})
export class CaseViewDetailComponent implements OnInit {
  recordId: any;
  caseId: any;
  subscriptions: Subscription[] = [];
  caseDetails = undefined;
  caseDetailsData = undefined;
  previousUrl;
  currentUrl;
  imagePath = environment.imagelinkPath;
  caseActivityLogs = [];
  profileImage = undefined;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private caseMgtSvc: CaseManagementService,
    private spinner: NgxSpinnerService,
    private dialog: MatDialog
  ) {
    // this.caseMgtSvc.actionTabRequired.next(false);
    this.spinner.show('caseViewDetailsLoading');
    this.currentUrl = this.router.url;
    this.subscriptions.push(
      this.route.params.subscribe((params) => {
        this.recordId = params['id'];
        if (this.recordId) {
          this.subscriptions.push(
            this.caseMgtSvc.caseWithinCaseDetails.subscribe((res) => {
              if (res && res != undefined) {
                this.caseDetails = res;
                if (res.data) {
                  this.caseDetailsData = res.data;

                  if (
                    this.caseDetailsData &&
                    Object.keys(this.caseDetailsData).length > 0 &&
                    this.caseDetailsData.image_path
                  ) {
                    this.profileImage = this.caseDetailsData.image_path;
                  }
                }
                this.spinner.hide('caseViewDetailsLoading');
              } else {
                this.subscriptions.push(
                  this.caseMgtSvc
                    .getCaseDetails(localStorage.getItem('caseId'), undefined)
                    .subscribe(
                      (data) => {
                        if (
                          data &&
                          data.caseDetails &&
                          data.caseDetails.length
                        ) {
                          data.caseDetails.map((result) => {
                            if (
                              result.id == this.recordId &&
                              result.case_id == localStorage.getItem('caseId')
                            ) {
                              this.caseDetails = result;
                              if (result.data) {
                                this.caseDetailsData = result.data;

                                if (
                                  this.caseDetailsData &&
                                  Object.keys(this.caseDetailsData).length > 0 &&
                                  this.caseDetailsData.image_path
                                ) {
                                  this.profileImage = this.caseDetailsData.image_path;
                                }
                              }
                              this.spinner.hide('caseViewDetailsLoading');
                            }
                          });
                        }
                      },
                      (err) => {
                        this.spinner.hide('caseViewDetailsLoading');
                      }
                    )
                );
              }
            }, _ => {
              this.spinner.hide('caseViewDetailsLoading');
            })
          );
        }
      })
    );

    this.getCaseActivityLogs();
  }

  getCaseActivityLogs() {
    const caseID = Number(localStorage.getItem('caseId')); // recordId

    this.caseMgtSvc.fetchCaseActivityLogs(caseID).subscribe(
      (res) => {
        if (res && res.activityLogs && res.activityLogs.length) {
          this.caseActivityLogs = res.activityLogs;
        } else {
          this.caseActivityLogs = [];
        }
      },
      (err) => {
        this.caseActivityLogs = [];
      }
    );
  }

  ngOnInit(): void { }

  goBack() {
    this.subscriptions.push(
      this.caseMgtSvc.previousCaseId.subscribe((res) => {
        if (res) {
          this.router.navigate(['pages/case-management/case-view/' + res]);
        } else {
          this.router.navigate([
            'pages/case-management/case-view/' + localStorage.getItem('caseId'),
          ]);
        }
      })
    );
  }

  CheckType(val) {
    let bool = false;
    if (val == null) {
      bool = true;
    } else {
      bool = false;
    }
    return { val: typeof val, valid: bool };
  }

  TransformLink(link) {
    let str = '';
    str = this.imagePath + link;
    return str;
  }

  WatchRCA() {
    this.dialog
      .open(ForcedDirectedGraphComponent, {
        panelClass: 'modalWidthRCA',
        data: this.caseDetailsData,
      })
      .afterClosed()
      .subscribe((result) => { });
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }
}
