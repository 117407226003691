import { FormGroup } from "@angular/forms";

/**
 * Match specific key in Object
 * @param obj 
 * @param keyToMatch 
 * @param valueToMatch 
 * @returns 
 */
export const matchTerm = (obj: any, keyToMatch: string, valueToMatch: string): boolean => {
    if (!obj[keyToMatch]) {
        return false;
    }
    return obj[keyToMatch].toString().toLowerCase().trim().includes(valueToMatch.toString().toLowerCase().trim());
}

/**
 * Removed null and blank properties from Object
 * @param data 
 * @returns 
 */
export const createCleanedObject = (data) => {
    for (var propName in data) {
        if (
            data[propName] === null ||
            data[propName] === undefined ||
            data[propName] === '' ||
            (Array.isArray(data[propName]) && !data[propName].length)
        ) {
            delete data[propName];
        }
    }
    return data;
}

/**
 * Finds Invalid Controls in a form
 * @param form 
 * @returns 
 */
export const findInvalidControls = (form: FormGroup): any[] => {
    const invalid = [];
    const controls = form.controls;
    for (const name in controls) {
        if (controls[name].invalid) {
            invalid.unshift(name);
        }
    }
    return invalid;
}