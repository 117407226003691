import { Component, ComponentFactoryResolver, Input, OnInit, ViewChild } from '@angular/core';
import { ConfigPanelHeaderComponent } from './components/config-panel-header/config-panel-header.component';
import { DynamicDataHostDirective } from './directives/dynamic-data-host.directive';
import { ITemplate } from './template.interface';
import { Project } from './enums/lib.enum';
import { UserType } from 'projects/case-manager/src/app/enums/user-type.enum';

@Component({
  selector: 'lib-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  template: ITemplate;
  userGroups = [];
  navLinks = [];
  userDetails = {
    email: ''
  };
  @ViewChild(DynamicDataHostDirective, { static: true }) libDynamicDataHost!: DynamicDataHostDirective;
  @Input('projectName') projectName: string;
  @Input('headerName') headerName: string;
  @Input('userGroups') set setUserGroups(value: Array<any>) {
    this.userGroups = value || [];

    if (this.userGroups.length) {
      this.createHeaderTemplates();
    }
  };
  @Input('allowedRoutes') set setAllowedRoutes(value: Array<any>) {
    this.navLinks = value || [];

    // if (this.navLinks.length) {
    //   this.createHeaderTemplates();
    // }
  };

  constructor(private componentFactoryResolver: ComponentFactoryResolver) { }

  ngOnInit(): void {
    if (localStorage.getItem('userDetails')) {
      this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
    }
    this.createHeaderTemplates();
  }

  createHeaderTemplates(): void {
    switch (this.projectName) {
      case Project.CaseManager: {
        let componentData = {};

        if (this.userDetails) {
          componentData['userDetails'] = this.userDetails
        }

        if (this.userGroups && this.userGroups.length && this.userDetails['user_type'] === UserType.RGU) {
          componentData['userGroups'] = this.userGroups;
        }

        if (this.navLinks && this.navLinks.length) {
          componentData['navLinks'] = this.navLinks;
        }

        this.template = new ITemplate(ConfigPanelHeaderComponent, componentData);

        break;
      }
    }

    if (this.template) {
      this.loadComponent();
    }
  }

  loadComponent() {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.template.component);

    const viewContainerRef = this.libDynamicDataHost.viewContainerRef;
    viewContainerRef.clear();

    const componentRef = viewContainerRef.createComponent<ITemplate>(componentFactory);
    componentRef.instance.data = this.template.data;
  }

}

