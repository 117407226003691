import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { LoginRoutingModule } from '../routes/login.routing';
import { LoginService } from '../services/login.service';
import { JwtInterceptor } from '../interceptors/JWTInterceptor';
import { LoginComponent } from '../login.component';
import { AuthGuard } from '../guards/login.guard';
import { SignUpComponent } from '../../auth/sign-up/sign-up.component';


import { NgxSpinnerModule } from 'ngx-bootstrap-spinner';
import { ForgotPasswordComponent } from '../forgot-password/forgot-password.component';
import { GlobalStateService } from "../../services/globalStateService";
import { RedirectGuard } from "../guards/redirect.guard";
import { MaterialModule } from "src/app/modules/material/material.module";

@NgModule({
  declarations: [LoginComponent,SignUpComponent,ForgotPasswordComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    LoginRoutingModule,
    MaterialModule,
    NgxSpinnerModule
  ],
  providers: [
    AuthGuard,
    RedirectGuard,
    LoginService,
    GlobalStateService,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
  ],
  bootstrap:[LoginComponent]
})
export class LoginModule {}
