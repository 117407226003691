import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
// import { HybridService } from '../hybrid-user-module/hybrid-user.service';
import { LayoutService } from '../services/layoutService';
import { LoginService } from './services/login.service';
import { NgxSpinnerService } from 'ngx-bootstrap-spinner';
import { SettingService } from '../services/settingsService';
import { GlobalStateService } from '../services/globalStateService';
import { UserType } from '../enums/user-type.enum';
import {
  lfdAdminNavLinks,
  appNavLinks,
  adminNavLinks,
} from '../constants/app-nav-links.constant';
import { ErrorMessageService } from '../services/error-message.service';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  hmacEnabled = true;
  loginForm = new FormGroup({
    email: new FormControl('', [Validators.required]),
    password: new FormControl('', [Validators.required]),
    secret_key: new FormControl(''),
    user_key: new FormControl(''),
  });
  alertPopUp = false;
  rememberMe = false;
  hide = true;
  errorText = '';
  emailRegex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  updateUser = {
    is_active: true,
  };
  copyRightYear = '';
  errorMessage = '';
  subscriptions: Subscription[] = [];
  loginSettings = undefined;
  returnUrl: any;
  userDetails = undefined;

  constructor(
    private formBuilder: FormBuilder,
    public _loginService: LoginService,
    private _layoutService: LayoutService,
    public _router: Router,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private _settingService: SettingService,
    public _globalStateService: GlobalStateService,
    public errorMessageService: ErrorMessageService
  ) {}

  ngOnInit() {
    //    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';

    //   this._router.navigate([this.returnUrl]);
    this.copyRightYear = this._loginService.copyRightYear;

    if (!localStorage.getItem('themeStatus')) {
      localStorage.setItem(
        'themeStatus',
        JSON.stringify(this.updateUser['is_active'])
      );
    }

    this.subscriptions.push(
      this.errorMessageService.errorMessage.subscribe((message) => {
        if (message) {
          message = message.split(':').pop();
          if (message.includes('signature')) {
            this.errorMessage = 'Please enter a valid Secret Key.';
          } else if (message.includes('key')) {
            this.errorMessage = 'Please enter a valid User Key.';
          } else {
            this.errorMessage = message;
          }

          setTimeout(() => {
            this.errorMessage = '';
          }, 7000);
        }
      })
    );

    this.subscriptions.push(
      this._layoutService.HMACEnabled.subscribe((enabled) => {
        this.hmacEnabled = enabled;
      })
    );
  }

  Login() {
    if (this.loginForm.valid) {
      this.spinner.show('loginLoading');
      this.spinner.show('authLoading');
      // setTimeout(() => {
      this._loginService.loginUser(this.loginForm.value).subscribe(
        (result) => {
          if (result.status == 'ok') {
            this._loginService
              .checkAuth(
                this.loginForm.value.user_key.trim(),
                this.loginForm.value.secret_key.trim(),
                JSON.stringify(result.token)
              )
              .subscribe(
                (data) => {
                  if (data && data.status == 'ok') {
                    // set data in session storage
                    localStorage.setItem('currentUserCaseMgt', result.token);
                    localStorage.setItem(
                      'secretKey',
                      this.loginForm.value.secret_key
                    );
                    localStorage.setItem(
                      'userKey',
                      this.loginForm.value.user_key
                    );
                    localStorage.setItem('userID', data.userID);

                    this.getUserDetails(data);
                    this.spinner.hide('loginLoading');
                  } else {
                    this.spinner.hide('loginLoading');
                    this._router.navigate(['login']);
                    return;
                  }
                },
                (_) => {
                  this.spinner.hide('loginLoading');
                  this.spinner.hide('authLoading');
                  this._router.navigate(['login']);
                  return;
                }
              );
          } else {
            this.spinner.hide('loginLoading');
            this._router.navigate(['login']);
            return;
          }
        },
        (_) => {
          this.spinner.hide('loginLoading');
          this.spinner.hide('authLoading');
          this._router.navigate(['login']);
          return;
        }
      );
      // }, 0);
    }
  }

  getUserDetails(data: any): void {
    this._settingService.getUserDetails().subscribe(
      (details) => {
        // console.log('Run get user details', details);
        if (details.status == 'ok') {
          this.userDetails = details.userDetails;

          // set data in local storage
          localStorage.setItem('userType', data.user_type);
          localStorage.setItem('userDetails', JSON.stringify(this.userDetails));

          // set permissions
          this.getPermissions();

          // if (data.user_type.toUpperCase() == 'ADM') {
          //   this._router.navigate(['pages/case-management']);
          // } else if (data.user_type.toUpperCase() == 'LDM') {
          //   this._router.navigate(['pages/lfd-admin']);
          // } else {
          //   this._router.navigate(['pages/select-group']);
          // }
        }
      },
      (_) => {
        this.spinner.hide('loginLoading');
        this.spinner.hide('authLoading');
      }
    );
  }

  changeType() {
    (document.getElementById('password') as any).type = 'password';
  }

  ValidateKeys() {
    if (
      this.hmacEnabled &&
      !(
        this.loginForm.get('secret_key').value &&
        this.loginForm.get('user_key').value
      )
    ) {
      return true;
    } else return false;
  }

  GotoLink(link) {
    this._router.navigate([link]);
  }

  HandleErrors(status?, msg?) {
    if (status == 0) {
      this.errorMessageService.errorMessage.next(
        'Service Temporarily Unavailable, please try again'
      );
    } else if (status == 400 || status == 401) {
      this.errorMessageService.errorMessage.next('Invalid Credentials: ' + msg);
    } else {
      this.errorMessageService.errorMessage.next(
        'Internal Server Error: ' + msg
      );
    }
  }

  /**
   * Get all permissions of login user
   */
  getPermissions(): void {
    let allowedRoutes = [];
    if (this.userDetails.user_type === UserType.LDM) {
      allowedRoutes = lfdAdminNavLinks;
      // set Allowed routes
      localStorage.setItem('allowedRoutes', JSON.stringify(allowedRoutes));
      this._globalStateService.allowedRoutes = allowedRoutes;
      this.spinner.hide('loginLoading');
      this.spinner.hide('authLoading');
      this._router.navigate(['pages/lfd-admin']);
    } else if (this.userDetails.user_type === UserType.ADM) {
      allowedRoutes = adminNavLinks;
      // set Allowed routes
      localStorage.setItem('allowedRoutes', JSON.stringify(allowedRoutes));
      this._globalStateService.allowedRoutes = allowedRoutes;
      this.spinner.hide('loginLoading');
      this.spinner.hide('authLoading');
      this._router.navigate(['pages/case-management']);
    } else if (this.userDetails.user_type == UserType.RGU) {
      const payload = {
        user_id: this.userDetails.id.toString(),
      };

      this.subscriptions.push(
        // this._globalStateService.getPermissions().subscribe((temp) => {
        this._settingService.getUserPermissions(payload).subscribe(
          (temp) => {
            if (temp.status == 'ok') {
              // let permissions = temp['permissions'];
              let permissions = {};
              if (
                temp['userPermissions'] &&
                temp['userPermissions']['permission_tags']
              ) {
                const permissionsArray = Array.from(
                  new Set(temp['userPermissions']['permission_tags'])
                );
                permissions =
                  this._globalStateService.MakePermissionsArray(
                    permissionsArray
                  );
              }
              if (localStorage.getItem('userID')) {
                this.subscriptions.push(
                  this._settingService.getGroupsAgainstUser().subscribe(
                    (res) => {
                      if (res.status == 'ok') {
                        let userGroups = res.userGroups;

                        // Set routes on permissions basis
                        allowedRoutes = appNavLinks.filter((link) => {
                          return Object.keys(link.permissions || {}).some(
                            (permission) => permissions[permission]
                          );
                        });

                        allowedRoutes.forEach((route) => {
                          if (route.children && route.children.length) {
                            route.children = route.children.filter((link) => {
                              return Object.keys(link.permissions || {}).some(
                                (permission) => permissions[permission]
                              );
                            });
                          }
                        });

                        // set Allowed routes
                        localStorage.setItem(
                          'allowedRoutes',
                          JSON.stringify(allowedRoutes)
                        );
                        // set User groups
                        localStorage.setItem(
                          'userGroups',
                          JSON.stringify(userGroups)
                        );
                        this._router.navigate(['pages/screening']);

                        this.spinner.hide('loginLoading');
                        this.spinner.hide('authLoading');
                      }
                    },
                    (err) => {
                      console.log('Error in getting user groups: ', err);
                      this.spinner.hide('loginLoading');
                      this.spinner.hide('authLoading');
                    }
                  )
                );
              }

              this.spinner.hide('loginLoading');
              this.spinner.hide('authLoading');
            }
          },
          (_) => {
            this.spinner.hide('loginLoading');
            this.spinner.hide('authLoading');
          }
        )
      );
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });

    this.errorMessageService.errorMessage.next('');
  }
}
