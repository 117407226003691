import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AddGroupComponent } from '../components/content/user-management/group-list/add-group/add-group.component';
import { GroupListComponent } from '../components/content/user-management/group-list/group-list.component';
import { AddRoleComponent } from '../components/content/user-management/role-list/add-role/add-role.component';
import { RoleListComponent } from '../components/content/user-management/role-list/role-list.component';
import { UmDashboardComponent } from '../components/content/user-management/um-dashboard/um-dashboard.component';
import { AddUserComponent } from '../components/content/user-management/user-list/add-user/add-user.component';
import { UserListComponent } from '../components/content/user-management/user-list/user-list.component';
import { UserManagementComponent } from '../components/content/user-management/user-management.component';

const routes: Routes = [
  {
    path: '',
    component: UserManagementComponent,
    data: {title: 'User Management'},
    children: [
      {
        path: '',
        component: UmDashboardComponent,
      },
      {
        path: 'groups',
        component: GroupListComponent,
        data: {title: 'Groups'},

      },
      {
        path: 'create-new-group',
        component: AddGroupComponent,
        data: {title: 'Create Group'},

      },
      {
        path: 'create-new-group/:groupId',
        component: AddGroupComponent,
        data: {title: 'Create Group'},

      },
      {
        path: 'edit-group/:id',
        component: AddGroupComponent,
      },
      {
        path: 'users',
        component: UserListComponent,
        data: {title: 'Users'},
      },
      {
        path: 'create-new-user',
        component: AddUserComponent,
        data: {title: 'Create User'},
      },
      {
        path: 'create-new-user/:groupId',
        component: AddUserComponent,
        data: {title: 'Create User'},
      },
      {
        path: 'edit-user/:id',
        component: AddUserComponent,
        data: {title: 'Edit User'},
      },
      {
        path: 'roles',
        component: RoleListComponent,
        data: {title: 'Roles'},
      },
      {
        path: 'create-new-role',
        component: AddRoleComponent,
        data: {title: 'Create Role'},
      },
      {
        path: 'edit-role/:id',
        component: AddRoleComponent,
        data: {title: 'Edit Role'},
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class UserManagementRoutingModule { }
