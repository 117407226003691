import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-bootstrap-spinner';
import { SettingService } from 'projects/case-manager/src/app/services/settingsService';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { ErrorMessageService } from 'projects/case-manager/src/app/services/error-message.service';

@Component({
  selector: 'app-add-group',
  templateUrl: './add-group.component.html',
  styleUrls: ['./add-group.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AddGroupComponent implements OnInit, OnDestroy {
  visible = false;
  addGroupForm = new FormGroup({
    groupName: new FormControl('', [Validators.required]),
    selectedGroupStatus: new FormControl('A', null),
    selectedGroupManager: new FormControl(''),
    selectedParentGroup: new FormControl(''),
  });
  horizontalPosition: MatSnackBarHorizontalPosition = 'end';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';
  managers = [];
  groupOptions = [];
  URL = undefined;
  signedUser = undefined;
  groupID = null;
  subscriptions: Subscription[] = [];
  errorMessage = '';

  constructor(
    private settingService: SettingService,
    private spinner: NgxSpinnerService,
    private _router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private errorMessageService: ErrorMessageService
  ) {
    this.URL = this._router.url;

    if (
      this._router.url.includes('edit-group') ||
      (this._router.url.includes('create-new-group') &&
        this.route.snapshot.params &&
        Object.keys(this.route.snapshot.params).length > 0)
    ) {
      if (
        this.route.snapshot.params &&
        Object.keys(this.route.snapshot.params).length > 0
      ) {
        if (this.route.snapshot.params.id) {
          this.groupID = Number(this.route.snapshot.params.id);
        } else if (this.route.snapshot.params.groupId) {
          this.groupID = Number(this.route.snapshot.params.groupId);
        }
      }
    }
  }

  ngOnInit(): void {
    this.fetchGroups();
    this.fetchGroupManagers();

    this.signedUser = JSON.parse(localStorage.getItem('userDetails'));
    const groupID = +this.route.snapshot.params.id;

    if (groupID) {
      this.getGroupDetails(groupID);
    }

    this.subscriptions.push(
      this.errorMessageService.errorMessage.subscribe((message) => {
        if (message) {
          message = message.split(':').pop();

          if (message.includes('already exists')) {
            this.errorMessage = 'Group with this name is already exists';
          }
          else if (message.includes('IntegrityError')) {
            this.errorMessage = 'Something went wrong while completing task, please try again later!';
          } else {
            this.errorMessage = message;
          }

          setTimeout(() => {
            this.errorMessage = '';
          }, 7000);
        }
      })
    );
  }

  getGroupDetails(groupID) {
    const payload = {
      id: groupID,
    };

    this.settingService.getGroupByID(payload).subscribe((res) => {
      if (res.status == 'ok') {
        const { organization_group } = res.groupDetails;
        const groupData = {};

        if (organization_group.length > 0) {
          groupData['groupName'] = organization_group[0].name;
          groupData['selectedParentGroup'] = organization_group[0].parent;
          groupData['selectedGroupStatus'] = organization_group[0].status;
          groupData['selectedGroupManager'] =
            organization_group[0].group_manager;
        }

        this.addGroupForm.patchValue(groupData);
      }
    });
  }

  fetchGroups() {
    this.settingService.getGroups().subscribe(
      (res) => {
        if (res.status == 'ok') {
          if (res && res.groups && res.groups.length > 0) {
            let data = res.groups;

            if (this.groupID) {
              data = data.filter(
                ({ id, parent_id }) =>
                  id !== this.groupID && parent_id !== this.groupID
              );
            }

            data.forEach((group) => {
              this.groupOptions.push({
                value: group.id,
                viewValue: group.name,
              });
            });

            // set selected group from route param
            const groupId = +this.route.snapshot.params.groupId;
            if (groupId) {
              this.addGroupForm.get('selectedParentGroup').setValue(groupId);
            }
          }
        } else {
          this.groupOptions = [];
        }
      },
      (err) => {
        this.groupOptions = [];
      }
    );
  }

  fetchGroupManagers() {
    this.settingService.getUsers().subscribe((res) => {
      if (res.status == 'ok') {
        if (res.users && res.users.length) {
          res.users = res.users.filter((el) => el.status != 'D');

          res.users.map((val) => {
            this.managers.push({
              value: val.id,
              viewValue: val.username,
            });
          });
        } else {
          this.managers = [];
        }
      } else {
        this.managers = [];
      }
    });
  }

  TransformGroupManager(id) {
    let name = '';

    this.managers.map((temp) => {
      if (temp.value == id) {
        name = temp.viewValue;
      }
    });

    return name;
  }

  AddGroup() {
    if (this.addGroupForm.valid) {
      let obj = {
        name: this.addGroupForm.get('groupName').value,
        parent: this.addGroupForm.get('selectedParentGroup').value,
        status: this.addGroupForm.get('selectedGroupStatus').value,
        group_manager: this.addGroupForm.get('selectedGroupManager').value,
        organization: this.signedUser.organization,
      };

      this.spinner.show('addGroupLoading');

      this.settingService.addGroup(obj).subscribe((res) => {
        if (res.status == 'ok') {
          this.addGroupForm.reset();
          this.toastr.success(
            'Group ' + res.groupdetails.name + ' added successfully!'
          );
          this._router.navigate([`pages/user-management/groups`]);
        }
        this.spinner.hide('addGroupLoading');
      }, _ => {
        this.spinner.hide('addGroupLoading');
      });
    }
  }

  UpdateGroup() {
    const groupID = +this.route.snapshot.params.id;
    const payload = {
      id: +this.route.snapshot.params.id,
      name: this.addGroupForm.get('groupName').value,
      parent: this.addGroupForm.get('selectedParentGroup').value,
      status: this.addGroupForm.get('selectedGroupStatus').value,
      group_manager: this.addGroupForm.get('selectedGroupManager').value,
      organization: this.signedUser.organization,
    };

    this.spinner.show('updateGroupLoading');

    this.settingService.updateGroup(payload, groupID).subscribe(
      (response) => {
        if (response.status == 'ok') {
          this.addGroupForm.reset();
          this.toastr.success('Group udpated successfully!');
          this._router.navigate(['/pages/user-management/groups']);
        }
        this.spinner.hide('updateGroupLoading');
      },
      (_) => {
        this.spinner.hide('updateGroupLoading');
      }
    );
  }

  onChange(result: Date[]): void {
    console.log('onChange: ', result);
  }

  open(): void {
    this.visible = !this.visible;
  }

  close(): void {
    this.visible = false;
  }

  goToGroupList() {
    this._router.navigate(['/pages/user-management/groups']);
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });

    this.errorMessageService.errorMessage.next('');
  }
}
