import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-bootstrap-spinner';
import { Subscription } from 'rxjs';
import { ErrorMessageService } from '../../services/error-message.service';
import { SettingService } from '../../services/settingsService';

@Component({
  selector: 'app-add-role-dialog',
  templateUrl: './add-role-dialog.component.html',
  styleUrls: ['./add-role-dialog.component.scss'],
})
export class AddRoleDialogComponent implements OnInit {
  loadingRoles = false;
  signedUser;
  selectedRole: any;
  subscriptions: Subscription[] = [];
  errorMessage = '';
  duplicateErrorMsg = [];
  statuses = [];
  editCase = false;
  tempObj = {};
  favoritePie = [];
  roles = [];
  permTags = [];
  organizations = [];

  pageControl: number = 1;
  itemsPerPage = 1;

  addRoleForm: FormGroup;
  constructor(
    private settingService: SettingService,
    private spinner: NgxSpinnerService,
    public dialogRef: MatDialogRef<AddRoleDialogComponent>,
    public formBuilder: FormBuilder,
    public errorMessageService: ErrorMessageService,
  ) {
    this.addRoleForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      status: ['', [Validators.required]],
      permission_tag: [[], []],
      organization: [undefined, []],
    });
    // this.subscriptions.push(
    //   this.settingService.getAllOrganizations().subscribe((res) => {
    //     if (res.status == 'ok') {
    //       res.organizations.map((val) => {
    //         this.organizations.push({
    //           value: val.id,
    //           viewValue: val.name
    //             .toLowerCase()
    //             .split(' ')
    //             .map((word) => {
    //               return word.charAt(0).toUpperCase() + word.slice(1);
    //             })
    //             .join(' '),
    //         });
    //       });
    //     } else {
    //       this.organizations = [];
    //     }
    //   })
    // );

    this.subscriptions.push(
      this.settingService.getChoices().subscribe((res) => {
        if (res.status == 'ok') {
          res.choices.alert_choices['STATUS_CHOICES'].map((temp) => {
            this.statuses.push({ value: temp[0], viewValue: temp[1] });
          });
        } else {
          this.statuses = [];
        }
        // console.log(this.statuses);
      })
    );

    this.spinner.show('loadingPermTags');
    this.subscriptions.push(
      this.settingService.getPermissionsTag().subscribe((res) => {
        if (res.status == 'ok') {
          this.spinner.hide('loadingPermTags');

          this.permTags = res.permissions;
          // let index = this.permTags.findIndex((tqp) => tqp == 'Escalation');
          // this.permTags.splice(index, 1);
        } else {
          this.spinner.hide('loadingPermTags');

          this.permTags = [];
        }
      })
    );
  }

  ngOnInit(): void {
    this.signedUser = JSON.parse(localStorage.getItem('userDetails'));

    // get error messages
    this.subscriptions.push(
      this.errorMessageService.errorMessage.subscribe((message) => {
        if (message) {
          this.errorMessage = message.split(':').pop();

          setTimeout(() => {
            this.errorMessage = '';
          }, 7000);
        }
      })
    );
  }

  onChange(ev, checking) {
    if (checking) {
      this.addRoleForm.get('permission_tag').value.push(ev.target.value);
    } else {
      let index = this.addRoleForm
        .get('permission_tag')
        .value.findIndex((res) => res == ev.target.value);
      this.addRoleForm.get('permission_tag').value.splice(index, 1);
    }
  }

  AddRole() {
    if (this.addRoleForm.valid) {
      let formValues = {
        name: this.addRoleForm.value.name,
        organization: this.signedUser.organization,
        permission_tag: this.TransformPermissionTags(
          this.addRoleForm.value.permission_tag
        ),
        status: this.addRoleForm.value.status,
      };

      this.spinner.show('addRoleLoading');
      this.settingService.addRole(formValues).subscribe((res) => {
        if (res.status == 'ok') {
          formValues['id'] = res.id;
          this.spinner.hide('addRoleLoading');
          this.addRoleForm.reset();
          this.dialogRef.close({
            status: true,
            newRole: formValues,
          });
        } else {
          this.addRoleForm.reset();
          this.spinner.hide('addRoleLoading');
        }
      });
    }
  }

  TransformPermissionTags(tags) {
    let str = '';
    tags = [...new Set(tags)];
    str = tags.join(',');
    return str;
  }

  Close() {
    // this.dialogRef.close();
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });

    this.errorMessageService.errorMessage.next('');
  }
}
