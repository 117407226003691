// import 'rxjs/add/operator/do';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from "rxjs/operators";
import { Router } from "@angular/router";
import { Injectable } from '@angular/core';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private _router: Router) {
  }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(tap((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
        // do stuff with response if you want
      }
    }, (err: any) => {
      if (err instanceof HttpErrorResponse) {
        // if(err.status == 401 && err.statusText == "Unauthorized" && !err.error.error.includes('The signature is not verified') && err.error.error_code != 'H008' && err.error.error_code != 'HI007') {
        if (err.status == 401 || err.statusText == "Unauthorized"
          || (err.error && err.error.detail && err.error.detail.includes('The signature is not verified'))
          || (err.error && err.error.error_code && (err.error.error_code != 'H008' && err.error.error_code != 'HI007'
            && err.error.error_code != "HS002" && err.error.error_code != "H007"))) {
          // redirect to the login route
          // or show a modal
          localStorage.clear();
          sessionStorage.clear();
          this._router.navigate(['login']);
        }

        if (err && err.error && err.error.detail && err.error.detail.includes('expired')) {
          sessionStorage.clear();
          localStorage.clear();
          this._router.navigate(['login']);
        }
      }
    }));
  }
}