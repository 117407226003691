export enum APIRoutes {
  getCaseChannelList = 'api/get_case_channels_list/',
  getCustomScreen = 'api/fetch_custom_screen_attrs/',
  screencase = 'api/screen_case/',
  listCases = 'api/list_alert_cases/',
  getCaseDetail = 'api/get_case_details/',
  // private getCaseLog = 'api/get_case_user_activity_log/',
  getCaseComments = 'api/get_case_detail_comments/',
  caseActivityURL = 'api/get_case_activity_log/',
  createcustomAttr = 'api/create_custom_screen_attr/',
  deleteCustomAttr = 'api/delete_custom_screen_attr/',
  changePass = 'api/change_password/',
  deleteCase = 'api/delete_case/',
  archeiveCase = 'api/archive_case/',
  assign_case = 'api/assign_case/',
  download_case = 'api/get_file/',
  escalate_case = 'api/case_escalation/',
  submitComment = 'api/create_case_detail_comments/',
  resolveCase = 'api/resolve_a_case_details/',
  exportcases = 'api/export_cases_report/',
  getExportCasesList = 'api/get_report_list/',
  rolesAgainstUser = 'api/list_my_roles/',
  logout = 'api/logout/',
  roleDetails = 'api/get_role_details/',
  heartBeat = 'api/check_auth/',
  passwordReset = 'api/password_reset/',
  passwordResetConfirm = 'api/password_reset/confirm/',
  addLicense = 'api/create_licence_package/',
  getLicenses = 'api/get_licence_package/',
  deleteLicense = 'api/update_licence_package/',
  addOrg = 'api/create_organization/',
  updateOrg = 'api/update_organization/',
  getAllOrgans = 'api/get_all_organizations/',
  renewLicenseAPI = 'api/renew_organization_licence/',
  getLicenseByIdUrl = 'api/get_licence_by_id/',
  getOrganizationByIdUrl = 'api/get_organizations_by_id/',
  getOrganizations = 'api/get_org_details/',
  createUser = 'api/create_user/',
  listUsers = 'api/list_users/',
  listAllUsers = 'api/list_all_users/',
  editUser = 'api/update_user/',
  getUserByID = 'api/list_user_by_id/',
  getGroupByID = 'api/get_group_details/',
  listGroupUsers = 'api/list_group_users/',
  createGroup = 'api/create_group/',
  listGroups = 'api/list_groups/',
  editGroup = 'api/update_group/',
  getGroupDetailsById = 'api/get_group_details/',
  createRole = 'api/create_role/',
  listRoles = 'api/list_roles/',
  editRole = 'api/update_role/',
  getPerms = 'api/get_perms/',
  getChoice = 'api/get_choices/',
  groupAgainstUser = 'api/list_my_groups/',
  getUserdetailsAPI = 'api/get_user_details/',
  assignGroupAPI = 'api/assign_group/',
  assignRoleAPI = 'api/assign_role/',
  authToken = 'api-token-auth/',
  zeroFootprintAPI = 'api/zerofootprint_screen_case/',
  createJobAPI = 'api/create_job/',
  getStatusAPI = 'api/get_status/',
  getCaseChannelAPI = 'api/get_case_channel/',
  userPermissions = 'api/list_my_all_roles/',
  getCaseEscalationReportAPI = 'api/get_case_escalation_report/',
  getSearchLogReportAPI = 'api/search_log_report/',
  getAccessLogReportAPI = 'api/access_log_report/',
  getDiscountLogReportAPI = 'api/discount_log_report/',
  getAuditLogReportAPI = 'api/audit_log_report/',
  getAccessLogAPI = 'api/get_access_log/',
}
