import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'strManipulation'
})
export class StrManipulationPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if(value.search("_") == -1){
      return value;

    }else if(value.search("_") != -1){
      return value.replace("_", " ");
    }
  }

}
