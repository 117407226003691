export const lfdAdminNavLinks = [
    {
        title: 'License',
        route: '/pages/lfd-admin',
    },
    {
        title: 'Organizations',
        route: '/pages/lfd-admin/organization',
    },
];

export const adminNavLinks = [
    {
        title: 'Case Management',
        route: '/pages/case-management/case-list',
    },
    // {
    // title: 'Management',
    // route: '/pages/user-management',
    // children: [
    {
        title: 'User Management',
        route: '/pages/user-management',
    },
    {
        title: 'Create / Add Group',
        route: '/pages/user-management/groups',
    },
    {
        title: 'Create / Add User',
        route: '/pages/user-management/users',
    },
    {
        title: 'Create / Add Roles',
        route: '/pages/user-management/roles',
    },
    // ],
    // },
];

export const appNavLinks = [
    {
        title: 'Screening',
        route: '/pages/screening',
        permissions: {
            "canScreenCase": true,
            "canScreenZeroFootprint": true,
            "canSearchBatch": true,
            "canOGSCase": true,
            "canPerformCustomScreenAttr": true
        }
    },
    {
        title: 'Case Management',
        route: '/pages/case-management/case-list',
        permissions: {
            "canDeleteCase": true,
            "canArchiveCase": true,
            "canResolveCase": true,
            "canReviewCase": true,
            "canAssignCase": true,
            "canAuditCase": true,
            "canEscalate": true,
            "canExportDetailedReport": true,
            "canExportSummaryReport": true,
        }
    },
    // {
    // title: 'Management',
    // route: '/pages/user-management',
    // permissions: {
    //     "canAssignGroup": true,
    //     "canManageGroups": true,
    //     "canManageUsers": true,
    //     "canManageRole": true,
    //     "canAssignRole": true,
    // },
    // children: [
    {
        title: 'User Management',
        route: '/pages/user-management',
        permissions: {
            "canManageGroups": true,
            "canManageUsers": true,
        }
    },
    {
        title: 'Create / Add Group',
        route: '/pages/user-management/groups',
        permissions: {
            "canManageGroups": true,
        }
    },
    {
        title: 'Create / Add User',
        route: '/pages/user-management/users',
        permissions: {
            "canManageUsers": true,
            "canAssignGroup": true,
            "canAssignRole": true,
        }
    },
    {
        title: 'Create / Add Roles',
        route: '/pages/user-management/roles',
        permissions: {
            "canManageRole": true,
        }
    },
    // ],
    // },
];