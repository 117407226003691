import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-bootstrap-spinner';
import { Subscription } from 'rxjs';
import { LoginService } from '../services/login.service';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ErrorMessageService } from '../../services/error-message.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {
  fpForm: FormGroup;
  confirmfpForm: FormGroup;
  subscriptions: Subscription[] = [];
  errorMessage = "";
  showConfirmPassword = false;
  emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  horizontalPosition: MatSnackBarHorizontalPosition = 'end';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';
  passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;
  copyRightYear = '';
  constructor(
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private _loginService: LoginService,
    private toastr: ToastrService,
    private _router: Router,
    private errorMessageService: ErrorMessageService,
  ) {
    this.fpForm = this.formBuilder.group({
      email: ["", [Validators.required, Validators.pattern(this.emailRegex)]],
    });

    this.confirmfpForm = this.formBuilder.group({
      token: ["", [Validators.required]],
      password: ["", [Validators.required, Validators.pattern(this.passwordRegex)]],
      repeatPassword: ["", [Validators.required, Validators.pattern(this.passwordRegex)]],
    });

    this.subscriptions.push(
      this.errorMessageService.errorMessage.subscribe((errorMessagee) => {
        this.errorMessage = errorMessagee;
      })
    );
  }

  ngOnInit(): void {
    this.copyRightYear = this._loginService.copyRightYear;

    this.subscriptions.push(
      this.errorMessageService.errorMessage.subscribe((message) => {
        if (message) {
          message = message.split(':').pop();

          setTimeout(() => {
            this.errorMessage = '';
          }, 7000);
        }
      })
    );
  }

  ForgotPasswordRequest() {
    if (this.fpForm.valid) {

      this.spinner.show('fpLoading');
      setTimeout(() => {
        this._loginService.forgotPasswordRequest(this.fpForm.value.email).subscribe((result) => {

          if ((result as any).status == "OK") {
            this.showConfirmPassword = true;
            this.toastr.success('Forgot Password request sent successfully, Check your mailbox!');
            this.errorMessage = ""
            this.spinner.hide('fpLoading');
          }
          else {
            this.spinner.hide('fpLoading');
          }
        }, _ => {
          this.spinner.hide('fpLoading');
        });
      }, 0);
    }
  }

  ConfirmPasswordRequest() {
    if (this.confirmfpForm.valid) {

      this.spinner.show('cfpLoading');
      setTimeout(() => {
        this._loginService.forgotPasswordConfirmRequest(this.confirmfpForm.value.token, this.confirmfpForm.value.password)
          .subscribe((result) => {
            if ((result as any).status == "OK") {
              this.toastr.success('Password changed successfully');
              this.errorMessage = ""
              this.spinner.hide('cfpLoading');
              this._router.navigate(["login"])
            }
            else {
              this.spinner.hide('cfpLoading');
            }
          }, _ => {
            this.spinner.hide('cfpLoading');
          });
      }, 0);
    }
  }
  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });

    this.errorMessageService.errorMessage.next('');
  }
}
