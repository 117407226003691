import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';
// import { map, catchError } from 'rxjs/operators';
import * as CryptoJS from 'crypto-js';
import * as Base64 from 'crypto-js/enc-base64';
import { environment } from '../../../environments/environment';
import { APIRoutes } from '../../enums/api-routes.enum';
import { ErrorMessageService } from '../../services/error-message.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
// import { any } from '@amcharts/amcharts4/.internal/core/utils/Array';
// import { LayoutService } from 'src/app/shared/services/layout.service';

@Injectable()
export class LoginService {
  private authURL = environment.serverIP;
  private getIP = 'get_ip/';
  private logout_url = 'api/logout/';
  private currentIP = '';
  roles = [];
  permissionsArray = [];
  public copyRightYear = new Date().getFullYear().toString();
  public permissions = new BehaviorSubject<any>([]);

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Pragma: 'no-cache',
      'Cache-Control': 'no-cache, no-store, max-age=0, must-revalidate',
    }),
  };
  constructor(
    private http: HttpClient,
    private errorMessageService: ErrorMessageService,
    private router: Router,
    private toastr: ToastrService,
  ) { }

  // HMACGeneration(userKey, secretKey, args): Observable<any> {
  //   userKey = JSON.stringify(userKey);
  //   secretKey = JSON.stringify(secretKey);
  //   return new Observable((observer) => {
  //     this.getPublicIPAddress().subscribe(
  //       (data) => {
  //         let endPointResult: any;
  //         let response: any = data;
  //         if (response.status == 'ok') {
  //           let method = args.method;
  //           let timestamp = new Date().toISOString();
  //           let endPoint = args.endPoint;
  //           if (args.parameters) {
  //             // console.log(args, "args");
  //             let data = JSON.stringify(args.parameters);
  //             endPointResult = `${method}\n${response.ip}\n${endPoint}\n${timestamp}\n${data}`;
  //           } else {
  //             endPointResult = `${method}\n${response.ip}\n${endPoint}\n${timestamp}\n`;
  //           }
  //           let generatedHMAC = CryptoJS.algo.HMAC.create(
  //             CryptoJS.algo.SHA256,
  //             `${JSON.parse(secretKey)}`
  //           );
  //           generatedHMAC.update(endPointResult);
  //           let HMAC = Base64.stringify(generatedHMAC.finalize());
  //           observer.next({
  //             status: 'ok',
  //             HMAC_Sign: HMAC,
  //             user_key: JSON.parse(userKey),
  //             timeStamp: timestamp,
  //           });
  //           observer.complete();
  //         } else {
  //           this.errorMessageService.errorMessage.next(
  //             'Something went wrong in authentication, Please try again!'
  //           );
  //           observer.next({ status: 'error' });
  //           observer.complete();
  //         }
  //       },
  //       (err) => {
  //         this.errorMessageService.errorMessage.next(
  //           'Something went wrong in authentication, Please try again!'
  //         );
  //         observer.next({ status: 'error', err: err });
  //         observer.complete();
  //       }
  //     );
  //   });
  // }

  HMACGeneration(userKey, secretKey, args): Observable<any> {
    userKey = JSON.stringify(userKey);
    secretKey = JSON.stringify(secretKey);
    return new Observable((observer) => {
      this.currentIP = this.currentIP || localStorage.getItem('currentIP');
      let endPointResult: any;
      let response = null;
      let method = args.method;
      let timestamp = new Date().toISOString();
      let endPoint = args.endPoint;

      if (this.currentIP) {
        response = this.currentIP;

        if (args.parameters) {
          // console.log(args, "args");
          let data = JSON.stringify(args.parameters);
          endPointResult = `${method}\n${response}\n${endPoint}\n${timestamp}\n${data}`;
        } else {
          endPointResult = `${method}\n${response}\n${endPoint}\n${timestamp}\n`;
        }
        let generatedHMAC = CryptoJS.algo.HMAC.create(
          CryptoJS.algo.SHA256,
          `${JSON.parse(secretKey)}`
        );
        generatedHMAC.update(endPointResult);
        let HMAC = Base64.stringify(generatedHMAC.finalize());
        observer.next({
          status: 'ok',
          HMAC_Sign: HMAC,
          user_key: JSON.parse(userKey),
          timeStamp: timestamp,
        });
        observer.complete();
      } else {
        this.getPublicIPAddress().subscribe(
          (data) => {
            response = data;
            if (response.status == 'ok') {
              if (args.parameters) {
                // console.log(args, "args");
                let data = JSON.stringify(args.parameters);
                endPointResult = `${method}\n${response.ip}\n${endPoint}\n${timestamp}\n${data}`;
              } else {
                endPointResult = `${method}\n${response.ip}\n${endPoint}\n${timestamp}\n`;
              }
              let generatedHMAC = CryptoJS.algo.HMAC.create(
                CryptoJS.algo.SHA256,
                `${JSON.parse(secretKey)}`
              );
              generatedHMAC.update(endPointResult);
              let HMAC = Base64.stringify(generatedHMAC.finalize());
              observer.next({
                status: 'ok',
                HMAC_Sign: HMAC,
                user_key: JSON.parse(userKey),
                timeStamp: timestamp,
              });
              observer.complete();
            } else {
              this.errorMessageService.errorMessage.next(
                'Something went wrong in authentication, Please try again!'
              );
              observer.error({ status: 'error' });
              observer.complete();
            }
          },
          (err) => {
            this.errorMessageService.errorMessage.next(
              'Something went wrong in authentication, Please try again!'
            );
            observer.error({ status: 'error', err: err });
            observer.complete();
          }
        );
      }
    });
  }

  loginUser(credentials): Observable<any> {
    return new Observable((observer) => {
      this.http
        .post(
          this.authURL + APIRoutes.authToken,
          { email: credentials.email.trim(), password: credentials.password.trim() },
          this.httpOptions
        )
        .subscribe(
          (data) => {
            let response: any = data;
            if (response && response.token) {
              // set current IP in session once
              this.getPublicIPAddress().subscribe(
                (data) => {
                  let response: any = data;
                  if (response.status == 'ok') {
                    this.currentIP = response.ip;

                    // cache IP address
                    localStorage.setItem('currentIP', this.currentIP);
                  } else {
                    this.errorMessageService.errorMessage.next('Error in getting IP address');
                  }
                },
                (err) => {
                  this.errorMessageService.errorMessage.next('Internal Server Error');
                }
              );
              observer.next({ status: 'ok', token: response.token });
              observer.complete();
            } else {
              if (response.non_field_errors && response.non_field_errors.length) {
                this.errorMessageService.errorMessage.next(
                  response.non_field_errors[0] || 'Invalid Credentials provided!'
                );
              } else if (response.error) {
                this.errorMessageService.errorMessage.next(
                  response.error || 'Something went wrong in logged in user, Please try again later!'
                );
              }
              else if (response.data) {
                this.errorMessageService.errorMessage.next(
                  response.data?.status || 'Something went wrong in logged in user, Please try again later!'
                );
              }
              observer.error({ status: 'error' });
              observer.complete();
            }
          },
          (err) => {
            let errorMessage = err?.error?.error ||
              'Something went wrong in logged in user, Please try again later!';
            this.errorMessageService.errorMessage.next(
              errorMessage
            );
            observer.error({ status: 'error', err: errorMessage });
            observer.complete();
          }
        );
    });
  }

  setHeaders(hmacData) {
    let options;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
      Authorization: 'jwt ' + JSON.parse(hmacData.token),
      Signature: hmacData.HMAC_Sign,
      Key: hmacData.user_key,
      timestamp: hmacData.timeStamp,
      Pragma: 'no-cache',
      'Cache-Control': 'no-cache, no-store, max-age=0, must-revalidate',
    });
    options = {
      headers: headers,
    };

    return options;
  }

  getFromLocal(item) {
    return localStorage.getItem(item);
  }

  getKeys() {
    let credentials: any;
    if (
      localStorage.getItem('userKey') &&
      localStorage.getItem('secretKey')
    ) {
      credentials = {
        userKey: localStorage.getItem('userKey'),
        secretKey: localStorage.getItem('secretKey'),
      };
    }
    return credentials;
  }

  getRoleDetails(id): Observable<any> {
    return new Observable((observer) => {
      let keys = this.getKeys();

      if (keys) {
        this.HMACGeneration(keys.userKey, keys.secretKey, {
          endPoint: '/' + APIRoutes.roleDetails,
          method: 'POST',
          parameters: { role_id: id },
        }).subscribe((res) => {
          let headerOptions = {
            HMAC_Sign: res.HMAC_Sign,
            user_key: res.user_key,
            timeStamp: res.timeStamp,
          };
          this.http
            .post(
              this.authURL + APIRoutes.roleDetails,
              { role_id: id },
              this.setHeaders(headerOptions)
            )
            .subscribe(
              (res) => {
                let response: any = res;
                if (response && response.data) {
                  observer.next({
                    status: 'ok',
                    roleDetails:
                      response.data &&
                        response.data.roles &&
                        Object.keys(response.data.roles).length
                        ? response.data.roles
                        : undefined,
                  });
                  observer.complete();
                }
              },
              (err) => {
                if (err.error) {
                  this.errorMessageService.errorMessage.next(
                    err?.error?.error ||
                    'Something went wrong in getting role details'
                  );
                  observer.error({ status: 'error' });
                  observer.complete();
                }
              }
            );
        });
      } else {
        this.errorMessageService.errorMessage.next('Something went wrong in getting role details');
        observer.error({ status: 'error' });
        observer.complete();
        this.router.navigate(['login']);
        this.toastr.info(
          'Session Expired!'
        );
      }
    });
  }

  checkAuth(userkey, secretkey, token): Observable<any> {
    return new Observable((observer) => {
      this.HMACGeneration(userkey, secretkey, {
        endPoint: '/' + APIRoutes.heartBeat,
        method: 'GET',
      }).subscribe((res) => {
        let headerOptions = {
          HMAC_Sign: res.HMAC_Sign,
          user_key: res.user_key,
          timeStamp: res.timeStamp,
          token: token,
        };

        this.http
          .get(
            this.authURL + APIRoutes.heartBeat,
            this.setHeaders(headerOptions)
          )
          .subscribe(
            (res) => {
              let response: any = res;
              if (response && response.data) {
                observer.next({
                  status: 'ok',
                  userID: response.data.used_id,
                  user_type: response.data.user_type,
                  // user_FN: response?.data?.first_name?.toUpperCase()[0] || '',
                  // user_LN: response?.data?.last_name?.toUpperCase()[0] || '',
                });
                observer.complete();
              }
            },
            (err) => {
              if (err.error) {
                this.errorMessageService.errorMessage.next(
                  err?.error?.error ||
                  'Invalid secret or user key provided, Please try again!'
                );
              }
              observer.error({ status: 'error' });
              observer.complete();
            }
          );
      });
    });
  }

  forgotPasswordRequest(email): Observable<any> {
    return new Observable((observer) => {
      this.http
        .post(
          this.authURL + APIRoutes.passwordReset,
          { email: email },
          this.httpOptions
        )
        .subscribe(
          (data) => {
            let response: any = data;
            if (response && response.status == 'OK') {
              observer.next({ status: 'OK' });
              observer.complete();
            } else {
              this.errorMessageService.errorMessage.next(
                'There is no active user associated with this e-mail address or the password can not be changed'
              );
              observer.error({ status: 'error' });
              observer.complete();
            }
          },
          (err) => {
            if (err.error.email && err.error.email.length) {
              this.errorMessageService.errorMessage.next(
                'There is no active user associated with this e-mail address or the password can not be changed'
              );
            } else {
              this.errorMessageService.errorMessage.next(
                'Service Temporarily Unavailable, please try again'
              );
            }
            observer.error({ status: 'error' });
            observer.complete();
          }
        );
    });
  }

  forgotPasswordConfirmRequest(token, password): Observable<any> {
    return new Observable((observer) => {
      this.http
        .post(
          this.authURL + APIRoutes.passwordResetConfirm,
          { token: token, password: password },
          this.httpOptions
        )
        .subscribe(
          (data) => {
            let response: any = data;
            if (response && response.status == 'OK') {
              observer.next({ status: 'OK' });
              observer.complete();
            } else {
              this.errorMessageService.errorMessage.next('Invalid token,Request again!');
              observer.error({ status: 'error' });
              observer.complete();
            }
          },
          (err) => {
            if (err.error.status == 'notfound') {
              this.errorMessageService.errorMessage.next('Invalid token,Request again!');
            } else {
              this.errorMessageService.errorMessage.next(
                'Service Temporarily Unavailable, please try again'
              );
            }
            observer.error({ status: 'error' });
            observer.complete();
          }
        );
    });
  }

  getRolesAgainstUser(id?: any): Observable<any> {
    let passingData = {};
    if (id) passingData = { user_id: id.toString() };
    else passingData = { key: 'value' };
    return new Observable((observer) => {
      let keys = this.getKeys();
      let token = this.getFromLocal('currentUserCaseMgt');
      if (keys) {
        this.HMACGeneration(keys.userKey, keys.secretKey, {
          endPoint: '/' + APIRoutes.rolesAgainstUser,
          method: 'POST',
          parameters: passingData,
        }).subscribe((res) => {
          let headerOptions = {
            HMAC_Sign: res.HMAC_Sign,
            user_key: res.user_key,
            timeStamp: res.timeStamp,
            token: JSON.stringify(token),
          };

          this.http
            .post(
              this.authURL + APIRoutes.rolesAgainstUser,
              passingData,
              this.setHeaders(headerOptions)
            )
            .subscribe(
              (res) => {
                let response: any = res;
                // console.log(response);
                if (response && response.data) {
                  observer.next({
                    status: 'ok',
                    userRoles:
                      response.data &&
                        response.data.roles &&
                        response.data.roles.length
                        ? response.data.roles
                        : [],
                  });
                  observer.complete();
                }
              },
              (err) => {
                if (err.error) {
                  this.errorMessageService.errorMessage.next(
                    err?.error?.error ||
                    'Something went wrong in logging in, Please Try again later!');
                  observer.error({ status: 'error' });
                  observer.complete();
                }
              }
            );
        });
      } else {
        observer.error({ status: 'error' });
        observer.complete();
        this.router.navigate(['login']);
        this.toastr.info(
          'Session Expired!'
        );
      }
    });
  }

  // getPublicIPAddress(): Observable<any> {
  //   return new Observable((observer) => {
  //     // observer.next({ status: 'ok', ip: '192.168.100.50' });
  //     // observer.complete();

  //     // https://stackoverflow.com/questions/391979/how-to-get-clients-ip-address-using-javascript
  //     // This link includes alternative ways to find public ip.
  //     this.http.get('https://api.ipify.org/?format=json').subscribe(
  //       (data) => {
  //         let response: any = data;
  //         if (response) {
  //           observer.next({ status: 'ok', ip: response['ip'] });
  //           observer.complete();
  //         } else {
  //           observer.next({ status: 'error' });
  //           observer.complete();
  //         }
  //       },
  //       (err) => {
  //         this.errorMessageService.errorMessage.next('Internal Server Error');
  //         observer.next({ status: 'error', code: 500 });
  //         observer.complete();
  //       }
  //     );
  //   });
  // }

  getPublicIPAddress(): Observable<any> {
    return new Observable((observer) => {
      this.http.get(this.authURL + this.getIP, this.httpOptions).subscribe(
        (data) => {
          let response: any = data;
          if (response) {
            observer.next({ status: 'ok', ip: response });
            observer.complete();
          } else {
            observer.error({ status: 'error' });
            observer.complete();
          }
        },
        (err) => {
          this.errorMessageService.errorMessage.next('Internal Server Error');
          observer.error({ status: 'error', code: 500 });
          observer.complete();
        }
      );
    });
  }

  logout(): Observable<any> {
    return new Observable((observer) => {
      let passingData = {};
      let keys = this.getKeys();
      let token = this.getFromLocal('currentUserCaseMgt');
      if (keys) {
        this.HMACGeneration(keys.userKey, keys.secretKey, {
          endPoint: '/' + APIRoutes.logout,
          method: 'POST',
          parameters: passingData,
        }).subscribe((res) => {
          let headerOptions = {
            HMAC_Sign: res.HMAC_Sign,
            user_key: res.user_key,
            timeStamp: res.timeStamp,
            token: JSON.stringify(token),
          };
        this.http.post(this.authURL + this.logout_url, passingData, this.setHeaders(headerOptions)).subscribe(
          (data) => {
            let response: any = data;
            if (response) {
              observer.next({ status: 'ok', data: response.data });
              observer.complete();
            } else {
              observer.error({ status: 'error', error: response.error });
              observer.complete();
            }
          },
          (err) => {
            // this.errorMessageService.errorMessage.next('Internal Server Error');
            observer.error({ status: 'error', error: err.error });
            observer.complete();
          }
        );
      });
    }
    })
  }
}
