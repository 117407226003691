import { Injectable } from "@angular/core";
import { CanActivate, CanLoad, Router } from "@angular/router";
import { UserType } from "../../enums/user-type.enum";

@Injectable()
export class RedirectGuard implements CanLoad, CanActivate {
    userDetails = {
        email: '',
        user_type: ''
    };

    constructor(
        private router: Router
    ) { }

    canActivate(): boolean {
        this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
        if (!!localStorage.getItem('currentUserCaseMgt') == true) {
            if (this.userDetails) {
                if (this.userDetails.user_type == UserType.ADM) {
                    this.router.navigate(['pages/case-management']);
                    return false;
                } else if (this.userDetails.user_type == UserType.LDM) {
                    this.router.navigate(['pages/lfd-admin']);
                    return false;
                } else if (this.userDetails.user_type == UserType.RGU) {
                    let gotoRoute = 'pages/screening';
                    const allowedRoutes = JSON.parse(localStorage.getItem('allowedRoutes'));

                    if (allowedRoutes && allowedRoutes.length) {
                        allowedRoutes.some((r) => {
                            if (r.route.includes('screening')) {
                                gotoRoute = 'pages/screening';
                                return true;
                            } else if (r.route.includes('case')) {
                                gotoRoute = r.route;
                                return true;
                            } else if (r.route.includes('user')) {
                                gotoRoute = r.route;
                                return true;
                            }
                            return false;
                        });
                    }

                    this.router.navigate([gotoRoute]);
                    return false;
                }
            } else {
                localStorage.clear();
                sessionStorage.clear();
                this.router.navigate(['login']);
            }

            return false;
        }

        return true;
    }

    canLoad(): boolean {
        this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
        if (!!localStorage.getItem('currentUserCaseMgt') == true) {
            if (this.userDetails) {
                if (this.userDetails.user_type == UserType.ADM) {
                    this.router.navigate(['pages/case-management']);
                    return false;
                } else if (this.userDetails.user_type == UserType.LDM) {
                    this.router.navigate(['pages/lfd-admin']);
                    return false;
                } else if (this.userDetails.user_type == UserType.RGU) {
                    let gotoRoute = 'pages/screening';
                    const allowedRoutes = JSON.parse(localStorage.getItem('allowedRoutes'));

                    if (allowedRoutes && allowedRoutes.length) {
                        allowedRoutes.some((r) => {
                            if (r.route.includes('screening')) {
                                gotoRoute = 'pages/screening';
                                return true;
                            } else if (r.route.includes('case')) {
                                gotoRoute = r.route;
                                return true;
                            } else if (r.route.includes('user')) {
                                gotoRoute = r.route;
                                return true;
                            }
                            return false;
                        });
                    }

                    this.router.navigate([gotoRoute]);
                    return false;
                }
            } else {
                localStorage.clear();
                sessionStorage.clear();
                this.router.navigate(['login']);
            }

            return false;
        }

        return true;
    }
}