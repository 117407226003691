import { Component, OnInit,   } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-bootstrap-spinner';
import { NzFormatEmitEvent, NzTreeNodeOptions } from 'ng-zorro-antd/tree';
import { ConfirmDialogComponent } from '../../../../dialogs/confirmation-dialog/confirmation-dialog.component';
import { SettingService } from 'projects/case-manager/src/app/services/settingsService';
import {
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
  MatSnackBar,
} from '@angular/material/snack-bar';
import {
  NzContextMenuService,
  NzDropdownMenuComponent,
} from 'ng-zorro-antd/dropdown';
import { ToastrService } from 'ngx-toastr';
import { GlobalStateService } from 'projects/case-manager/src/app/services/globalStateService';
import { Subscription } from 'rxjs';
import { ErrorMessageService } from 'projects/case-manager/src/app/services/error-message.service';

@Component({
  selector: 'app-um-dashboard',
  templateUrl: './um-dashboard.component.html',
  styleUrls: ['./um-dashboard.component.scss'],
})
export class UmDashboardComponent implements OnInit {
  visible = false;
  isPermissionModalVisible = false;
  assignedPermissions = [];
  isPermissionLoading = false;
  usersAgainstGroup = [];
  fetchUserLoading = false;
  groups = [];
  subgroups = [];
  groupCollapse: any
  isSpinning = false;
  horizontalPosition: MatSnackBarHorizontalPosition = 'end';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';
  selectedGroupID = undefined;
  nodes = [
    { title: 'Sub Group A Name Here', key: '0' },
    { title: 'Sub Group B Name Here', key: '1' },
    { title: 'Sub Group C Name Here', key: '2' },
  ];
  subGroupsHash = {};
  permissions = undefined;
  subscriptions: Subscription[] = [];
  errorMessage = '';
  selectedGroup: Subscription;

  constructor(
    private settingService: SettingService,
    private spinner: NgxSpinnerService,
    private dialog: MatDialog,
    public _router: Router,
    public nzContextMenuService: NzContextMenuService,
    private toastr: ToastrService,
    private globalStateService: GlobalStateService,
    private errorMessageService: ErrorMessageService
  ) {
    const userDetails = JSON.parse(localStorage.getItem('userDetails'));
    const payload = {
      user_id: userDetails.id.toString(),
    };

    this.subscriptions.push(
      // this._globalStateService.getPermissions().subscribe((temp) => {
      this.settingService.getUserPermissions(payload).subscribe(
        (temp) => {
          if (temp.status == 'ok') {
            // this.permissions = res.permissions;
            if (
              temp['userPermissions'] &&
              temp['userPermissions']['permission_tags']
            ) {
              const permissionsArray = Array.from(
                new Set(temp['userPermissions']['permission_tags'])
              );
              this.permissions =
                this.globalStateService.MakePermissionsArray(permissionsArray);
            }
          } else {
            this.permissions = undefined;
          }
        },
        (err) => {
          this.permissions = undefined;
        }
      )
    );
  }

  ngOnInit(): void {
    this.fetchGroups();

    // Show Error message
    this.subscriptions.push(
      this.errorMessageService.errorMessage.subscribe((message) => {
        if (message) {
          this.errorMessage = message.split(':').pop();

          setTimeout(() => {
            this.errorMessage = '';
          }, 7000);
        }
      })
    );
  }

  fetchGroups() {
    this.isSpinning = true;
    this.spinner.show('groupsLoader');

    this.settingService.getGroups().subscribe(
      (res) => {
        if (res.status == 'ok') {
          if (res.groups && res.groups.length) {
            this.groups = res.groups.filter((group) => !group.parent_id);
            this.subgroups = res.groups.filter((group) => group.parent_id);
            // console.log(this.subgroups)?
          }

          this.isSpinning = false;
          this.spinner.hide('groupsLoader');
        } else {
          this.isSpinning = false;
          this.spinner.hide('groupsLoader');
          this.groups = [];
        }
      },
      (err) => {
        this.isSpinning = false;
        this.spinner.hide('groupsLoader');
        this.groups = [];
      }
    );
  }

  getSelectedUserPermissions(userID) {
    const payload = {
      user_id: userID.toString(),
    };

    this.isPermissionLoading = true;

    this.settingService.getUserPermissions(payload).subscribe(
      (res) => {
        if (res.status == 'ok') {
          this.isPermissionLoading = false;
          this.assignedPermissions = res['userPermissions']['permission_tags'];
        } else {
          this.isPermissionLoading = false;
          this.assignedPermissions = [];
        }
      },
      (err) => {
        this.isPermissionLoading = false;
        this.assignedPermissions = [];
      }
    );
  }

  /**
   * Generate Tree Nodes
   * @param groupId
   * @returns
   */
  generateTreeNodes(groupId) {
    this.groups.forEach(element => {
      element.name = element.name.replace(" ", "");
      if (element.id != groupId) {
        document.getElementById(element.name).classList.remove('show')
        document.getElementById('btn'+element.name).classList.add('collapsed')
        document.getElementById('btn'+element.name).setAttribute("aria-expanded", 'false')
      } else {
        if (document.getElementById(element.name).classList.contains('show')) {
          document.getElementById(element.name).classList.remove('show')
          document.getElementById('btn'+element.name).classList.add('collapsed')
          document.getElementById('btn'+element.name).setAttribute("aria-expanded", 'false')

        } else {
          document.getElementById(element.name).classList.add('show')
          document.getElementById('btn'+element.name).classList.remove('collapsed')
          document.getElementById('btn'+element.name).setAttribute("aria-expanded", 'true')
        }
      }
    });
    if (this.subGroupsHash[groupId] && this.subGroupsHash[groupId].length) {
      return this.subGroupsHash[groupId];
    }
    if (!this.subGroupsHash[groupId]) {
      this.subGroupsHash[groupId] = [];
    }
    this.subgroups.forEach((sub) => {
      if (sub.parent_id === groupId) {
        sub.title = sub.name;
        sub.key = sub.id;
        this.subGroupsHash[groupId].push(sub);

      }
    });

    console.log(this.subGroupsHash[groupId])
    return this.subGroupsHash[groupId];
  }

  deleteGroup(event, group) {
    event.stopPropagation();
    this.dialog
      .open(ConfirmDialogComponent, {
        data: { message: 'Are you sure you want to delete this group?' },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.spinner.show('deleteLoading');
          const obj = {
            id: group.id || group.key,
            status: 'D',
          };
          this.settingService.updateGroup(obj, group.id).subscribe(
            (res) => {
              if ((res as any).status == 'ok') {
                this.toastr.success('Group deleted  successfully!');
                this.spinner.hide('deleteLoading');
                this.fetchGroups();
              } else {
                // this.toastr.error('Group deleted  successfully!');
                this.spinner.hide('deleteLoading');
              }
            },
            (err) => {
              // this.toastr.error('Error in deleting Group');
              this.spinner.hide('deleteLoading');
            }
          );
        } else {
          return;
        }
      });
  }

  editGroup(event, groupID) {
    event.stopPropagation();
    this._router.navigate([`pages/user-management/edit-group/${groupID}`]);
  }

  open(): void {
    this.visible = !this.visible;
  }

  close(): void {
    this.visible = false;
  }

  nzEvent(event: NzFormatEmitEvent): void {
    if (event.eventName === 'expand') {
      const node = event.node;
      if (node?.getChildren().length === 0 && node?.isExpanded) {
        this.loadNode(node['key']).then((data) => {
          if (data.length) {
            node.addChildren(data);
          } else {
            node.isLeaf = true;
          }
        });
      }
    }
  }

  loadNode(groupID): Promise<NzTreeNodeOptions[]> {
    return new Promise((resolve) => {
      setTimeout(() => resolve(this.generateTreeNodes(groupID)), 1000);
    });
  }

  getSelectedGroupUsers(groupID) {
    if (this.selectedGroupID != groupID) {
      this.selectedGroupID = groupID;
      // this.getUsersAgaintGroup(this.selectedGroupID);
    }
    this.generateTreeNodes(groupID);
  }

  getUsersAgaintGroup(groupID) {
    this.usersAgainstGroup = [];
    this.fetchUserLoading = true;
    const selectedGroupIdIndex = this.subgroups.findIndex(x => x.id === groupID)
    this.selectedGroupID = this.subgroups[selectedGroupIdIndex].parent_id
    this.settingService.listUsersAgainstGroup(groupID.toString()).subscribe(
      (listUsers) => {
        const response: any = listUsers;

        if (response.status == 'ok') {
          if (response.users && response.users.length) {
            this.usersAgainstGroup = response.users;
            this.usersAgainstGroup = this.usersAgainstGroup.map((i) => {
              return {
                ...i,
                parentID: this.selectedGroupID,
              };


            });
            this.selectedGroupID = this.usersAgainstGroup[0].parentID;
            this.fetchUserLoading = false;
          } else {
            this.usersAgainstGroup = [];
            this.fetchUserLoading = false;
          }
        } else {
          this.usersAgainstGroup = [];
          this.fetchUserLoading = false;
        }
      },
      (err) => {
        this.usersAgainstGroup = [];
        this.fetchUserLoading = false;
      }
    );
  }

  editUser(userID) {
    this._router.navigate([`/pages/user-management/edit-user/${userID}`]);
  }

  /// Modal JS ///
  viewPermissions(user) {
    this.isPermissionModalVisible = true;
    this.getSelectedUserPermissions(user.id);
  }

  handleOk(): void {
    this.isPermissionModalVisible = false;
    this.assignedPermissions = [];
  }

  handleCancel(): void {
    this.isPermissionModalVisible = false;
    this.assignedPermissions = [];
  }

  format(name) {
    let formatedString = '';

    if (!isNaN(name[0])) {
      formatedString = `a-${name.split(' ').join('')}`;
    } else {
      formatedString = name.split(' ').join('');
    }

    return formatedString;
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });

    this.errorMessageService.errorMessage.next('');
  }
}
