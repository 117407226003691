import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NzDrawerPlacement } from 'ng-zorro-antd/drawer';
import { HeaderService } from '../../header.service';
import { CaseManagementService } from 'projects/case-manager/src/app/services/caseManagementService';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from 'projects/case-manager/src/app/auth/services/login.service';

@Component({
  selector: 'lib-config-panel-header',
  templateUrl: './config-panel-header.component.html',
  styleUrls: ['./config-panel-header.component.scss'],
})
export class ConfigPanelHeaderComponent implements OnInit {
  visible = false;
  hideSidebarToggleButton = false;
  placement: NzDrawerPlacement = 'left';
  currentUrl = '';
  selectedGroupID = null;
  selectedGroup = null;
  userShortName = '';
  isLicenseDetailsVisible = false;

  @Input() public data: any;

  constructor(private router: Router, private headerService: LoginService, private toastr: ToastrService,) {}

  ngOnInit(): void {
    // console.log(this.data)
    this.currentUrl = window.location.pathname;
    if(this.currentUrl === '/pages/select-group'){
      this.currentUrl = '/pages/screening'
    }
    this.getRouteUrl = this.getRouteUrl.bind(this);
    window.addEventListener("popstate", () => this.getRouteUrl());
      
    const userType = localStorage.getItem('userType');

    if (userType === 'ADM') {
      this.isLicenseDetailsVisible = true;
    }

    this.selectedGroupID = JSON.parse(localStorage.getItem('selectedGroup'));
    this.hideSidebarToggleButton = this.data.hideSidebarToggleButton;

    if (
      this.selectedGroupID &&
      this.data.userGroups &&
      this.data.userGroups.length
    ) {
      this.selectedGroup = this.data.userGroups.find(
        (group) => group.id === this.selectedGroupID
      );
    }

    this.getUserShortName();
  }

  getRouteUrl(){
    this.currentUrl = window.location.pathname;
  }

  getUserShortName() {
    const userDetails = JSON.parse(localStorage.getItem('userDetails'));

    if (userDetails && userDetails.user_type === 'LDM') {
      if (userDetails.first_name || userDetails.last_name) {
        this.userShortName = this.getNameFirstCharacter(userDetails);
      } else {
        this.userShortName = 'L';
      }
    } else if (userDetails && userDetails.user_type === 'ADM') {
      if (userDetails.first_name || userDetails.last_name) {
        this.userShortName = this.getNameFirstCharacter(userDetails);
      } else {
        this.userShortName = 'A';
      }
    } else if (userDetails && userDetails.user_type === 'RGU') {
      if (userDetails.first_name || userDetails.last_name) {
        this.userShortName = this.getNameFirstCharacter(userDetails);
      } else {
        this.userShortName = 'U';
      }
    } else {
      this.userShortName = 'N/A';
    }
  }

  getNameFirstCharacter(userDetails) {
    const firstName = userDetails.first_name
      ? userDetails.first_name[0].toUpperCase()
      : '';
    const lastName = userDetails.last_name
      ? userDetails.last_name[0].toUpperCase()
      : '';

    return `${firstName}${lastName}`;
  }

  open(): void {
    this.visible = !this.visible;
  }

  close(): void {
    this.visible = false;
  }

  logout(): void {
    const user = localStorage.getItem('currentUserCaseMgt');

    // if (user) {
    //   localStorage.clear();
    //   sessionStorage.clear();
    //   this.router.navigate(['login']);
    //   // window.location.reload();
    // }
    this.headerService.logout().subscribe(
      (res) => {
      if (res.status == 'ok') {
          localStorage.clear();
          sessionStorage.clear();
          this.router.navigate(['login']);
          this.toastr.success(
            'Successfully logged out!'
          );
          window.location.reload();
        } else {
          this.toastr.error(res.error || 'Error in logging out!');
          window.location.reload();
        }
        },
        (err) => {
          this.toastr.error('Error in logging out!');
          window.location.reload();
        }
      );
  }

  gotoRoute(route: string) {
    this.router.navigate([route]);
    this.currentUrl = route;
    this.close();
  }
}
