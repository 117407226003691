import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginModule } from './auth/modules/login.module';
import { NgxSpinnerModule } from "ngx-bootstrap-spinner";

import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import { ParticlesModule } from "angular-particle";
import { PageNotFoundComponent } from './components/errors/pageNotFound/pageNotFound.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

declare module "@angular/core" {
  interface ModuleWithProviders<T = any> {
    ngModule: Type<T>;
    providers?: Provider[];
  }
}
import { CaseManagementModule } from './modules/case-management.module';
import { LocationStrategy, PathLocationStrategy, registerLocaleData } from '@angular/common';
import { UserManagementModule } from './modules/user-management.module';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NZ_I18N } from 'ng-zorro-antd/i18n';
import { en_US } from 'ng-zorro-antd/i18n';
import en from '@angular/common/locales/en';
import { PipesModule } from './pipes/pipes.module';
import { ToastContainerModule, ToastrModule } from 'ngx-toastr';
import { FileSaverModule } from 'ngx-filesaver';

registerLocaleData(en);

@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    LoginModule,
    // ParticlesModule,
    TooltipModule.forRoot(),
    NgxSpinnerModule,
    BrowserAnimationsModule,
    UserManagementModule,
    CaseManagementModule,
    NgMultiSelectDropDownModule.forRoot(),
    ToastrModule.forRoot(),
    FormsModule,
    HttpClientModule,
    PipesModule,
    FileSaverModule,
    ToastContainerModule
  ],
  providers: [{ provide: LocationStrategy, useClass: PathLocationStrategy }, { provide: NZ_I18N, useValue: en_US }],
  bootstrap: [AppComponent]
})

export class AppModule { }
