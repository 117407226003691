import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-no-result',
  templateUrl: './no-result.component.html',
  styleUrls: ['./no-result.component.scss']
})
export class NoResultComponent implements OnInit {
  @Input('redirectionLink') redirectionLink: string;

  constructor(
    private router: Router,
  ) { }

  ngOnInit(): void { }

  goBack() {
    this.router.navigate([this.redirectionLink]);
  }

}
