import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NzDrawerPlacement } from 'ng-zorro-antd/drawer';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { NgxSpinnerService } from 'ngx-bootstrap-spinner';
import { SettingService } from 'projects/case-manager/src/app/services/settingsService';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ErrorMessageService } from 'projects/case-manager/src/app/services/error-message.service';
@Component({
  selector: 'app-add-role',
  templateUrl: './add-role.component.html',
  styleUrls: ['./add-role.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AddRoleComponent implements OnInit {
  URL = undefined;
  checked = true;
  date = null;
  errorMessage = '';
  favoritePie = [];
  horizontalPosition: MatSnackBarHorizontalPosition = 'end';
  itemsPerPage = 1;
  loadingRoles = false;
  organizations = [];
  pageControl: number = 1;
  permTags = [];
  permTagsOnEdit = [];
  placement: NzDrawerPlacement = 'left';
  public addRoleForm: FormGroup;
  radioValue = 'A';
  roleDetails = {};
  roles = [];
  selectedPermissionTag = [];
  selectedRole: any;
  selectedRoleID = undefined;
  sortedData: any;
  statuses = [];
  subscriptions: Subscription[] = [];
  tempObj = {};
  toggleDropdown = false;
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';
  visible = false;

  constructor(
    private settingService: SettingService,
    private spinner: NgxSpinnerService,
    public formBuilder: FormBuilder,
    private _router: Router,
    private _route: ActivatedRoute,
    private toastr: ToastrService,
    private errorMessageService: ErrorMessageService,
  ) {
    this.URL = this._router.url;
    this.spinner.show('rolesLoading');
    this.loadingRoles = true;
    this.subscriptions.push(
      this.settingService.getRoles().subscribe((res) => {
        if (res.status == 'ok') {
          this.roles = res.roles;
          this.loadingRoles = false;
          this.spinner.hide('rolesLoading');
        } else {
          this.loadingRoles = false;
          this.spinner.hide('rolesLoading');
          this.roles = [];
        }
      })
    );
    this.subscriptions.push(
      this.settingService.getAllOrganizations().subscribe((res) => {
        if (res.status == 'ok') {
          res.organizations.map((val) => {
            this.organizations.push({
              value: val.id,
              viewValue: val.name
                .toLowerCase()
                .split(' ')
                .map((word) => {
                  return word.charAt(0).toUpperCase() + word.slice(1);
                })
                .join(' '),
            });
          });
        } else {
          this.organizations = [];
        }
      })
    );
    this.subscriptions.push(
      this.settingService.getChoices().subscribe((res) => {
        if (res.status == 'ok') {
          res.choices.alert_choices['STATUS_CHOICES'].map((temp) => {
            this.statuses.push({ value: temp[0], viewValue: temp[1] });
          });
        } else {
          this.statuses = [];
        }
      })
    );
    this.spinner.show('loadingPermTags');
    this.subscriptions.push(
      this.settingService.getPermissionsTag().subscribe((res) => {
        if (res.status == 'ok') {
          this.spinner.hide('loadingPermTags');
          if (res.permissions && res.permissions.length > 0) {
            this.permTags = res.permissions.map((permission) => {
              return {
                ...permission,
                checked: false,
              };
            });
            this.permTagsOnEdit = res.permissions;
            this.subscriptions.push(

              this._route.params.subscribe((params) => {
                if (params['id']) {
                  this.selectedRoleID = +params['id'];
                  if (this.selectedRoleID) {
                    this.getSelectedRoleDetails(this.selectedRoleID);
                  }
                }
              })
            );
          }
        } else {
          this.spinner.hide('loadingPermTags');
          this.permTags = [];
        }
      })
    );
    this.addRoleForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      status: ['A', []],
      permission_tag: [[], [Validators.required]],
      organization: [null, [Validators.required]],
    });
    const userDetails = JSON.parse(localStorage.getItem('userDetails'));
    if (userDetails && Object.keys(userDetails).length > 0) {
      this.addRoleForm.get('organization').setValue(userDetails.organization);
    }
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.errorMessageService.errorMessage.subscribe((message) => {
        if (message) {
          message = message.split(':').pop();

          if (message.includes('already exists')) {
            this.errorMessage = 'Role with this name is already exists';
          }
          else if (message.includes('IntegrityError')) {
            this.errorMessage = 'Something went wrong while completing task, please try again later!';
          } else {
            this.errorMessage = message;
          }

          setTimeout(() => {
            this.errorMessage = '';
          }, 7000);
        }
      })
    );
  }

  getSelectedRoleDetails(roleID) {
    this.spinner.show('fetchRoleDetails');
    this.settingService.getRoleDetails(roleID).subscribe(
      (res) => {
        if (res.status == 'ok') {
          this.spinner.hide('fetchRoleDetails');
          let getRole = res.roleDetails;
          if (getRole && Object.keys(getRole).length > 0) {
            this.roleDetails = {
              name: getRole.name,
              status: getRole.status,
            };
          }
          this.addRoleForm.patchValue(this.roleDetails);
          if (this.permTagsOnEdit && this.permTagsOnEdit.length > 0) {
            this.permTagsOnEdit = this.permTagsOnEdit.map((permTag) => {
              return {
                ...permTag,
                isChecked:
                  getRole.permission_tags && getRole.permission_tags.length > 0
                    ? getRole.permission_tags.includes(permTag.tag)
                      ? true
                      : false
                    : false,
              };
            });
          }
        } else {
          this.spinner.hide('fetchRoleDetails');
          this.roleDetails = {};
        }
      },
      (err) => {
        this.spinner.hide('fetchRoleDetails');
        this.roleDetails = {};
      }
    );
  }

  AddRole() {
    if (this.addRoleForm.valid) {
      let formValues = {
        name: this.addRoleForm.value.name,
        organization: this.addRoleForm.value.organization,
        permission_tag: this.TransformPermissionTags(
          this.addRoleForm.value.permission_tag
        ),
        status: this.addRoleForm.value.status,
      };
      this.spinner.show('addRoleLoading');
      this.settingService.addRole(formValues).subscribe((res) => {
        if (res.status == 'ok') {
          this.addRoleForm.reset();
          this.goToRoleList();
          this.toastr.success('Role added successfully!');
        }
        this.spinner.hide('addRoleLoading');
      }, _ => {
        this.spinner.hide('addRoleLoading');
      });
    }
  }

  onPageChange(page: number) {
    this.pageControl = page;
    this.roles.map((res) => (res['selected'] = false));
  }

  MapStatus(val) {
    let name = '';
    this.statuses.map((res) => {
      if (res.value.toLowerCase() == val.toLowerCase()) {
        name = res.viewValue;
      }
    });
    return name;
  }

  UpdateRole() {
    const payload = {
      permTagsEditRole:
        this.permTagsOnEdit && this.permTagsOnEdit.length > 0
          ? this.permTagsOnEdit
            .filter(({ isChecked }) => isChecked)
            .map(({ tag }) => tag)
            .join(',')
          : [],
      name: this.addRoleForm.value.name,
      statusEditRole: this.addRoleForm.value.status,
    };
    this.spinner.show('editRoleLoading');
    this.subscriptions.push(
      this.settingService.updateRole(payload, this.selectedRoleID).subscribe(
        (yz) => {
          if (yz.status == 'ok') {
            this.toastr.success('Role updated successfully!');
            this._router.navigate([`pages/user-management/roles`]);
          }
          this.spinner.hide('editRoleLoading');
        },
        (_) => {
          this.spinner.hide('editRoleLoading');
        }
      )
    );
  }

  TransformPermissionTags(tags) {
    let str = '';
    str = tags.join(',');
    return str;
  }

  selectedPermissions(permissionTag) {
    if (!this.selectedPermissionTag.includes(permissionTag)) {
      this.selectedPermissionTag.push(permissionTag);
    } else {
      const idx = this.selectedPermissionTag.findIndex(
        (item) => item === permissionTag
      );
      this.selectedPermissionTag.splice(idx, 1);
    }
    this.addRoleForm.get('permission_tag').setValue(this.selectedPermissionTag);
  }

  TransformStatus(status) {
    let str = '';
    this.statuses.map((res) => {
      if (res.value == status) {
        str = res.viewValue;
      }
    });
    return str;
  }

  TransformOrg(word) {
    let str = '';
    str = word
      .toLowerCase()
      .split(' ')
      .map((word) => {
        return word.charAt(0).toUpperCase() + word.slice(1);
      })
      .join(' ');
    return str;
  }

  Cancel() {
    this.addRoleForm.reset();
  }

  open(): void {
    this.visible = !this.visible;
  }

  close(): void {
    this.visible = false;
  }

  hideOverlay(toggleDropdown) {
    if (toggleDropdown) {
      document.body.setAttribute('class', 'hide-dropdown-overlay');
    } else {
      setTimeout(() => {
        document.body.setAttribute('class', '');
      }, 300);
    }
  }

  goToRoleList() {
    this._router.navigate(['/pages/user-management/roles']);
  }

  selectedPermTagOnEdit(permTag) {
    const duplicatePermTagsOnEdit = [...this.permTagsOnEdit];
    const idx = duplicatePermTagsOnEdit.findIndex(
      (tag) => permTag.id === tag.id
    );
    let getPermissionTag = duplicatePermTagsOnEdit[idx];
    if (getPermissionTag.isChecked) {
      getPermissionTag = {
        ...getPermissionTag,
        isChecked: false,
      };
    } else {
      getPermissionTag = {
        ...getPermissionTag,
        isChecked: true,
      };
    }
    duplicatePermTagsOnEdit.splice(idx, 1, getPermissionTag);
    this.permTagsOnEdit = [...duplicatePermTagsOnEdit];
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });

    this.errorMessageService.errorMessage.next('');
  }
}
