import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { LoginService } from '../services/login.service';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent implements OnInit {
  signUpForm: FormGroup;
  @ViewChild('hideDiv') hideDiv: ElementRef;

  whiteSpaceRegex = /^\S*$/;
  emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  copyRightYear = "";
  errorMessage = [];
  passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;
  subscriptions: Subscription[] = [];
  disableButton = false;
  passwordSame = false;
  termsandConditions: any;

  constructor(private formBuilder: FormBuilder, private _loginService: LoginService, private _router: Router) {
    this.signUpForm = this.formBuilder.group({
      firstName: [
        "",
        [Validators.required],
      ],
      lastName: [
        "",
        [],
      ],
      userName: ["", [Validators.required]],
      email: ["", [Validators.required, Validators.pattern(this.emailRegex)]],
      password: ["", [Validators.required, Validators.pattern(this.passwordRegex)]],
      repeatPassword: ["", [Validators.required, Validators.pattern(this.passwordRegex)]],
    });
  }

  ngOnInit() {
    this.copyRightYear = this._loginService.copyRightYear;
  }

  ShowErrors() {
    if (!this.signUpForm.valid || this.signUpForm.get('email').hasError('pattern') ||
      this.signUpForm.get('password').value == this.signUpForm.get('userName').value ||
      this.signUpForm.get('password').value != this.signUpForm.get('repeatPassword').value ||
      this.signUpForm.get('password').hasError('pattern') &&
      !this.termsandConditions) {
      return true
    } else {
      return false;
    }
  }
  SignUp() {
    if (this.signUpForm.valid && this.termsandConditions) {
      // console.log(this.signUpForm.value);
    }
    else return;

  }

  GotoLogin() {
    this._router.navigate(["login"])
  }

  FocusEvent() {
    if (this.hideDiv && this.hideDiv.nativeElement) {
      setTimeout(() => { this.hideDiv.nativeElement.style.display = "none" }, 2000)
    }
  }

}
