import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Route, Router, RouterStateSnapshot, UrlSegment } from "@angular/router";
import { Observable } from "rxjs";
import { UserType } from "../../enums/user-type.enum";

@Injectable()
export class AuthGuard implements CanActivate, CanLoad {
    userDetails = {
        email: '',
        user_type: ''
    };

    constructor(private _router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
        this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
        let allowedRoutes = JSON.parse(localStorage.getItem('allowedRoutes'));
        if (!!localStorage.getItem('currentUserCaseMgt') == true) {
            if (this.userDetails) {
                if (state.url.indexOf('case-management') !== -1 && (route.data.userType === this.userDetails.user_type
                    || this.userDetails.user_type === UserType.RGU)) {
                    return true;
                }
                else if (state.url.indexOf('user-management') !== -1 && (route.data.userType == this.userDetails.user_type
                    || allowedRoutes.some(item => item.title === "User Management"))) 
                {
                    return true;
                }
                else if (state.url.indexOf('lfd-admin') !== -1 && route.data.userType === this.userDetails.user_type) {
                    return true;
                }
                else if (state.url.indexOf('screening') !== -1 && route.data.userType === this.userDetails.user_type) {
                    return true;
                }
                else {
                    this._router.navigate(['login']);
                    return false;
                }
            }

            else {
                localStorage.clear();
                sessionStorage.clear();
                this._router.navigate(['login']);
            }

        } else {
            this._router.navigate(['login']);
        }

        return false;
    }

    canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
        this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
        let allowedRoutes = JSON.parse(localStorage.getItem('allowedRoutes'));
        if (!!localStorage.getItem('currentUserCaseMgt') == true) {
            if (this.userDetails) {
                if (route.path.indexOf('case-management') !== -1 && (route.data.userType === this.userDetails.user_type
                    || this.userDetails.user_type === UserType.RGU)) {
                    return true;
                }
                else if (route.path.indexOf('user-management') !== -1 && (route.data.userType == this.userDetails.user_type
                    || allowedRoutes.some(item => item.title === "User Management"))) 
                {
                    return true;
                }
                else if (route.path.indexOf('lfd-admin') !== -1 && route.data.userType == this.userDetails.user_type) {
                    return true;
                }
                else if (route.path.indexOf('screening') !== -1 && allowedRoutes.some(item => item.title === "Screening")) {
                    return true;
                }
                else {
                    this._router.navigate(['login']);
                    return false;
                }

            }
            else {
                localStorage.clear();
                sessionStorage.clear();
                this._router.navigate(['login']);
            }
        } else {
            this._router.navigate(['login']);
        }

        return false;
    }
}