import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgxSpinnerModule } from 'ngx-bootstrap-spinner';
import { CaselistComponent } from '../components/content/case-management/case-list/case-list.component';
import { CaseManagementComponent } from '../components/content/case-management/case-management.component';
import { CaseManagementRoutingModule } from '../routes/case-management-routing.module';
import { CaseViewComponent } from '../components/content/case-management/case-view/case-view.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NgxPopperModule } from 'ngx-popper';

import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { AntDesignModule } from 'src/app/modules/ant-design/ant-design.module';
import { PackageDetailsComponent } from '../components/content/case-management/package-details/package-details-component';
import { PipesModule } from '../pipes/pipes.module';
import { HeaderModule } from '../../../../header/src/lib/header.module';
import { MaterialModule } from 'src/app/modules/material/material.module';
import { CaseViewDetailComponent } from '../components/content/case-management/case-view/case-view-detail/case-view-detail.component';
import { CommonSharedModule } from '../components/common/shared/common-shared.module';

@NgModule({
  declarations: [
    CaselistComponent,
    CaseViewComponent,
    CaseManagementComponent,
    PackageDetailsComponent,
    CaseViewDetailComponent,
  ],
  imports: [
    CommonModule,
    CaseManagementRoutingModule,
    BsDatepickerModule.forRoot(),
    NgxPaginationModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    AngularSvgIconModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot(),
    NgxSkeletonLoaderModule.forRoot(),
    BsDropdownModule.forRoot(),
    // MatNativeDateModule,
    // MatDatepickerModule,
    // SatDatepickerModule,
    // SatNativeDateModule,
    MaterialModule,
    TooltipModule.forRoot(),
    NgxPopperModule.forRoot(),
    AntDesignModule,
    PipesModule,
    HeaderModule,
    CommonSharedModule
  ],
  exports: [NgxPopperModule],
  providers: [],
})
export class CaseManagementModule { }
