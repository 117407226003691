import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { LoginService } from '../auth/services/login.service';
import { ErrorMessageService } from './error-message.service';
import { SettingService } from './settingsService';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
@Injectable({
  providedIn: 'root',
})
export class GlobalStateService {
  roles = [];
  userGroups = [];
  allowedRoutes = [];
  permissionsArray = [];

  constructor(
    private _loginService: LoginService,
    private settingSvc: SettingService,
    private errorMessageService: ErrorMessageService,
    private router: Router,
    private toastr: ToastrService,
  ) {
    // if (sessionStorage.getItem('userID')) {
    //   this.getPermissions();
    // }
  }

  getPermissions(): Observable<any> {
    return new Observable((observer) => {
      let userDetails = JSON.parse(localStorage.getItem('userDetails'));
      this._loginService.getRolesAgainstUser(userDetails.id).subscribe((res) => {

        if (res.status == 'ok') {
          if (res.userRoles && res.userRoles.length) {
            res.userRoles.map((temp) => {
              this.roles.push({ value: temp.id, viewValue: temp.name });
            });
            let unique = [];
            this.roles.map((x) =>
              unique.filter((a) => a.value == x.value).length > 0
                ? null
                : unique.push(x)
            );

            if (unique && unique.length) {

              unique.forEach((item) => {
                this.settingSvc.getRoleDetails(item.value).subscribe(
                  (innerRes) => {
                    if (innerRes.status == 'ok') {
                      let permit = [
                        ...this.permissionsArray
                      ];
                      if (innerRes['roleDetails'] && innerRes['roleDetails']['permission_tags']) {
                        permit = [
                          ...innerRes['roleDetails']['permission_tags'],
                        ];
                      }
                      this.permissionsArray = Array.from(new Set(permit));
                      let allowPermissions = this.MakePermissionsArray(
                        this.permissionsArray
                      );

                      observer.next({
                        status: 'ok',
                        permissions: allowPermissions,
                      });
                      observer.complete();
                    }
                  },
                  (innerErr) => {
                    this.errorMessageService.errorMessage.next(
                      innerErr ||
                      'Something went wrong in getting permissions, Please try again later!'

                    );
                    observer.error({ status: 'error', permissions: undefined });
                    observer.complete();
                  }
                );
              });
            } else {
              this.errorMessageService.errorMessage.next(
                'Something went wrong in getting permissions, Please try again later!'
              );
              observer.error({ status: 'error', permissions: undefined });
              observer.complete();
              this.router.navigate(['login']);
            }
          } else {
            this.errorMessageService.errorMessage.next(
              'Something went wrong in getting permissions, Please try again later!'
            );
            observer.error({ status: 'error', permissions: undefined });
            observer.complete();
            this.router.navigate(['login']);
          }
        }
      }, _ => {
        this.errorMessageService.errorMessage.next(
          'Something went wrong in getting permissions, Please try again later!'
        );
        observer.error({ status: 'error', permissions: undefined });
        observer.complete();
        this.router.navigate(['login']);
        this.toastr.info(
          'Session Expired!'
        );
      });
    });
    // return this.permissions.getValue();
  }

  MakePermissionsArray(permissions) {
    let returningPermissions = {
      canDeleteCase: false,
      canOGSCase: false,
      canAssignGroup: false,
      canArchiveCase: false,
      canManageGroups: false,
      canScreenCase: false,
      canManageUsers: false,
      canResolveCase: false,
      canAssignRole: false,
      canManageRole: false,
      canReviewCase: false,
      canAssignCase: false,
      canAuditCase: false,
      canEscalate: false,
      canSearchBatch: false,
      canExportDetailedReport: false,
      canExportSummaryReport: false,
      canScreenZeroFootprint: false,
      canPerformCustomScreenAttr: false,
    };

    if (permissions.find((str) => str === 'Deletion'))
      returningPermissions.canDeleteCase = true;
    if (permissions.find((str) => str === 'Ongoing Screening'))
      returningPermissions.canOGSCase = true;
    if (permissions.find((str) => str === 'Assign Groups'))
      returningPermissions.canAssignGroup = true;
    if (permissions.find((str) => str === 'Archiving'))
      returningPermissions.canArchiveCase = true;
    if (permissions.find((str) => str === 'Manage Groups'))
      returningPermissions.canManageGroups = true;
    if (permissions.find((str) => str === 'Screening'))
      returningPermissions.canScreenCase = true;
    if (permissions.find((str) => str === 'Manage Users'))
      returningPermissions.canManageUsers = true;
    if (permissions.find((str) => str === 'Resolution'))
      returningPermissions.canResolveCase = true;
    if (permissions.find((str) => str === 'Assign Roles'))
      returningPermissions.canAssignRole = true;
    if (permissions.find((str) => str === 'Manage Roles'))
      returningPermissions.canManageRole = true;
    if (permissions.find((str) => str === 'Review'))
      returningPermissions.canReviewCase = true;
    if (permissions.find((str) => str === 'Custom Attributes Inclusion'))
      returningPermissions.canPerformCustomScreenAttr = true;
    if (permissions.find((str) => str === 'Assign'))
      returningPermissions.canAssignCase = true;
    if (permissions.find((str) => str === 'Escalation Resolution'))
      returningPermissions.canEscalate = true;
    if (permissions.find((str) => str === 'Export Cases Detail'))
      returningPermissions.canExportDetailedReport = true;
    if (permissions.find((str) => str === 'Export Cases Summary'))
      returningPermissions.canExportSummaryReport = true;
    if (permissions.find((str) => str === 'Auditing'))
      returningPermissions.canAuditCase = true;
    if (permissions.find((str) => str === 'Zero Footprint Screening'))
      returningPermissions.canScreenZeroFootprint = true;
    if (permissions.find((str) => str === 'Batch Search'))
      returningPermissions.canSearchBatch = true;

    return returningPermissions;
  }
}
