import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { FormGroup } from '@angular/forms';
import { DOCUMENT } from '@angular/common';
import { NgxSpinnerService } from 'ngx-bootstrap-spinner';
import { CaseManagementService } from 'projects/case-manager/src/app/services/caseManagementService';
import { GlobalStateService } from 'projects/case-manager/src/app/services/globalStateService';
import { SettingService } from 'projects/case-manager/src/app/services/settingsService';
import { nationalities } from 'projects/case-manager/src/assets/nationality';
import { ToastrService } from 'ngx-toastr';
import { createCleanedObject } from 'projects/case-manager/src/app/helpers/utility';
import { ErrorMessageService } from 'projects/case-manager/src/app/services/error-message.service';

@Component({
  selector: 'app-case-dynamic-filters',
  templateUrl: './case-dynamic-filters.component.html',
  styleUrls: ['./case-dynamic-filters.component.scss']
})
export class CaseDynamicFiltersComponent implements OnInit {
  subscriptions: Subscription[] = [];
  data = nationalities;
  searchTerm = '';
  start_date = '';
  end_date = '';
  nationals = [];
  filterModel = {};
  bindedData = [];
  filters = [];
  channelId = null;

  @Output() filterCaseDetails = new EventEmitter();
  @Input('channelId') set caseChannelID(channelId: any) {
    // console.log('channelId: ', channelId)
    if (channelId) {
      this.channelId = channelId;
      this.getPermissions();
    }
  };
  showNationalities = false;
  loading = false;
  havePermissions = false;

  dates = [];
  nationalities = [];
  filterForm: FormGroup;
  public dropdownSettingsSingle = {
    singleSelection: true,
    enableCheckAll: false,
    itemsShowLimit: 2,
    closeDropDownOnSelection: false,
  };
  public dynamicDropdownSettings = {
    singleSelection: false,
    itemsShowLimit: 2,
    enableCheckAll: false,
    clearSearchFilter: true,
    closeDropDownOnSelection: false,
  };

  public staticDropdownSettings = {
    singleSelection: false,
    enableCheckAll: false,
    itemsShowLimit: 2,
    closeDropDownOnSelection: false,
  };

  public staticDropdownSettingsSingleSelection = {
    singleSelection: true,
    enableCheckAll: false,
    itemsShowLimit: 3,
    clearSearchFilter: true,
    closeDropDownOnSelection: false,
  };

  public nationalitiesSettings = {
    singleSelection: false,
    itemsShowLimit: 2,
    allowSearchFilter: true,
    enableCheckAll: false,
    clearSearchFilter: true,
    closeDropDownOnSelection: false,
  };
  status = ['All'];
  assigned_to = '';
  userList = [];
  statusFilters = ['All', 'Active', 'Resolved', 'Archived', 'Deleted'];
  statuses = [];
  statusesFilters = [
    { value: ['A', 'C', 'R'], viewValue: 'All' },
    { value: 'A', viewValue: 'Active' },
    { value: 'R', viewValue: 'Resolved' },
    { value: 'C', viewValue: 'Archived' },
    { value: 'D', viewValue: 'Deleted' },
  ];

  duplicateErrorMsg = [];
  tempObj = {};
  matchingKey = '';
  actualJSON = [];
  errorMessage = '';
  dataError = false;
  finalObj = {};
  users = [];
  permissions = undefined;
  availableFilters = {
    gender: '',
    entity_type: '',
    date_of_birth: '',
    nationality: '',
    source_flag: ''
  };
  // rank: '',
  // list_type: '',
  // dataset_name: '',
  // programme_name: '',
  // publisher_name: '',
  userDetails = null;

  constructor(
    private caseMgtSvc: CaseManagementService,
    private spinner: NgxSpinnerService,
    private _globalStateService: GlobalStateService,
    @Inject(DOCUMENT) private document: Document,
    private settingService: SettingService,
    private toastr: ToastrService,
    private errorMessageService: ErrorMessageService,
    ) { }

  ngOnInit(): void {
    if (this.data) {
      this.actualJSON = (this.data as any).default;
      // this.nationalities = (this.data as any).default.map((twe) => {
      //   return twe.nationality;
      // });
    }

    this.subscriptions.push(
      this.settingService.getUsers().subscribe(
        (res) => {
          if (res.status == 'ok') {
            this.users = res.users;
            this.userList = res['users'].map((response) => {
              if (response.username) {
                return response.username;
              } else {
                return response.email;
              }
            });
          } else {
            this.userList = [];
          }
        },
        (err) => {
          this.userList = [];
        }
      )
    );

    // Show Error message
    this.subscriptions.push(
      this.errorMessageService.errorMessage.subscribe((message) => {
        if (message) {
          this.errorMessage = message.split(':').pop();

          setTimeout(() => {
            this.errorMessage = '';
          }, 7000);
        }
      })
    );
  }

  getPermissions(): void {
    this.userDetails = JSON.parse(localStorage.getItem('userDetails'));

    this.subscriptions.push(
      // this._globalStateService.getPermissions().subscribe((temp) => {
      //   console.log('permissions: ', temp);
      //   if (temp.status == 'ok') {
      //     this.permissions = temp.permissions;
      this.settingService.getUserPermissions({
        user_id: this.userDetails.id.toString(),
      }).subscribe(
        (res) => {
          // console.log('getUserPermissions: ', res);
          if (res.status == 'ok') {
            const permissions = res['userPermissions']['permission_tags'];
            const permissionsArray = Array.from(new Set(permissions));
            this.permissions = this._globalStateService.MakePermissionsArray(
              permissionsArray
            );
            if (this.permissions && Object.keys(this.permissions).length) {
              if (
                (this.permissions && this.permissions.canReviewCase) ||
                (this.permissions && this.permissions.canResolveCase) ||
                (this.permissions && this.permissions.canAssignCase) ||
                (this.permissions && this.permissions.canOGSCase) ||
                (this.permissions && this.permissions.canDeleteCase) ||
                (this.permissions && this.permissions.canArchiveCase) ||
                (this.permissions && this.permissions.canEscalate)
              ) {
                this.spinner.show('filtersLoading');
                this.havePermissions = true;
                this.loading = true;
                this.subscriptions.push(
                  this.caseMgtSvc.getCaseChannelById(this.channelId).subscribe(
                    res => {
                      if (res.status == 'ok') {
                        if (res.jobs && res.jobs.case_channel) {
                          this.filters = res.jobs.case_channel.filters;
                          this.filters = this.filters.map(f => {
                            f['label'] = f.key;
                            if(f['label'] == "entity_type"){
                              f.value = f.value.slice(0,3);
                            }
                            return f;
                          });
                        }
                        this.spinner.hide('filtersLoading');
                        this.loading = false;
                      } else {
                        this.spinner.hide('filtersLoading');
                        this.loading = false;
                      }
                    },
                    err => {
                      // this.toastr.error('error in getting case channel detail');
                      this.spinner.hide('filtersLoading');
                      this.loading = false;
                    }
                  )
                );
              }
            } else {
              this.spinner.hide('filtersLoading');
              this.loading = false;
            }
          } else {
            this.spinner.hide('filtersLoading');
            this.loading = false;
          }
        })
    );
  }

  TransformLabel(word) {
    let str = '';
    str = word.replace(/_/g, ' ');
    return str;
  }

  onDynamicItemSelect(event, value) {
    if (value == 'nationality') {
      if (this.showNationalities) {
        this.finalObj[value][0] = 'in';
        if (
          this.finalObj[value][1] &&
          Array.isArray(this.finalObj[value][1]) &&
          this.finalObj[value][1].length
        ) {
          this.finalObj[value][1].push(this.TransformNationality(event, value));
        } else {
          this.finalObj[value][1] = [];
          this.finalObj[value][1].push(this.TransformNationality(event, value));
        }
      } else {
        this.finalObj[value][0] = 'isnull';
        this.finalObj[value][1] = this.TransformNationality(event, value);
      }
    } else {
      this.filters.map((res) => {
        if (res.key == value) {
          this.finalObj[value][0] = res.operator;
          //in case
          if (this.finalObj[value][0] == 'in') {
            if (
              this.finalObj[value][1] &&
              Array.isArray(this.finalObj[value][1]) &&
              this.finalObj[value][1].length
            ) {
              this.finalObj[value][1].push(
                this.TransformValue(event, res.value)
              );
            } else {
              this.finalObj[value][1] = [];
              this.finalObj[value][1].push(
                this.TransformValue(event, res.value)
              );
            }
            this.finalObj[value].push();
          } else {
            //is null case
            this.finalObj[value][1] = this.TransformValue(event, res.value);
          }
        }
      });
    }
  }

  onDynamicItemDeSelect(event, value) {
    if (value == 'nationality') {
      if (this.showNationalities) {
        if (
          (this.finalObj[value][0] =
            'in' &&
            this.finalObj[value][1] &&
            Array.isArray(this.finalObj[value][1]) &&
            this.finalObj[value][1].length)
        ) {
          let index = this.finalObj[value].findIndex((tw) => tw == event);
          this.finalObj[value].splice(index, 1);
          if (!this.finalObj[value][1]) this.finalObj[value] = [];
        } else {
          this.finalObj[value] = [];
        }
      }
    } else {
      this.filters.map((res) => {
        if (res.key == value) {
          //in case
          if (this.finalObj[value][0] == 'in') {
            if (
              this.finalObj[value][1] &&
              Array.isArray(this.finalObj[value][1]) &&
              this.finalObj[value][1].length
            ) {
              let index = this.finalObj[value][1].findIndex(
                (tw) => tw == event
              );
              this.finalObj[value][1].splice(index, 1);

              if (!this.finalObj[value][1].length) this.finalObj[value][1] = [];
            }
          } else {
            //is null case
            this.finalObj[value] = [];
          }
        }
      });
    }
  }

  onItemSelectNationals(event) {
    this.actualJSON.map((res) => {
      if (res.nationality == event) {
        this.nationals.push(res.code);
      }
    });
  }

  TransformValue(value, options) {
    let str = '';
    options.map((ty) => {
      if (ty.option_value == value) str = ty.option_key;
    });
    return str;
  }

  TransformNationality(value, key) {
    let str = '';
    if (!this.showNationalities) {
      this.filters.map((res) => {
        if (res.key == key) {
          res.value.map((ret) => {
            if (ret.option_value == value) {
              str = ret.option_key;
            }
          });
        }
      });
    } else {
      this.actualJSON.map((national) => {
        if (national.nationality == value) {
          str = national.code;
        }
      });
    }
    return str;
  }
  BindData(key) {
    this.matchingKey = key;
    let data = [];
    this.filters.map((res) => {
      if (res.key == key) {
        data = res.value.map((twe) => {
          return twe.option_value;
        });
      }
    });
    this.bindedData = data;
    return;
  }

  DisableCondition(key) {
    let bool = false;
    if (key == 'nationality' && this.showNationalities) bool = true;
    else bool = false;
    return bool;
  }

  onCheck(event) {
    this.showNationalities = event;
    this.filterForm.get('nationality').setValue([]);
  }

  convert(str) {
    let date = new Date(str);
    let mnth = ('0' + (date.getMonth() + 1)).slice(-2);
    let day = ('0' + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join('-');
  }

  FilterByStatus(type, event) {
    // if (type == 'status' && event == 'All') {
    //   this.status = ['All'];
    // }

    if (this.status.length > 1) {
      this.status = this.status.filter((e) => e !== 'All');
    }

    if (this.status && this.status.length > 0) {
      let stats = [];
      this.status.forEach((res) =>
        this.statusesFilters.forEach((te) => {
          if (te.viewValue === res) {
            if (te.viewValue === 'All' && res === 'All') {
              stats = [...te.value];
            } else {
              stats.push(te.value);
            }
          }
        })
      );

      this.statuses = [...new Set(stats)];
    }
  }

  DeleteFilterStatus(type, event) {
    if (this.status.length === 0) {
      this.status = ['All'];
    }

    if (this.status && this.status.length > 0) {
      let stats = [];
      this.status.forEach((res) =>
        this.statusesFilters.forEach((te) => {
          if (te.viewValue === res) {
            if (te.viewValue === 'All' && res === 'All') {
              stats = [...te.value];
            } else {
              stats.push(te.value);
            }
          }
        })
      );

      this.statuses = [...new Set(stats)];
    }
  }

  noToClose(e) {
    e.stopPropagation();
  }

  onopendatepicker() {
    let elements = this.document.querySelectorAll('.mat-datepicker-content');

    elements[0].addEventListener('click', (evn) => {
      evn.stopPropagation();
    });
  }

  resetFilters() {
    this.availableFilters = {
      gender: '',
      entity_type: '',
      date_of_birth: '',
      nationality: '',
      source_flag: ''
    };
    this.applyFilters();
    // this.removeFirstOption();
    // this.addSelectPlaceholder();
    // rank: '',
    // list_type: '',
    // programme_name: '',
    // publisher_name: '',
    // this.caseMgtSvc.resetAction.next(true);
  }

  // addSelectPlaceholder(){
  //   var selectElements = document.getElementsByClassName("mySelect");

  //   for (var i = 0; i < selectElements.length; i++) {
  //     var selectElement = selectElements[i];
  //     var placeholderOption = document.createElement("option");
  //     placeholderOption.text = "Please Select";
  //     placeholderOption.disabled = true;
  //     placeholderOption.selected = true;
  //     selectElement.insertBefore(placeholderOption, selectElement.firstChild);
  //   }

  // }

  // removeFirstOption(){
  //   var selectAllElements = document.querySelectorAll(".mySelect");

  //   for (var i = 0; i < selectAllElements.length; i++) {
  //     var selectFirstElement = selectAllElements[i];
  //     selectFirstElement.querySelector("option:first-child").remove();
  //   }
  // }

  TransformValueArray(options, values) {
    let arr = [];
    if (values)
      values.map((ty, index) => {
        if (index != 0) {
          arr.push(this.TransformValue(options, ty));
        }
      });
    return arr;
  }

  // TransformIsNull(option, key) {
  //   let val = '';
  //   this.filters.map((tp) => {
  //     if (tp.key == key) {
  //       val = this.TransformValue(tp.value, option);
  //     }
  //   });
  //   return val;
  // }

  applyFilters() {
    this.caseMgtSvc.submitSearchRecordList.next(this.availableFilters);
    let availableFiltersKeys = Object.keys(createCleanedObject(this.availableFilters));
    let selectedFilters = this.filters.map((filter) => {
      if (availableFiltersKeys.includes(filter.key)) {
        if(filter.key === 'nationality'){
          if(this.availableFilters[filter.key] === 'Y' || this.availableFilters[filter.key] === 'N'){
            return { [filter.key]: ['isnull', [this.availableFilters[filter.key]]] }
          }
          else{
            return { [filter.key]: ['in', [this.availableFilters[filter.key]]] }
          }
        }
        else{
          return { [filter.key]: [filter.operator, [this.availableFilters[filter.key]]] }
        }
      }
      return null;
    }).filter(Boolean);
    
    let filterObj = {};
    selectedFilters.forEach(filter => {
      Object.keys(filter).forEach(key => {
        filterObj[key] = filter[key];
      });
    });
    this.filterCaseDetails.emit(filterObj);
  }

  TransformUser(username) {
    let id: any;
    this.users.map((tp) => {
      if (tp.username == username) id = tp.id;
    });
    return id;
  }

  BindSettings(key) {
    let str: any;
    this.filters.map((res) => {
      if (res.key == key) {
        if (res.operator == 'in') str = this.dynamicDropdownSettings;
        else if (res.operator == 'isnull') str = this.dropdownSettingsSingle;
        else str = this.dropdownSettingsSingle;
      }
    });
    return str;
  }

  SearchTermCase() {

  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });

    this.errorMessageService.errorMessage.next('');
  }
}
