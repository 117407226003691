import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NoResultComponent } from '../no-result/no-result.component';
import { MaterialModule } from 'src/app/modules/material/material.module';
import { CaseDynamicFiltersComponent } from '../../content/case-management/case-dynamic-filters/case-dynamic-filters.component';
import { PipesModule } from '../../../pipes/pipes.module';
import { AntDesignModule } from 'src/app/modules/ant-design/ant-design.module';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { NgxPopperModule } from 'ngx-popper';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgxSpinnerModule } from 'ngx-bootstrap-spinner';

@NgModule({
  declarations: [
    NoResultComponent,
    CaseDynamicFiltersComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    NgxSpinnerModule,
    MaterialModule,
    PipesModule,
    MaterialModule,
    AntDesignModule,
    AngularSvgIconModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot(),
    NgxSkeletonLoaderModule.forRoot(),
    BsDropdownModule.forRoot(),
    TooltipModule.forRoot(),
    NgxPopperModule.forRoot(),
  ],
  exports: [
    NoResultComponent,
    CaseDynamicFiltersComponent
  ]
})
export class CommonSharedModule { }
