import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzTreeModule } from 'ng-zorro-antd/tree';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    NzIconModule,
    NzDrawerModule,
    NzIconModule,
    NzDrawerModule,
    NzDatePickerModule,
    NzTableModule,
    NzDropDownModule,
    NzSwitchModule,
    NzModalModule,
    NzIconModule,
    NzDrawerModule,
    NzDatePickerModule,
    NzTableModule,
    NzDropDownModule,
    NzSwitchModule,
    NzModalModule,
    NzRadioModule,
    NzUploadModule,
    NzIconModule,
    NzDrawerModule,
    NzTreeModule,
    NzCheckboxModule,
    NzSelectModule,
    NzTabsModule,
    NzInputModule,
    NzToolTipModule,
  ],
  exports: [
    NzIconModule,
    NzDrawerModule,
    NzIconModule,
    NzDrawerModule,
    NzDatePickerModule,
    NzTableModule,
    NzDropDownModule,
    NzSwitchModule,
    NzModalModule,
    NzIconModule,
    NzDrawerModule,
    NzDatePickerModule,
    NzTableModule,
    NzDropDownModule,
    NzSwitchModule,
    NzModalModule,
    NzRadioModule,
    NzUploadModule,
    NzIconModule,
    NzDrawerModule,
    NzTreeModule,
    NzCheckboxModule,
    NzSelectModule,
    NzTabsModule,
    NzInputModule,
    NzToolTipModule,
  ],
  providers: [NzMessageService],
})
export class AntDesignModule {}
