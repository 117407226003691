import { Component, Inject, OnInit } from "@angular/core";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_spiritedaway from "@amcharts/amcharts4/themes/spiritedaway";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import * as am4plugins_forceDirected from "@amcharts/amcharts4/plugins/forceDirected";
import * as am4plugins_bullets from "@amcharts/amcharts4/plugins/bullets";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "app-forcedDirected",
  templateUrl: "./forcedDirected.component.html",
  styleUrls: ["./forcedDirected.component.scss"],
})
export class ForcedDirectedGraphComponent implements OnInit {
  private chart: any;
  plottingData = [];
  // data = [
  //   { name: "Zulfiqar Ali Bhutto", relation: "Father In Law" },
  //   { name: "Azra Fazal Pechuho", relation: "Brother" },
  //   { name: "Azra Fazal Pechuho", relation: "Sister" },
  //   { name: "Abdul Razzak Yaqub", relation: "Associate" },
  //   { name: "Ara Zardari", relation: "Mother" },
  //   { name: "Aseefa Bhutto Zardari", relation: "Daughter" },
  //   { name: "Aslam Hayat Qureshi", relation: "Associate" },
  //   { name: "Awais Muzaffar", relation: "Half Brother" },
  //   { name: "Bakhtawar Bhutto Zardari", relation: "Daughter" },
  //   { name: "Benazir Bhutto", relation: "Spouse" },
  //   { name: "Bilawal Bhutto Zardari", relation: "Son" },
  //   { name: "Bilquis Sultana Zardari", relation: "Mother" },
  //   { name: "Fazalullah Pechuho", relation: "Brother In Law" },
  //   { name: "Faryal Talpur", relation: "Sister" },
  //   { name: "Hakim Ali Zardari", relation: "Father" },
  //   { name: "Munawar Talpur", relation: "Brother In Law" },
  //   { name: "Murtaza Bhutto", relation: "Brother in Law" },
  //   { name: "Owais Muzaffar", relation: "Half Brother" },
  //   { name: "Sanam Bhutto", relation: "Sister in Law" },
  //   { name: "Shahnawaz Bhutto", relation: "Brother in Law" },
  // ];

  data = [
    { name: "Aziz Abbas Sharif", relation: "brother" },
    { name: "Abbas Sharif", relation: "son" },
    { name: "Hamza Shehbaz Shaif", relation: "cousin" },
    { name: "Maryam Nawaz Shareef", relation: "sister" },
    { name: "Mian Muhammad Nawaz Shareef", relation: "father" },
    { name: "Mian Uhammad Shahbaz Shareef", relation: "brother" },
    { name: "Salma Abbas Sharif", relation: "cousin" },
    { name: "Sara Abbas Sharif", relation: "sister" },
    { name: "Subiha Abbas", relation: "mother" },
  ]

  constructor(public dialogRef: MatDialogRef<ForcedDirectedGraphComponent>, @Inject(MAT_DIALOG_DATA) public injectedData: any) { }

  ngOnInit() {
    //Transfrom data into am4chart manner

    this.plottingData = this.TransformData(this.data);
    if (this.plottingData) {
      // Theme applied

      am4core.useTheme(am4themes_spiritedaway);
      am4core.useTheme(am4themes_animated);

      // Create chart
      let chart = am4core.create(
        "chartdiv",
        am4plugins_forceDirected.ForceDirectedTree
      );

      this.chart = chart;

      //hide logo of am4charts
      chart.logo.height = -1500;
      chart.zoomable = true;
      chart.paddingRight = am4core.percent(10);
      // chart.legend = new am4charts.Legend();
      // For zoomout key
      chart.zoomOutButton.background.cornerRadius(5, 5, 5, 5);
      chart.zoomOutButton.background.fill = am4core.color("#25283D");
      chart.zoomOutButton.icon.stroke = am4core.color("#EFD9CE");
      chart.zoomOutButton.icon.strokeWidth = 2;
      chart.zoomOutButton.background.states.getKey(
        "hover"
      ).properties.fill = am4core.color("#606271");
      // Create series
      var series = chart.series.push(
        new am4plugins_forceDirected.ForceDirectedSeries()
      );

      // Set data
      series.data = this.plottingData;
      // Set up data fields
      series.dataFields.value = "value";
      series.dataFields.name = "name";
      series.dataFields.children = "children";
      series.dataFields.id = "name";
      series.dataFields.linkWith = "linkWith";
      series.manyBodyStrength = -50;
      series.fontSize = 10;
      series.minRadius = 15;
      series.maxRadius = 40;
      series.centerStrength = 0.5;

      series.links.template.distance = 1;
      series.links.template.strength = 1;
      series.dataFields.fixed = "true";

      // Label  properties
      series.nodes.template.label.text = "{name}";
      series.nodes.template.label.valign = "bottom";
      series.nodes.template.label.dy = 10;
      series.nodes.template.label.fill = am4core.color("#000");
      series.nodes.template.label.fontSize = 11;
      series.nodes.template.tooltipText = "{name}";

      // Lighten node colors
      // series.nodes.template.adapter.add("fill", (fill, target) => {
      //   return fill.lighten(target.dataItem.level * 0.25);
      // });

      // Image properties
      // var icon = series.nodes.template.createChild(am4plugins_bullets.PinBullet);
      // icon.image = new am4core.Image();
      // icon.tooltipText = "{name}";
      // icon.background.fill = chart.colors.getIndex(10);
      // icon.background.fillOpacity = 0.9;
      // icon.background.pointerAngle = 200;
      // icon.background.pointerBaseWidth = 40;

      // icon.image.propertyFields.href = "path";
      // icon.circle.radius = am4core.percent(100);
      // icon.circle.strokeWidth = 0;
      // icon.background.pointerLength = 0;
      // icon.background.disabled = true;

      // Link properties
      let linkTemplate = series.links.template;
      let linkHoverState = linkTemplate.states.create("hover");
      linkHoverState.properties.strokeOpacity = 10;
      linkHoverState.properties.strokeWidth = 10;

    }
  }

  Close() {
    this.dialogRef.close();
  }

  TransformData(data) {
    let children = [];
    children = data.reduce((o, cur) => {
      let occurs = o.reduce((n, item, i) => {
        return item.name === cur.relation ? i : n;
      }, -1);
      //If value founded
      if (occurs >= 0) {
        let i = { name: cur.name, value: 50 };
        o[occurs] = {
          name: cur.relation,
          children: o[occurs].children.concat(i),
          value: -50,
        };
      }
      //If value not founded
      else {
        let temp = [cur];
        let f = {};
        let children = [];
        temp.map((res) => {
          children.push({ name: res.name, value: 50 });
        });
        var obj = {
          name: cur.relation,
          value: -50,
          children: children,
        };
        o = o.concat([obj]);
      }
      return o;
    }, []);
    // let person = this.getPersonByPep(1);
    let person = {
      name: this.injectedData.cleaned_name ? this.injectedData.cleaned_name : this.injectedData.name,
      path: "https://s3-us-west-2.amazonaws.com/s.cdpn.io/t-160/icon_cloud.svg",
    };
    return [
      { name: person.name, value: 150, path: person.path, children: children },
    ];
  }

  ngOnDestroy() {
    // Clean up chart when the component is removed
    if (this.chart) {
      this.chart.dispose();
    }
  }
}
