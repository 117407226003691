import { Component, OnInit } from '@angular/core';
import { NzDrawerPlacement } from 'ng-zorro-antd/drawer';
import { NgxSpinnerService } from 'ngx-bootstrap-spinner';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import {
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
  MatSnackBar,
} from '@angular/material/snack-bar';
import { SettingService } from 'projects/case-manager/src/app/services/settingsService';
import { ConfirmDialogComponent } from '../../../../dialogs/confirmation-dialog/confirmation-dialog.component';
import { ToastrService } from 'ngx-toastr';
import { GlobalStateService } from 'projects/case-manager/src/app/services/globalStateService';
import { ErrorMessageService } from 'projects/case-manager/src/app/services/error-message.service';

@Component({
  selector: 'app-role-list',
  templateUrl: './role-list.component.html',
  styleUrls: ['./role-list.component.scss'],
})
export class RoleListComponent implements OnInit {
  visible = false;
  checked = false;
  toggleDropdown = false;
  date = null;
  placement: NzDrawerPlacement = 'left';
  indeterminate = false;
  setOfCheckedId = new Set<number>();
  subscriptions: Subscription[] = [];
  roles = [];
  isSpinning = false;
  isPermissionLoading = false;
  roleDetails = {};
  horizontalPosition: MatSnackBarHorizontalPosition = 'end';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';
  isModalVisible = false;
  viewSelectedPermissions = [];
  permissions = undefined;
  errorMessage = '';

  constructor(
    private _router: Router,
    private settingService: SettingService,
    private spinner: NgxSpinnerService,
    private dialog: MatDialog,
    private toastr: ToastrService,
    private globalStateService: GlobalStateService,
    private errorMessageService: ErrorMessageService
  ) {
    const userDetails = JSON.parse(localStorage.getItem('userDetails'));
    const payload = {
      user_id: userDetails.id.toString(),
    };

    this.subscriptions.push(
      // this._globalStateService.getPermissions().subscribe((temp) => {
      this.settingService.getUserPermissions(payload).subscribe((temp) => {
        if (temp.status == 'ok') {
          if (temp['userPermissions'] && temp['userPermissions']['permission_tags']) {
            const permissionsArray = Array.from(new Set(temp['userPermissions']['permission_tags']));
            this.permissions = this.globalStateService.MakePermissionsArray(permissionsArray);
          }
          // this.permissions = res.permissions;
        } else {
          this.permissions = undefined;
        }
      },
        (err) => {
          this.permissions = undefined;
        }
      )
    );
  }

  ngOnInit(): void {
    this.getRoles();

    // Show Error message
    this.subscriptions.push(
      this.errorMessageService.errorMessage.subscribe((message) => {
        if (message) {
          this.errorMessage = message.split(':').pop();

          setTimeout(() => {
            this.errorMessage = '';
          }, 7000);
        }
      })
    );
  }

  getRoles() {
    this.isSpinning = true;

    this.settingService.getRoles().subscribe(
      (res) => {
        if (res.status == 'ok') {
          this.isSpinning = false;

          if (res.roles && res.roles.length > 0) {
            this.roles = res.roles.filter((el) => el.status != 'D');
          }
        } else {
          this.isSpinning = false;
          this.roles = [];
        }
      },
      (err) => {
        this.isSpinning = false;
        this.roles = [];
      }
    );
  }

  getSelectedRolePermissions(roleID) {
    this.isPermissionLoading = true;

    this.settingService.getRoleDetails(roleID).subscribe(
      (res) => {
        if (res.status == 'ok') {
          this.isPermissionLoading = false;
          this.roleDetails = res.roleDetails;

          this.viewSelectedPermissions = this.roleDetails['permission_tags'];
        } else {
          this.isPermissionLoading = false;
          this.roleDetails = {};
        }
      },
      (err) => {
        this.isPermissionLoading = false;
        this.roleDetails = {};
      }
    );
  }

  open(): void {
    this.visible = !this.visible;
  }

  close(): void {
    this.visible = false;
  }

  editRole(role) {
    this._router.navigate([`pages/user-management/edit-role/${role.id}`]);
  }

  viewPermissions(role) {
    this.isModalVisible = true;
    this.getSelectedRolePermissions(role.id);
  }

  deleteRole(role) {
    this.dialog
      .open(ConfirmDialogComponent, {
        data: { message: 'Are you sure you want to delete this role?' },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.spinner.show('deleteLoading');

          const sendData = {
            statusEditRole: 'D',
          };

          this.settingService.deleteRoleService(sendData, role.id).subscribe(
            (res) => {
              if (res.status == 'ok') {
                this.spinner.hide('deleteLoading');
                this.toastr.success('Role deleted successfully!');
                this.getRoles();
              } else {
                this.spinner.hide('deleteLoading');
                // this.toastr.error('Error in deleting role!');
              }
            },
            (err) => {
              this.spinner.hide('deleteLoading');
              // this.toastr.error('Error in deleting role!');
            }
          );
        } else {
          return;
        }
      });
  }

  goToCreateRole() {
    this._router.navigate([`pages/user-management/create-new-role`]);
  }

  /// Modal JS ///
  handleOk(): void {
    this.isModalVisible = false;
    this.viewSelectedPermissions = [];
  }

  handleCancel(): void {
    this.isModalVisible = false;
    this.viewSelectedPermissions = [];
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });

    this.errorMessageService.errorMessage.next('');
  }
}
