import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterUserPipe } from './filterUser.pipe';
import { FilterPipe } from './filter.pipe';
import { AgePipe } from './age.pipe';
import { StrManipulationPipe } from './string-manipulation.pipe';
import { LinkifyPipe } from './urlify.pipe';
import { LabelTransformerPipe } from './label-transformer.pipe';
import { NestedKeyTransformer } from './nested-key-transformer.pipe';

@NgModule({
  declarations: [
    FilterUserPipe,
    FilterPipe,
    AgePipe,
    StrManipulationPipe,
    LinkifyPipe,
    LabelTransformerPipe,
    NestedKeyTransformer
  ],
  imports: [
    CommonModule
  ],
  exports: [
    FilterUserPipe,
    FilterPipe,
    AgePipe,
    StrManipulationPipe,
    LinkifyPipe,
    LabelTransformerPipe,
    NestedKeyTransformer
  ]
})
export class PipesModule { }
