import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CaseManagementComponent } from '../components/content/case-management/case-management.component';
import { CaselistComponent } from '../components/content/case-management/case-list/case-list.component';
import { CaseViewComponent } from '../components/content/case-management/case-view/case-view.component';
import { PackageDetailsComponent } from '../components/content/case-management/package-details/package-details-component';
import { CaseViewDetailComponent } from '../components/content/case-management/case-view/case-view-detail/case-view-detail.component';

const routes: Routes = [
  {
    path: '',
    component: CaseManagementComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'case-list'

      },
      {
        path: 'case-list',
        component: CaselistComponent,
        data: {title: 'Case List'}
      },
      { path: 'case-view/record-detail/:id', component: CaseViewDetailComponent, data: {title: 'Case View Detail'} },
      { path: 'case-view/:id', component: CaseViewComponent, data: {title: 'Case View'}},
      { path: 'package-details', component: PackageDetailsComponent, data: {title: 'Package Details'} }
    ],
  },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CaseManagementRoutingModule { }
