import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UserManagementComponent } from '../components/content/user-management/user-management.component';
import { AddUserComponent } from '../components/content/user-management/user-list/add-user/add-user.component';
import { AddGroupComponent } from '../components/content/user-management/group-list/add-group/add-group.component';
import { AddRoleComponent } from '../components/content/user-management/role-list/add-role/add-role.component';
import { UserManagementRoutingModule } from '../routes/user-management.routes.module';
// Angular
// import { ParticlesModule } from 'angular-particle';
import { NgxPaginationModule } from 'ngx-pagination';

import { NgxSpinnerModule } from 'ngx-bootstrap-spinner';
import { NgxPopperModule } from 'ngx-popper';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { UserDetailsDialogComponent } from '../dialogs/user-details/user-details.component';
import { AddRoleDialogComponent } from '../dialogs/add-role-dialog/add-role-dialog.component';
import { EditRoleDialogComponent } from '../dialogs/edit-role-dialog/edit-role-dialog.component';
import { AntDesignModule } from 'src/app/modules/ant-design/ant-design.module';
import { GroupListComponent } from '../components/content/user-management/group-list/group-list.component';
import { UserListComponent } from '../components/content/user-management/user-list/user-list.component';
import { RoleListComponent } from '../components/content/user-management/role-list/role-list.component';
import { HeaderModule } from 'projects/header/src/lib/header.module';
import { UmDashboardComponent } from '../components/content/user-management/um-dashboard/um-dashboard.component';
import { MaterialModule } from 'src/app/modules/material/material.module';
import { PipesModule } from '../pipes/pipes.module';
import { NzSpinModule } from 'ng-zorro-antd/spin';

@NgModule({
  declarations: [
    UserManagementComponent,
    AddUserComponent,
    AddGroupComponent,
    AddRoleComponent,
    UserDetailsDialogComponent,
    AddRoleDialogComponent,
    EditRoleDialogComponent,
    GroupListComponent,
    UserListComponent,
    RoleListComponent,
    UmDashboardComponent,
  ],

  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    UserManagementRoutingModule,
    // ParticlesModule,
    NgxPaginationModule,
    NgxSpinnerModule,
    MaterialModule,
    TooltipModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot(),
    NgxPopperModule.forRoot(),
    AntDesignModule,
    HeaderModule,
    PipesModule,
    NzSpinModule,
  ],
  exports: [NgxPopperModule],
  entryComponents: [
    UserDetailsDialogComponent,
    AddRoleDialogComponent,
    EditRoleDialogComponent,
  ],
})
export class UserManagementModule {}
