import { Component, OnInit } from '@angular/core';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { NgxSpinnerService } from 'ngx-bootstrap-spinner';
import { ToastrService } from 'ngx-toastr';
import { ErrorMessageService } from 'projects/case-manager/src/app/services/error-message.service';
import { SettingService } from 'projects/case-manager/src/app/services/settingsService';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-package-details',
  templateUrl: './package-details-component.html',
  styleUrls: ['./package-details-component.scss'],
})
export class PackageDetailsComponent implements OnInit {
  licensePackage = [];
  horizontalPosition: MatSnackBarHorizontalPosition = 'end';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';
  errorMessage = '';
  subscriptions: Subscription[] = [];

  constructor(
    private spinner: NgxSpinnerService,
    private settingService: SettingService,
    private toastr: ToastrService,
    private errorMessageService: ErrorMessageService,
  ) { }

  ngOnInit(): void {
    this.getLicensePackageDetails();

    // Show Error message
    this.subscriptions.push(
      this.errorMessageService.errorMessage.subscribe((message) => {
        if (message) {
          this.errorMessage = message.split(':').pop();

          setTimeout(() => {
            this.errorMessage = '';
          }, 7000);
        }
      })
    );
  }

  getLicensePackageDetails() {
    this.spinner.show('licenseLoading');

    this.settingService.getLicenseDetails().subscribe(
      (res) => {
        if (res.status == 'ok') {
          this.licensePackage = res.package;
          this.spinner.hide('licenseLoading');
        } else {
          this.licensePackage = [];
          this.spinner.hide('licenseLoading');
          // this.toastr.error(res.err);
        }
      },
      (err) => {
        this.licensePackage = [];
        this.spinner.hide('licenseLoading');
      }
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });

    this.errorMessageService.errorMessage.next('');
  }
}
