import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-non-lfd-admin',
  templateUrl: './non-lfd-admin.component.html',
  styleUrls: ['./non-lfd-admin.component.scss']
})
export class NonLfdAdminComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
