import { Component, OnInit } from '@angular/core';
import { NzDrawerPlacement } from 'ng-zorro-antd/drawer';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { NgxSpinnerService } from 'ngx-bootstrap-spinner';
import { MatDialog } from '@angular/material/dialog';
import {
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
  MatSnackBar,
} from '@angular/material/snack-bar';
import { SettingService } from 'projects/case-manager/src/app/services/settingsService';
import { ConfirmDialogComponent } from '../../../../dialogs/confirmation-dialog/confirmation-dialog.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { matchTerm } from 'projects/case-manager/src/app/helpers/utility';
import { GlobalStateService } from 'projects/case-manager/src/app/services/globalStateService';
import { UserType } from 'projects/case-manager/src/app/enums/user-type.enum';
import { ErrorMessageService } from 'projects/case-manager/src/app/services/error-message.service';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss'],
})
export class UserListComponent implements OnInit {
  visible = false;
  checked = false;
  toggleDropdown = false;
  date = null;
  placement: NzDrawerPlacement = 'left';
  indeterminate = false;
  setOfCheckedId = new Set<number>();
  subscriptions: Subscription[] = [];
  users = [];
  allUsers = [];
  isSpinning = false;
  isPermissionSpinning = false;
  roleDetails = {};
  horizontalPosition: MatSnackBarHorizontalPosition = 'end';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';
  loggedInUserID = undefined;
  searchTerm = '';
  isModalVisible = false;
  viewAssignedRoles = [];
  isAssignedRolesLoading = false;
  assignRoleForm: FormGroup;
  selectedUserID = undefined;
  roles = [];
  isGroupModalVisible = false;
  viewAssignedGroups = [];
  isAssignedGroupsLoading = false;
  assignGroupForm: FormGroup;
  groups = [];
  permissions = undefined;
  errorMessage = '';

  constructor(
    private _router: Router,
    private settingService: SettingService,
    private spinner: NgxSpinnerService,
    private dialog: MatDialog,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private globalStateService: GlobalStateService,
    private errorMessageService: ErrorMessageService
  ) {
    // this.globalStateService.getPermissions().subscribe(
    //   (res) => {
    //     if (res.status == 'ok') {
    //       this.permissions = res.permissions;
    //     } else {
    //       this.permissions = undefined;
    //     }
    //   },
    //   (err) => {
    //     this.permissions = undefined;
    //   }
    // );
  }

  ngOnInit(): void {
    const userDetails = JSON.parse(localStorage.getItem('userDetails'));
    const payload = {
      user_id: userDetails.id.toString(),
    };

    if (userDetails) {
      this.loggedInUserID = userDetails.id;
    }

    this.assignRoleForm = this.formBuilder.group({
      role: ['', [Validators.required]],
    });

    this.assignGroupForm = this.formBuilder.group({
      group: ['', [Validators.required]],
    });

    this.subscriptions.push(
      this.settingService.getUserPermissions(payload).subscribe((temp) => {
        if (temp.status == 'ok') {
          if (
            temp['userPermissions'] &&
            temp['userPermissions']['permission_tags']
          ) {
            const permissionsArray = Array.from(
              new Set(temp['userPermissions']['permission_tags'])
            );
            this.permissions =
              this.globalStateService.MakePermissionsArray(permissionsArray);
          }
        }
      })
    );

    // Show Error message
    this.subscriptions.push(
      this.errorMessageService.errorMessage.subscribe((message) => {
        if (message) {
          this.errorMessage = message.split(':').pop();

          setTimeout(() => {
            this.errorMessage = '';
          }, 7000);
        }
      })
    );

    this.fetchUsers();
    this.getRoles();
    this.getGroups();
  }

  /**
   * Search Term in a Table
   * @param value
   */
  filterData(value: any) {
    setTimeout(() => {
      if (!value) {
        this.users = this.allUsers;
        return;
      }

      this.users = this.allUsers.filter((obj) => {
        return (
          matchTerm(obj, 'id', value) ||
          matchTerm(obj, 'email', value) ||
          matchTerm(obj, 'username', value) ||
          matchTerm(obj, 'first_name', value) ||
          matchTerm(obj, 'last_name', value)
        );
      });
    }, 300);
  }

  fetchUsers() {
    this.isSpinning = true;

    this.settingService.getUsers().subscribe(
      (res) => {
        if (res.status == 'ok') {
          this.isSpinning = false;
          this.users = res.users;

          if (this.loggedInUserID) {
            this.users = this.users.filter(
              (user) =>
                user.id !== this.loggedInUserID &&
                user.user_type !== UserType.ADM &&
                user.user_type !== UserType.LDM
            );
          }

          // Set default data for search purpose
          this.allUsers = JSON.parse(JSON.stringify(this.users));
        } else {
          this.isSpinning = false;
          this.users = [];
        }
      },
      (err) => {
        this.isSpinning = false;
        this.users = [];
      }
    );
  }

  getRoles() {
    this.settingService.getRoles().subscribe(
      (res) => {
        if (res.status == 'ok') {
          if (res.roles && res.roles.length > 0) {
            res.roles
              .filter((el) => el.status === 'A')
              .forEach((role) => {
                this.roles.push({
                  id: role.id,
                  name: role.name,
                });
              });
          }
        } else {
          this.roles = [];
        }
      },
      (err) => {
        this.roles = [];
      }
    );
  }

  getGroups() {
    this.settingService.getGroups().subscribe(
      (res) => {
        if (res.status == 'ok') {
          if (res.groups && res.groups.length > 0) {
            res.groups
              .filter((el) => el.status === 'A')
              .forEach((group) => {
                this.groups.push({
                  id: group.id,
                  name: group.name,
                });
              });
          }
        } else {
          this.groups = [];
        }
      },
      (err) => {
        this.groups = [];
      }
    );
  }

  getSelectedRolePermissions(roleID) {
    this.isPermissionSpinning = true;

    this.settingService.getRoleDetails(roleID).subscribe(
      (res) => {
        if (res.status == 'ok') {
          this.isPermissionSpinning = false;
          this.roleDetails = res.roleDetails;
        } else {
          this.isPermissionSpinning = false;
          this.roleDetails = {};
        }
      },
      (err) => {
        this.isPermissionSpinning = false;
        this.roleDetails = {};
      }
    );
  }

  open(): void {
    this.visible = !this.visible;
  }

  close(): void {
    this.visible = false;
  }

  editRole(role) {
    this._router.navigate([`pages/user-management/edit-role/${role.id}`]);
  }

  viewPermissions(role) {
    this.getSelectedRolePermissions(role.id);
  }

  deleteUser(user) {
    this.dialog
      .open(ConfirmDialogComponent, {
        data: { message: 'Are you sure you want to delete this user?' },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          // this.deleting = true;
          // this.matchingId = user.id;
          this.spinner.show('deleteLoading');
          const obj = {
            uid: user.id,
            // gid: this.currentGroupId,
            status: 'D',
          };
          this.settingService.deleteUser(obj).subscribe(
            (res) => {
              if ((res as any).status == 'ok') {
                // const index = this.userAgainstGroup.findIndex(
                //   (temp) => temp.id == user.id
                // );
                // this.userAgainstGroup.splice(index, 1);
                this.toastr.success('User deleted successfully!');
                // this.deleting = false;
                this.spinner.hide('deleteLoading');
                this.fetchUsers();
              } else {
                // this.toastr.error('User deleted  successfully!');
                // this.deleting = false;
                this.spinner.hide('deleteLoading');
              }
            },
            (err) => {
              // this.toastr.error('Error in deleting user');
              // this.deleting = false;
              this.spinner.hide('deleteLoading');
            }
          );
        } else {
          return;
        }
      });
  }

  editUser(userID) {
    this._router.navigate([`/pages/user-management/edit-user/${userID}`]);
  }

  /// Role Modal JS ///
  handleOk(): void {
    this.isModalVisible = false;
    // this.viewAssignedRoles = [];
    this.assignRoleForm.reset();
    // this.selectedUserID = undefined;
  }

  handleCancel(isCloseModal?): void {
    if (isCloseModal) {
      this.isModalVisible = false;
    }
    // this.viewAssignedRoles = [];
    this.assignRoleForm.reset();
    // this.selectedUserID = undefined;
  }
  /// Role Modal JS ///

  getAssignedRoles(id) {
    this.viewAssignedRoles = [];
    this.isModalVisible = true;
    this.selectedUserID = id;

    if (this.selectedUserID) {
      this.getUserAssignedRoles(this.selectedUserID);
    }
  }

  getUserAssignedRoles(userID) {
    this.isAssignedRolesLoading = true;
    this.spinner.show('isAssignedRolesLoading');

    if (userID) {
      userID = userID.toString();
    }

    this.settingService.getRolesAgainstUser(userID).subscribe(
      (res) => {
        if (res.status == 'ok') {
          this.viewAssignedRoles = res.userRoles;
        } else {
          this.roleDetails = {};
        }
        this.isAssignedRolesLoading = false;
        this.spinner.hide('isAssignedRolesLoading');
      },
      (err) => {
        this.isAssignedRolesLoading = false;
        this.spinner.hide('isAssignedRolesLoading');
        this.roleDetails = {};
      }
    );
  }

  UnassignRole(role, indexToDelete) {
    this.settingService
      .roleAssignment(this.selectedUserID, role.id, 0)
      .subscribe(
        (res) => {
          if (res.status == 'ok') {
            this.toastr.success('Role unnassigned successfully!');
            this.viewAssignedRoles.splice(indexToDelete, 1);
            this.handleCancel();
          } else {
            this.handleCancel();

            return;
          }
        },
        (err) => {
          // this.toastr.error('Error in unassigning of role ' + role.name);
        }
      );
  }

  AssignRole() {
    this.spinner.show('assignRoleLoading');

    this.settingService
      .roleAssignment(
        this.selectedUserID,
        this.assignRoleForm.get('role').value,
        1
      )
      .subscribe(
        (res) => {
          if (res.status == 'ok') {
            this.toastr.success('Role assigned successfully!');
            this.spinner.hide('assignRoleLoading');
            const roleToPush = (this.roles || []).find(
              (r) => r.id === this.assignRoleForm.get('role').value
            );
            this.viewAssignedRoles.push(roleToPush);
            this.handleCancel();
          } else {
            this.spinner.hide('assignRoleLoading');
            this.handleCancel();
            return;
          }
        },
        (err) => {
          this.spinner.hide('assignRoleLoading');
          // this.toastr.error('Error in assigning role!');
          this.handleCancel();
        }
      );
  }

  /// Group Modal JS ///
  handleGroupModalOk(): void {
    this.isGroupModalVisible = false;
    // this.viewAssignedGroups = [];
    this.assignGroupForm.reset();
    // this.selectedUserID = undefined;
  }

  handleGroupModalCancel(isCloseModal?): void {
    if (isCloseModal) {
      this.isGroupModalVisible = false;
    }
    // this.viewAssignedGroups = [];
    this.assignGroupForm.reset();
    // this.selectedUserID = undefined;
  }
  /// Group Modal JS ///

  getAssignedGroups(id) {
    this.viewAssignedGroups = [];
    this.isGroupModalVisible = true;
    this.selectedUserID = id;

    if (this.selectedUserID) {
      this.getUserAssignedGroups(this.selectedUserID);
    }
  }

  getUserAssignedGroups(userID) {
    this.isAssignedGroupsLoading = true;
    this.spinner.show('isAssignedGroupsLoading');

    if (userID) {
      userID = userID.toString();
    }

    this.settingService.getGroupsAgainstUser(userID).subscribe(
      (res) => {
        if (res.status == 'ok') {
          this.viewAssignedGroups = res.userGroups;
        } else {
          this.roleDetails = {};
        }
        this.isAssignedGroupsLoading = false;
        this.spinner.hide('isAssignedGroupsLoading');
      },
      (err) => {
        this.isAssignedGroupsLoading = false;
        this.spinner.hide('isAssignedGroupsLoading');
        this.roleDetails = {};
      }
    );
  }

  UnassignGroup(group, indexToDelete) {
    this.settingService
      .groupAssignment(this.selectedUserID.toString(), group.id.toString(), '0')
      .subscribe(
        (res) => {
          if (res.status == 'ok') {
            this.toastr.success('Group unnassigned successfully!');
            this.viewAssignedGroups.splice(indexToDelete, 1);
            this.handleGroupModalCancel();
          } else {
            this.handleGroupModalCancel();

            return;
          }
        },
        (err) => {
          // this.toastr.error('Error in unassigning of group ' + group.name);
          this.handleGroupModalCancel();
        }
      );
  }

  AssignGroup() {
    this.spinner.show('assignGroupLoading');

    this.settingService
      .groupAssignment(
        this.selectedUserID.toString(),
        this.assignGroupForm.get('group').value.toString(),
        '1'
      )
      .subscribe(
        (res) => {
          if (res.status == 'ok') {
            this.toastr.success('Group assigned successfully!');
            this.spinner.hide('assignGroupLoading');
            const groupToPush = (this.groups || []).find(
              (r) => r.id === this.assignGroupForm.get('group').value
            );
            this.viewAssignedGroups.push(groupToPush);
          } else {
            this.spinner.hide('assignGroupLoading');
            this.handleGroupModalCancel();
            return;
          }
        },
        (err) => {
          this.spinner.hide('assignGroupLoading');
          // this.toastr.error('Error in assigning group!');
          this.handleGroupModalCancel();
        }
      );
  }

  goToAddUserScreen() {
    this._router.navigate([`pages/user-management/create-new-user`]);
  }

  getUserStatus(status) {
    switch (status) {
      case 'A':
        return 'Active';
      case 'B':
        return 'In-Active';
      default:
        return 'Active';
    }
  }

  trackByFn(i) {
    return i;
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });

    this.errorMessageService.errorMessage.next('');
  }
}
