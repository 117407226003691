import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'nestedKeyTransformer'
})
export class NestedKeyTransformer implements PipeTransform {

    transform(obj: any, ...args: any[]): string {
        let dbKeys = args[0];
        let choice = null;
        let choices = null;
        let keyToFind = null;
        if (dbKeys && dbKeys.includes('|')) {
            // console.log('dbKeys |:', dbKeys);
            // console.log('===============================');
            choices = dbKeys.split('|');
            // console.log('choices |:', choices);
            choices.forEach(ckey => {

                if (ckey && ckey.includes('.')) {
                    ckey = ckey.split('.');
                    keyToFind = ckey[ckey.length - 1];
                    // console.log('keyToFind :', keyToFind);

                    [ckey[0]].forEach(key => {
                        // console.log('key :', key);
                        // console.log('obj[key] :', obj[key]);

                        if (obj[key]) {
                            let result = this._getObject(obj[key], keyToFind);
                            if (result) {
                                // console.log('result :', result);
                                choice = keyToFind;
                                obj = result;
                            }
                        }
                    });
                }
            });
        }
        else if (dbKeys && dbKeys.includes('.')) {
            // console.log('dbKeys .:', dbKeys);
            // console.log('===============================');
            dbKeys = dbKeys.split('.');
            keyToFind = dbKeys[dbKeys.length - 1];
            // console.log('keyToFind :', keyToFind);

            [dbKeys[0]].forEach(key => {
                // console.log('key :', key);
                // console.log('obj[key] :', obj[key]);

                if (obj[key]) {
                    let result = this._getObject(obj[key], keyToFind);
                    if (result) {
                        // console.log('result :', result);

                        choice = keyToFind;
                        obj = result;
                    }
                }
            });
        }
        else {
            choice = dbKeys;
        }
        if (choices && choices.length > 0) {
            return obj[choices[0]] || obj[choices[1]];
        }
        return obj[choice];
    }

    _getObject(theObject, keyToFind) {
        var result = null;
        if (theObject instanceof Array) {
            for (var i = 0; i < theObject.length; i++) {
                result = this._getObject(theObject[i], keyToFind);
            }
        }
        else {
            for (var prop in theObject) {
                if (prop == keyToFind) {
                    return theObject;
                }
                if (theObject[prop] instanceof Object || theObject[prop] instanceof Array)
                    result = this._getObject(theObject[prop], keyToFind);
            }
        }
        return result;
    }
}
