import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { NgxSpinnerService } from 'ngx-bootstrap-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { ErrorMessageService } from '../../services/error-message.service';
import { SettingService } from '../../services/settingsService';

@Component({
  selector: 'app-edit-role-dialog',
  templateUrl: './edit-role-dialog.component.html',
  styleUrls: ['./edit-role-dialog.component.scss'],
})
export class EditRoleDialogComponent implements OnInit {
  editRoleForm: FormGroup;
  roleData = undefined;
  roleToEdit = undefined;
  horizontalPosition: MatSnackBarHorizontalPosition = 'end';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';
  subscriptions: Subscription[] = [];
  statuses = [];
  roles = [];
  permTags = [];
  rolesForEditCase = [];
  rolesOfUsers = [];
  errorMessage = '';

  constructor(
    private formBuilder: FormBuilder,
    public settingService: SettingService,
    private spinner: NgxSpinnerService,
    private dialogRef: MatDialogRef<EditRoleDialogComponent>,
    private toastr: ToastrService,
    private errorMessageService: ErrorMessageService
  ) {}

  ngOnInit(): void {
    this.editRoleForm = this.formBuilder.group({
      statusEditRole: ['', []],
      permTagsEditRole: [[], []],
    });

    this.subscriptions.push(
      this.settingService.getChoices().subscribe(
        (res) => {
          if (res.status == 'ok') {
            res.choices.alert_choices.STATUS_CHOICES.map((temp) => {
              this.statuses.push({ value: temp[0], viewValue: temp[1] });
            });
          } else {
            this.statuses = [];
          }
        },
        (err) => {}
      )
    );

    this.subscriptions.push(
      this.settingService.getRoles().subscribe(
        (res) => {
          if (res.status == 'ok') {
            this.roles = res.roles;
            this.roles = this.roles.filter((el) => {
              return el.status != 'D';
            });

            this.rolesForEditCase = Array.from(this.roles);
            const index = this.rolesForEditCase.findIndex(
              (pi) => pi.name == 'Case Escalation'
            );
            if (index > -1) {
              this.rolesForEditCase.splice(index, 1);
            }
            const index1 = this.rolesForEditCase.findIndex(
              (pi) => pi.name == 'Master Role'
            );
            if (index1 > -1) {
              this.rolesForEditCase.splice(index1, 1);
            }

            this.subscriptions.push(
              this.settingService
                .getRolesAgainstUser(localStorage.getItem('userID'))
                .subscribe(
                  (rl) => {
                    if ((rl as any).status == 'ok') {
                      rl.userRoles.map((temp) => {
                        this.rolesOfUsers.push(temp.name);
                      });
                      this.rolesOfUsers = this.rolesOfUsers.filter(
                        (item, pos) => {
                          return this.rolesOfUsers.indexOf(item) == pos;
                        }
                      );

                      // Note: Not remove the commented code
                      // this.roles = this.roles.filter((item) => {
                      //   return (
                      //     this.rolesOfUsers.find((item2) => {
                      //       return item.name == item2;
                      //     }) == undefined
                      //   );
                      // });
                      this.roles = this.roles.filter((el) => {
                        return el.status != 'D';
                      });
                    } else {
                      this.roles = this.roles;
                    }
                  },
                  (err) => {
                    this.roles = this.roles;
                    this.spinner.hide('usersLoading');
                  }
                )
            );
          } else {
            this.spinner.hide('usersLoading');

            this.roles = [];
          }
        },
        (err) => {
          this.roles = [];
          this.spinner.hide('usersLoading');
        }
      )
    );

    this.subscriptions.push(
      this.settingService.getPermissionsTag().subscribe((res) => {
        if (res.status == 'ok') {
          this.spinner.hide('loadingPermTags');

          const temp = res.permissions;
          const index = temp.findIndex((tqp) => tqp == 'Escalation');
          temp.splice(index, 1);
          temp.map((rist) => {
            const arrToObj = { name: rist.tag, checked: false };
            this.permTags.push(arrToObj);
          });
        } else {
          this.spinner.hide('loadingPermTags');
          this.permTags = [];
        }
      })
    );

    // Show Error message
    this.subscriptions.push(
      this.errorMessageService.errorMessage.subscribe((message) => {
        if (message) {
          this.errorMessage = message.split(':').pop();

          setTimeout(() => {
            this.errorMessage = '';
          }, 7000);
        }
      })
    );
  }

  changeRole() {
    let roleDetailsData;
    const roles = this.roles.map((res) => {
      if (res.id == this.roleToEdit) {
        this.roleData = res;
        this.editRoleForm.get('statusEditRole').setValue(this.roleData.status);
        this.spinner.show('loadingPermTags');
        this.settingService.getRoleDetails(this.roleData.id).subscribe(
          (result) => {
            if (result.status == 'ok') {
              this.spinner.hide('loadingPermTags');
              roleDetailsData = result.roleDetails;

              if (roleDetailsData) {
                this.editRoleForm
                  .get('permTagsEditRole')
                  .setValue(roleDetailsData.permission_tags);
              }

              this.permTags.map((ttr) => {
                this.editRoleForm.get('permTagsEditRole').value.map((vlc) => {
                  if (ttr.name == vlc) {
                    ttr.checked = true;
                  }
                });
              });
            } else {
              this.spinner.hide('loadingPermTags');
              roleDetailsData = undefined;
            }
          },
          (err) => {
            this.spinner.hide('loadingPermTags');
            roleDetailsData = undefined;
          }
        );
      }
    });
  }

  onChange(value, status) {
    if (
      this.editRoleForm.get('permTagsEditRole').value.indexOf(value) === -1 &&
      status
    ) {
      this.editRoleForm.get('permTagsEditRole').value.push(value);
      this.editRoleForm
        .get('permTagsEditRole')
        .setValue([
          ...new Set(this.editRoleForm.get('permTagsEditRole').value),
        ]);
    } else if (!status) {
      const index = this.editRoleForm
        .get('permTagsEditRole')
        .value.findIndex((tp) => tp == value);
      this.editRoleForm.get('permTagsEditRole').value.splice(index, 1);
    } else {
      return;
    }
  }

  ChangeStatusInEditRole(role) {
    this.editRoleForm.get('statusEditRole').setValue(role);
  }

  UpdateRole() {
    if (this.editRoleForm.valid) {
      this.spinner.show('editRoleLoading');
      if (!this.editRoleForm.get('statusEditRole').value) {
        this.editRoleForm.get('statusEditRole').setValue(this.roleData.status);
      }

      this.subscriptions.push(
        this.settingService
          .updateRole(this.editRoleForm.value, this.roleToEdit)
          .subscribe(
            (yz) => {
              let roleDetailsData;
              if (yz.status == 'ok') {
                this.roles = this.roles.filter((r) => r.id !== this.roleToEdit);
                this.roleToEdit = undefined;
                this.editRoleForm.reset();
                this.roles = this.roles.filter((el) => {
                  return el.status != 'D';
                });
                this.spinner.hide('editRoleLoading');
                this.toastr.success(
                  'Role updated successfully! Changes will be reflected upon refresh of page.'
                );
                this.settingService.getRoleDetails(this.roleData.id).subscribe(
                  (result) => {
                    if (result.status == 'ok') {
                      this.spinner.hide('loadingPermTags');
                      roleDetailsData = result.roleDetails;

                      if (roleDetailsData) {
                        this.editRoleForm
                          .get('permTagsEditRole')
                          .setValue(roleDetailsData.permission_tags);

                        this.editRoleForm
                          .get('statusEditRole')
                          .setValue(roleDetailsData.status);
                      } else {
                        this.editRoleForm.get('permTagsEditRole').setValue([]);
                        this.editRoleForm.get('statusEditRole').setValue('B');
                      }

                      if (
                        this.editRoleForm.get('permTagsEditRole').value &&
                        this.editRoleForm.get('permTagsEditRole').value.length >
                          0
                      ) {
                        this.permTags.map((ttr) => {
                          this.editRoleForm
                            .get('permTagsEditRole')
                            .value.map((vlc) => {
                              if (ttr.name == vlc) {
                                ttr.checked = true;
                              }
                            });
                        });
                      } else {
                        this.permTags.forEach((ttr) => (ttr.checked = false));
                      }
                      this.dialogRef.close();
                    } else {
                      this.spinner.hide('loadingPermTags');
                      roleDetailsData = undefined;
                    }
                  },
                  (err) => {
                    this.spinner.hide('loadingPermTags');
                    roleDetailsData = undefined;
                  }
                );
              } else {
                this.spinner.hide('editRoleLoading');
                // this.toastr.error('Error in updating role, Try again! ' + yz.error);
              }
            },
            (err) => {
              this.spinner.hide('editRoleLoading');
              // this.toastr.error('Error in updating role, Try again!');
            }
          )
      );
    } else {
      return;
    }
  }

  CloseER() {
    this.roleToEdit = undefined;
    this.editRoleForm.reset();
    this.dialogRef.close();
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });

    this.errorMessageService.errorMessage.next('');
  }
}
