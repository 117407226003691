import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { SettingService } from 'projects/case-manager/src/app/services/settingsService';
import { NgxSpinnerService } from 'ngx-bootstrap-spinner';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../../../../dialogs/confirmation-dialog/confirmation-dialog.component';
import {
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
  MatSnackBar,
} from '@angular/material/snack-bar';
import { ToastrService } from 'ngx-toastr';
import { GlobalStateService } from 'projects/case-manager/src/app/services/globalStateService';
import { ErrorMessageService } from 'projects/case-manager/src/app/services/error-message.service';

@Component({
  selector: 'app-group-list',
  templateUrl: './group-list.component.html',
  styleUrls: ['./group-list.component.scss'],
})
export class GroupListComponent implements OnInit {
  visible = false;
  toggleDropdown = false;
  date = null;
  checked = false;
  indeterminate = false;
  groups = [];
  setOfCheckedId = new Set<number>();
  subscriptions: Subscription[] = [];
  managers = [];
  isSpinning = false;
  horizontalPosition: MatSnackBarHorizontalPosition = 'end';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';
  permissions = undefined;
  errorMessage = '';

  constructor(
    private _router: Router,
    private settingService: SettingService,
    private spinner: NgxSpinnerService,
    private dialog: MatDialog,
    private toastr: ToastrService,
    private globalStateService: GlobalStateService,
    private errorMessageService: ErrorMessageService,
  ) {
    const userDetails = JSON.parse(localStorage.getItem('userDetails'));
    const payload = {
      user_id: userDetails.id.toString(),
    };

    this.subscriptions.push(
      // this._globalStateService.getPermissions().subscribe((temp) => {
      this.settingService.getUserPermissions(payload).subscribe((temp) => {
        if (temp.status == 'ok') {
          // this.permissions = toy.permissions;
          if (temp['userPermissions'] && temp['userPermissions']['permission_tags']) {
            const permissionsArray = Array.from(new Set(temp['userPermissions']['permission_tags']));
            this.permissions = this.globalStateService.MakePermissionsArray(permissionsArray);
          }
          if (this.permissions && Object.keys(this.permissions).length) {
            if (this.permissions && Object.keys(this.permissions).length) {
              // this.loading = true;
              // this.initialLoading = false;
              if (this.permissions && this.permissions.canManageUsers) {
                this.spinner.hide('initialLoading');
                this.spinner.show('groupLoading');

                this.subscriptions.push(
                  this.settingService.getGroups().subscribe(
                    (res) => {
                      if (res.status == 'ok') {
                        if (res.groups && res.groups.length) {
                          this.groups = res.groups;

                          this.groups = this.groups.filter((el) => {
                            return el.status != 'D';
                          });
                          // this.groups.map((res) => {
                          //   if (res.parent_id == null) {
                          //     this.rootGroups.push(res);
                          //   }
                          // });

                          this.settingService.group_id.next(0);
                          // this.groups = this.getNestedChildren(this.groups, null);
                          // this.loading = false;

                          this.spinner.hide('groupLoading');
                        } else {
                          // this.loading = false;
                          this.spinner.hide('groupLoading');
                          this.groups = [];
                        }
                        // if(this.groups.length>0){
                        // this.groups.unshift({
                        //   id: 0,
                        //   name: 'All Groups',
                        //   parent_id: null,
                        //   status: 'A',
                        // });
                        // }
                      }
                    },
                    (err) => {
                      // this.loading = false;
                      this.spinner.hide('groupLoading');
                      this.groups = [];
                    }
                  )
                );
              } else {
                // this.modules = JSON.parse(localStorage.getItem('modules'));
                // this.layoutSvc.selectedRole.next(this.modules[0]);
                // if (this.modules[0] == 'Case Management') {
                //   sessionStorage.setItem('selectedModule', 'Case Management');
                //   this._router.navigate(['pages/case-management']);
                //   this.layoutSvc.leftPanel.next(false);
                // } else {
                //   this.layoutSvc.leftPanel.next(true);
                //   sessionStorage.setItem('selectedModule', 'Screening');
                //   this._router.navigate(['pages/dashboard']);
                //   this.layoutSvc.namesearchPanelStatus.next(true);
                // }
              }
            } else {
              // this.loading = false;
              // this.initialLoading = false;
              this.spinner.hide('initialLoading');
              this.permissions = undefined;
            }
          }
        } else {
          this.spinner.hide('initialLoading');
          this.permissions = undefined;
          // this.loading = false;
          // this.initialLoading = false;
        }
      },
        (err) => {
          // this.loading = false;
          // this.initialLoading = false;
          this.spinner.hide('initialLoading');
          this.permissions = undefined;
        }
      )
    );
  }

  ngOnInit(): void {
    this.fetchGroups();
    this.fetchGroupManagers();
    // Show Error message
    this.subscriptions.push(
      this.errorMessageService.errorMessage.subscribe((message) => {
        if (message) {
          this.errorMessage = message.split(':').pop();

          setTimeout(() => {
            this.errorMessage = '';
          }, 7000);
        }
      })
    );
  }

  fetchGroups() {
    this.isSpinning = true;

    this.settingService.getGroups().subscribe(
      (res) => {
        if (res.status == 'ok') {
          this.isSpinning = false;
          this.groups = res.groups;
        } else {
          this.isSpinning = false;
          this.groups = [];
        }
      },
      (err) => {
        this.isSpinning = false;
        this.groups = [];
      }
    );
  }

  fetchGroupManagers() {
    this.settingService.getUsers().subscribe((res) => {
      if (res.status == 'ok') {
        if (res.users && res.users.length) {
          res.users = res.users.filter((el) => el.status != 'D');

          res.users.map((val) => {
            this.managers.push({
              value: val.id,
              viewValue: val.username,
            });
          });
        } else {
          this.managers = [];
        }
      } else {
        this.managers = [];
      }
    });
  }

  TransformGroupManager(id) {
    let name = '';

    this.managers.map((temp) => {
      if (temp.value == id) {
        name = temp.viewValue;
      }
    });

    return name;
  }

  deleteGroup(group) {
    this.dialog
      .open(ConfirmDialogComponent, {
        data: { message: 'Are you sure you want to delete this group?' },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.spinner.show('deleteLoading');

          const obj = {
            id: group.id,
            status: 'D',
          };
          this.settingService.updateGroup(obj, group.id).subscribe(
            (res) => {
              if ((res as any).status == 'ok') {
                this.toastr.success('Group deleted  successfully!');
                this.spinner.hide('deleteLoading');
                this.fetchGroups();
              } else {
                // this.toastr.error('Group deleted  successfully!');
                this.spinner.hide('deleteLoading');
              }
            },
            (err) => {
              // this.toastr.error('Error in deleting Group');
              this.spinner.hide('deleteLoading');
            }
          );
        } else {
          return;
        }
      });
  }

  open(): void {
    this.visible = !this.visible;
  }

  close(): void {
    this.visible = false;
  }

  editGroup(groupID) {
    this._router.navigate([
      `pages/user-management/edit-group/${groupID}`,
    ]);
  }

  getGroupStatus(status) {
    switch (status) {
      case 'A':
        return 'Active';
      case 'B':
        return 'In-Active';
      default:
        return 'Active';
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });

    this.errorMessageService.errorMessage.next('');
  }
}
