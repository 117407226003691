import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { NzDrawerPlacement } from 'ng-zorro-antd/drawer';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import {
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-bootstrap-spinner';
import { Subscription } from 'rxjs';
import { SettingService } from 'projects/case-manager/src/app/services/settingsService';
import { GlobalStateService } from 'projects/case-manager/src/app/services/globalStateService';
import { ConfirmDialogComponent } from 'projects/case-manager/src/app/dialogs/confirmation-dialog/confirmation-dialog.component';
import { UserDetailsDialogComponent } from 'projects/case-manager/src/app/dialogs/user-details/user-details.component';
import { AddRoleDialogComponent } from 'projects/case-manager/src/app/dialogs/add-role-dialog/add-role-dialog.component';
import { EditRoleDialogComponent } from 'projects/case-manager/src/app/dialogs/edit-role-dialog/edit-role-dialog.component';
import { ToastrService } from 'ngx-toastr';
import { ErrorMessageService } from 'projects/case-manager/src/app/services/error-message.service';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AddUserComponent implements OnInit {
  editRoleForm: FormGroup;
  roles = [];
  searchTerm = '';
  signedUser;
  roleData = undefined;
  whiteSpaceRegex = /^\S*$/;
  emailRegex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  passwordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;
  public usersDropdown = {
    singleSelection: false,
    itemsShowLimit: 2,
    allowSearchFilter: true,
    enableCheckAll: false,
    idField: 'id',
    textField: 'username',
    clearSearchFilter: true,
    closeDropDownOnSelection: false,
  };
  public createUserDropdown = {
    singleSelection: false,
    itemsShowLimit: 2,
    allowSearchFilter: true,
    enableCheckAll: false,
    idField: 'id',
    textField: 'name',
    clearSearchFilter: true,
    closeDropDownOnSelection: false,
  };
  selectedUsers = [];
  addUserForm = new FormGroup({
    first_name: new FormControl(''),
    last_name: new FormControl(''),
    username: new FormControl(''),
    email: new FormControl('', [
      Validators.required,
      Validators.pattern(this.emailRegex),
    ]),
    organization: new FormControl(''),
    status: new FormControl(''),
    role: new FormControl([], [Validators.required]),
    group: new FormControl([], [Validators.required]),
    selectedUserType: new FormControl('RGU'),
    selectedUserStatus: new FormControl('A'),
  });
  selectedRole = new FormControl([]);
  noPermission = false;
  checkedList = [];
  matchingId = undefined;
  alreadyExistsError = false;
  userId = undefined;
  @ViewChild('selectAll') selectAll: ElementRef;
  organizations = [];
  assignGroup = false;
  groups = [];
  subscriptions: Subscription[] = [];
  users = [];
  rolesOfUsers = [];
  userAgainstGroup = [];
  errorMessage = '';
  duplicateErrorMsg = [];
  tempObj = {};
  rolesForEditCase = [];
  pageControl = 1;
  pageNumber = 1;
  itemsPerPage = 5;
  itemsAgainstPage = 2;
  currentGroupId: any;
  deleting = false;
  horizontalPosition: MatSnackBarHorizontalPosition = 'end';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';
  hide = true;
  hideCP = true;
  loading = true;
  selectedUser = undefined;
  editCase = false;
  userData = undefined;
  id = undefined;
  sortedData: any;
  groupToAssign: any;
  roleToAssign: any;
  permissions = undefined;
  statuses = [];
  focused = false;
  assignRoleForm: FormGroup;
  editUserForm: FormGroup;
  roleToEdit = undefined;
  permTags = [];
  usersToAssign = [];
  visible = false;
  toggleDropdown = false;
  date = null;
  radioValue = 'A';
  placement: NzDrawerPlacement = 'left';
  URL = undefined;

  constructor(
    private formBuilder: FormBuilder,
    public settingService: SettingService,
    public dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private _globalStateService: GlobalStateService,
    private _router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private errorMessageService: ErrorMessageService,
  ) {
    this.URL = this._router.url;

    this.assignRoleForm = this.formBuilder.group({
      role: ['', [Validators.required]],
    });

    this.editUserForm = this.formBuilder.group({
      first_name: ['', []],
      last_name: ['', []],
    });

    this.spinner.show('usersLoading');
    const userDetails = JSON.parse(localStorage.getItem('userDetails'));
    const payload = {
      user_id: userDetails.id.toString(),
    };

    this.subscriptions.push(
      // this._globalStateService.getPermissions().subscribe((temp) => {
      this.settingService.getUserPermissions(payload).subscribe((temp) => {
        if (temp.status == 'ok') {
          // this.permissions = temp.permissions;
          if (
            temp['userPermissions'] &&
            temp['userPermissions']['permission_tags']
          ) {
            const permissionsArray = Array.from(
              new Set(temp['userPermissions']['permission_tags'])
            );
            this.permissions =
              this._globalStateService.MakePermissionsArray(permissionsArray);
          }
          if (this.permissions && Object.keys(this.permissions).length) {
            this.subscriptions.push(
              this.settingService.group_id.subscribe(
                (tp) => {
                  if (tp) {
                    this.spinner.show('usersLoading');
                    this.currentGroupId = tp;
                    this.loading = true;

                    this.subscriptions.push(
                      this.settingService.getUsers().subscribe(
                        (res) => {
                          if (res.status == 'ok') {
                            this.users = res.users;
                            this.usersToAssign = res.users.filter((el) => {
                              return el.user_type != 'ADM' && el.status != 'D';
                            });
                            if (
                              this.users &&
                              this.users.length &&
                              this.permissions &&
                              this.permissions.canAssignGroup
                            ) {
                              this.subscriptions.push(
                                this.settingService
                                  .listUsersAgainstGroup(tp)
                                  .subscribe(
                                    (listUsers) => {
                                      const response: any = listUsers;

                                      if (response.status == 'ok') {
                                        if (
                                          response.users &&
                                          response.users.length
                                        ) {
                                          this.userAgainstGroup =
                                            response.users;
                                          this.userAgainstGroup.map((ur) => {
                                            this.settingService
                                              .getRolesAgainstUser(
                                                ur.id.toString()
                                              )
                                              .subscribe(
                                                (rl) => {
                                                  if (rl.status == 'ok') {
                                                    ur.roles =
                                                      this.assignRoletoUser(rl);

                                                    this.userAgainstGroup =
                                                      Array.from(
                                                        new Set(
                                                          this.userAgainstGroup.map(
                                                            (a) => a.id
                                                          )
                                                        )
                                                      ).map((id) => {
                                                        return this.userAgainstGroup.find(
                                                          (a) => a.id === id
                                                        );
                                                      });

                                                    this.userAgainstGroup =
                                                      this.userAgainstGroup.filter(
                                                        (el) => {
                                                          return (
                                                            el.status != 'D'
                                                          );
                                                        }
                                                      );
                                                    this.users.map((tr) => {
                                                      const ind =
                                                        this.userAgainstGroup
                                                          .map(function (e) {
                                                            return e.id;
                                                          })
                                                          .indexOf(tr.id);
                                                      if (ind != -1) {
                                                        this.userAgainstGroup[
                                                          ind
                                                        ].email = '';
                                                        this.userAgainstGroup[
                                                          ind
                                                        ].email = tr.email;

                                                        this.userAgainstGroup[
                                                          ind
                                                        ].first_name = '';
                                                        this.userAgainstGroup[
                                                          ind
                                                        ].first_name =
                                                          tr.first_name;

                                                        this.userAgainstGroup[
                                                          ind
                                                        ].last_name = '';
                                                        this.userAgainstGroup[
                                                          ind
                                                        ].last_name =
                                                          tr.last_name;
                                                      }
                                                    });
                                                    this.spinner.hide(
                                                      'usersLoading'
                                                    );
                                                  } else {
                                                    this.spinner.hide(
                                                      'usersLoading'
                                                    );
                                                    this.userAgainstGroup = [];
                                                    this.loading = false;
                                                  }
                                                },
                                                (err) => {
                                                  this.spinner.hide(
                                                    'usersLoading'
                                                  );
                                                  this.userAgainstGroup = [];
                                                  this.loading = false;
                                                }
                                              );
                                            // this.userAgainstGroup.reverse();
                                            this.loading = false;
                                            this.spinner.hide('usersLoading');
                                          });
                                        } else {
                                          this.spinner.hide('usersLoading');
                                          this.userAgainstGroup = [];
                                          this.loading = false;
                                        }
                                      } else {
                                        this.spinner.hide('usersLoading');
                                        this.userAgainstGroup = [];
                                        this.loading = false;
                                      }
                                    },
                                    (err) => {
                                      this.spinner.hide('usersLoading');
                                      this.userAgainstGroup = [];
                                      this.loading = false;
                                    }
                                  )
                              );
                            } else {
                              this.noPermission = true;
                              this.spinner.hide('usersLoading');
                              this.userAgainstGroup = [];
                            }
                          } else {
                            this.spinner.hide('usersLoading');
                            this.userAgainstGroup = [];
                          }
                        },
                        (err) => {
                          this.spinner.hide('usersLoading');
                          this.userAgainstGroup = [];
                        }
                      )
                    );
                  }
                },
                (err) => {
                  this.spinner.hide('usersLoading');
                  this.userAgainstGroup = [];
                }
              )
            );
          } else {
            this.spinner.show('usersLoading');
            this.permissions = undefined;
          }
        } else {
          this.spinner.show('usersLoading');
          this.permissions = undefined;
        }
      })
    );

    this.subscriptions.push(
      this.settingService.getAllOrganizations().subscribe((res) => {
        if (res.status == 'ok') {
          res.organizations.map((val) => {
            this.organizations.push({
              value: val.id,
              viewValue: val.name
                .toLowerCase()
                .split(' ')
                .map((word) => {
                  return word.charAt(0).toUpperCase() + word.slice(1);
                })
                .join(' '),
            });
          });
        } else {
          this.organizations = [];
        }
      })
    );

    this.subscriptions.push(
      this.settingService.getRoles().subscribe(
        (res) => {
          if (res.status == 'ok') {
            this.roles = res.roles;
            this.roles = this.roles.filter((el) => {
              return el.status != 'B';
            });

            this.rolesForEditCase = Array.from(this.roles);
            const index = this.rolesForEditCase.findIndex(
              (pi) => pi.name == 'Case Escalation'
            );
            if (index > -1) {
              this.rolesForEditCase.splice(index, 1);
            }
            const index1 = this.rolesForEditCase.findIndex(
              (pi) => pi.name == 'Master Role'
            );
            if (index1 > -1) {
              this.rolesForEditCase.splice(index1, 1);
            }

            this.subscriptions.push(
              this.settingService
                .getRolesAgainstUser(localStorage.getItem('userID'))
                .subscribe(
                  (rl) => {
                    if ((rl as any).status == 'ok') {
                      rl.userRoles.map((temp) => {
                        this.rolesOfUsers.push(temp.name);
                      });
                      this.rolesOfUsers = this.rolesOfUsers.filter(
                        (item, pos) => {
                          return this.rolesOfUsers.indexOf(item) == pos;
                        }
                      );

                      // Note: Not remove the commented code
                      // this.roles = this.roles.filter((item) => {
                      //   return (
                      //     this.rolesOfUsers.find((item2) => {
                      //       return item.name == item2;
                      //     }) == undefined
                      //   );
                      // });
                      this.roles = this.roles.filter((el) => {
                        return el.status != 'D';
                      });
                    } else {
                      this.roles = this.roles;
                    }
                  },
                  (err) => {
                    this.roles = this.roles;
                    this.spinner.hide('usersLoading');
                  }
                )
            );
          } else {
            this.spinner.hide('usersLoading');

            this.roles = [];
          }
        },
        (err) => {
          this.roles = [];
          this.spinner.hide('usersLoading');
        }
      )
    );

    this.subscriptions.push(
      this.settingService.getPermissionsTag().subscribe((res) => {
        if (res.status == 'ok') {
          this.spinner.hide('loadingPermTags');
          this.spinner.hide('addUserLoading');

          const temp = res.permissions;
          const index = temp.findIndex((tqp) => tqp == 'Escalation');
          temp.splice(index, 1);
          temp.map((rist) => {
            const arrToObj = { name: rist.tag, checked: false };
            this.permTags.push(arrToObj);
          });
        } else {
          this.spinner.hide('loadingPermTags');
          this.spinner.hide('addUserLoading');
          this.permTags = [];
        }
      })
    );

    this.subscriptions.push(
      this.settingService.getGroups().subscribe(
        (res) => {
          if (res.status == 'ok') {
            this.groups = res.groups;
            this.groups = this.groups.filter(item => item.status !== 'B');

            // set selected group from route param
            const groupId = +this.route.snapshot.params.groupId;
            if (groupId) {
              this.addUserForm.get('group').setValue([groupId]);
            }
          } else {
            this.groups = [];
          }
        },
        (err) => {
          this.groups = [];
          this.spinner.hide('usersLoading');
        }
      )
    );

    this.subscriptions.push(
      this.settingService.getChoices().subscribe(
        (res) => {
          if (res.status == 'ok') {
            res.choices.alert_choices.STATUS_CHOICES.map((temp) => {
              this.statuses.push({ value: temp[0], viewValue: temp[1] });
            });
          } else {
            this.statuses = [];
          }
        },
        (err) => { }
      )
    );
  }

  ngOnInit(): void {
    this.spinner.show('addUserLoading');
    this.signedUser = JSON.parse(localStorage.getItem('userDetails'));

    const userID = +this.route.snapshot.params.id;

    if (userID) {
      this.getUserDetails(userID);
    }

    this.subscriptions.push(
      this.errorMessageService.errorMessage.subscribe((message) => {
        if (message) {
          message = message.split(':').pop();

          if (message.includes('already exists')) {
            this.errorMessage = 'User with this email is already exists';
          }
          else if (message.includes('email')) {
            this.errorMessage = `Email ${message.split('=').pop()}`;
          }
          else if (message.includes('IntegrityError')) {
            this.errorMessage = 'Something went wrong while completing task, please try again later!';
          }

          setTimeout(() => {
            this.errorMessage = '';
          }, 7000);
        }
      })
    );
  }

  getUserDetails(userID) {
    const payload = {
      id: userID,
    };

    this.settingService.getUserByID(payload).subscribe((res) => {
      if (res.status == 'ok') {
        const { user_details } = res.userDetails;
        const userData = {};

        if (user_details.length > 0) {
          userData['first_name'] = user_details[0].first_name;
          userData['last_name'] = user_details[0].last_name;
          userData['username'] = user_details[0].username;
          userData['email'] = user_details[0].email;
          // userData['selectedUserType'] = user_details[0].user_type;
          userData['selectedUserType'] = 'RGU';
          userData['selectedUserStatus'] = user_details[0].status;
        }

        this.addUserForm.patchValue(userData);
      }
    });
  }

  assignRoletoUser(role) {
    let arr = [];
    arr = role.userRoles.map((v) => ({ id: v.id, name: v.name }));
    arr = Array.from(new Set(arr.map((a) => a.id))).map((id) => {
      return arr.find((a) => a.id === id);
    });
    this.spinner.hide('usersLoading');

    return arr;
  }

  changeRole() {
    let roleDetailsData;
    const roles = this.roles.map((res) => {
      if (res.id == this.roleToEdit) {
        this.roleData = res;
        this.editRoleForm.get('statusEditRole').setValue(this.roleData.status);
        this.spinner.show('loadingPermTags');
        this.settingService.getRoleDetails(this.roleData.id).subscribe(
          (result) => {
            if (result.status == 'ok') {
              this.spinner.hide('loadingPermTags');
              roleDetailsData = result.roleDetails;

              if (roleDetailsData) {
                this.editRoleForm
                  .get('permTagsEditRole')
                  .setValue(roleDetailsData.permission_tags);
              }

              this.permTags.map((ttr) => {
                this.editRoleForm.get('permTagsEditRole').value.map((vlc) => {
                  if (ttr.name == vlc) {
                    ttr.checked = true;
                  }
                });
              });
            } else {
              this.spinner.hide('loadingPermTags');
              roleDetailsData = undefined;
            }
          },
          (err) => {
            this.spinner.hide('loadingPermTags');
            roleDetailsData = undefined;
          }
        );
      }
    });
  }

  onChange(value, status) {
    if (
      this.editRoleForm.get('permTagsEditRole').value.indexOf(value) === -1 &&
      status
    ) {
      this.editRoleForm.get('permTagsEditRole').value.push(value);
      this.editRoleForm
        .get('permTagsEditRole')
        .setValue([
          ...new Set(this.editRoleForm.get('permTagsEditRole').value),
        ]);
    } else if (!status) {
      const index = this.editRoleForm
        .get('permTagsEditRole')
        .value.findIndex((tp) => tp == value);
      this.editRoleForm.get('permTagsEditRole').value.splice(index, 1);
    } else {
      return;
    }
  }

  CloseAU() {
    // this.assignUserPopper.hide();
  }

  AssignUsers() {
    if (this.selectedUsers && this.selectedUsers.length) {
      let userDetails = [];

      this.spinner.show('assignUsersLoading');

      this.settingService
        .groupAssignment(this.extractIdsFromUsers(), this.currentGroupId, '1')
        .subscribe(
          (res) => {
            if (res.status == 'ok') {
              if (res.alreadyExistsUser) {
                this.spinner.hide('assignUsersLoading');
                // this.assignUserPopper.hide();
                this.selectedUsers = [];
                this.toastr.success(
                  'User ' + res.alreadyExistsUser + ' already exists in group'
                );
              } else {
                userDetails = this.getUserDataFromId(this.selectedUsers);
                userDetails.map((ur) => {
                  this.settingService
                    .getRolesAgainstUser(ur.id.toString())
                    .subscribe((rl) => {
                      if (rl.status == 'ok') {
                        ur.roles = this.assignRoletoUser(rl);
                      }
                    });
                });

                this.userAgainstGroup =
                  this.userAgainstGroup.concat(userDetails);
                // this.userAgainstGroup.reverse();
                this.userAgainstGroup = Array.from(
                  new Set(this.userAgainstGroup.map((a) => a.id))
                ).map((id) => {
                  return this.userAgainstGroup.find((a) => a.id === id);
                });

                this.userAgainstGroup = this.userAgainstGroup.filter((el) => {
                  return el.status != 'D';
                });

                this.spinner.hide('assignUsersLoading');
                // this.assignUserPopper.hide();
                this.selectedUsers = [];
              }
            } else {
              this.spinner.hide('assignUsersLoading');
            }
          },
          (err) => {
            this.spinner.hide('assignUsersLoading');
          }
        );
    } else {
      return;
    }
  }

  extractIdsFromUsers() {
    const arr = [];
    this.selectedUsers.map((res) => {
      if (res && Object.keys(res).length) {
        arr.push(res.id);
      }
    });
    return arr.join(',');
  }

  getUserDataFromId(userIds) {
    let sendingUsers = [];
    if (userIds && userIds.length) {
      this.users.map((tqe) => {
        userIds.map((gyt) => {
          if (tqe.id == gyt.id) {
            sendingUsers.push(tqe);
          }
        });
      });
    }
    sendingUsers = [
      ...new Map(sendingUsers.map((item) => [item.id, item])).values(),
    ];
    return sendingUsers;
  }

  sortData(sort) {
    const data = this.users.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedData = data;
      return true;
    }

    this.sortedData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'id':
          return this.compare(a.id, b.id, isAsc);
        case 'username':
          return this.compare(a.username, b.username, isAsc);
        case 'email':
          return this.compare(a.email, b.email, isAsc);
        case 'organization':
          return this.compare(a.organization, b.organization, isAsc);
        // case 'user_type': return this.compare(a.user_type, b.user_type, isAsc);
        case 'status':
          return this.compare(a.status, b.status, isAsc);
        case 'date_joined':
          return this.compare(a.date_joined, b.date_joined, isAsc);

        default:
          return 0;
      }
    });
    this.users = data;
    return this.users;
  }

  onPageChange(page: number) {
    this.pageControl = page;
    this.userAgainstGroup.map((res) => (res.selected = false));
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  AddUser() {
    if (this.addUserForm.valid) {
      this.spinner.show('addUserLoading');

      const obj = {
        first_name: this.addUserForm.get('first_name').value,
        last_name: this.addUserForm.get('last_name').value,
        // username: this.addUserForm.get('username').value,
        email: this.addUserForm.get('email').value,
        role:
          this.addUserForm.get('role').value &&
            this.addUserForm.get('role').value.length > 0
            ? this.addUserForm.get('role').value.join(',')
            : '',
        group:
          this.addUserForm.get('group').value &&
            this.addUserForm.get('group').value.length > 0
            ? this.addUserForm.get('group').value.join(',')
            : '',
        organization: this.signedUser.organization,
        status: this.addUserForm.get('selectedUserStatus').value,
        // user_type: this.addUserForm.get('selectedUserType').value,
        user_type: 'RGU',
      };

      const ObjToAppend = {
        first_name: this.addUserForm.get('first_name').value,
        last_name: this.addUserForm.get('last_name').value,
        // username: this.addUserForm.get('username').value,
        email: this.addUserForm.get('email').value,
        roles:
          this.addUserForm.value.role && this.addUserForm.value.role.length
            ? this.addUserForm.value.role
            : [],
        organization: this.signedUser.organization,
        status: this.addUserForm.get('selectedUserStatus').value,
        // user_type: this.addUserForm.get('selectedUserType').value,
        user_type: 'RGU',
      };

      this.settingService.addUser(obj).subscribe(
        (userRes) => {
          if (userRes.status == 'ok') {
            this.addUserForm.reset();
            this.spinner.hide('addUserLoading');
            this.toastr.success('User created successfully!');
            this.goToUserList();

            ObjToAppend['id'] = userRes.id;
            if (
              this.currentGroupId &&
              this.permissions &&
              this.permissions.canAssignGroup
            ) {
              this.userAgainstGroup.unshift(ObjToAppend);

              this.userAgainstGroup = Array.from(
                new Set(this.userAgainstGroup.map((a) => a.id))
              ).map((id) => {
                return this.userAgainstGroup.find((a) => a.id === id);
              });

              this.userAgainstGroup = this.userAgainstGroup.filter((el) => {
                return el.status != 'D';
              });
            }
          }
        },
        (_) => {
          this.spinner.hide('addUserLoading');
        }
      );
    }
  }

  Reset() {
    this.addUserForm.reset();
  }

  Close() {
    // this.assignRolePopper.hide();
    this.assignRoleForm.reset();
  }

  CloseEU() {
    // this.editUserPopper.hide();
    this.editUserForm.reset();
  }

  DeleteUserFromGroup(user) {
    this.dialog
      .open(ConfirmDialogComponent, {
        data: { message: 'Are you sure you want to delete this user from group?' },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.spinner.show('deleteUser');
          this.deleting = true;
          this.matchingId = user.id;
          this.spinner.show('deleteUser');
          const obj = {
            uid: user.id,
            gid: this.currentGroupId,
            status: 'D',
          };
          this.settingService.deleteUser(obj).subscribe(
            (res) => {
              if ((res as any).status == 'ok') {
                const index = this.userAgainstGroup.findIndex(
                  (temp) => temp.id == user.id
                );
                this.userAgainstGroup.splice(index, 1);
                this.toastr.success('User deleted from group successfully!');
                this.deleting = false;
                this.spinner.hide('deleteUser');
              } else {
                this.deleting = false;
                this.spinner.hide('deleteUser');
              }
            },
            (err) => {
              this.deleting = false;
              this.spinner.hide('deleteUser');
            }
          );
        }
      });
  }

  ClearSearch() {
    this.searchTerm = '';
  }

  updateUser() {
    const obj = {
      uid: +this.route.snapshot.params.id,
      first_name: this.addUserForm.get('first_name').value,
      last_name: this.addUserForm.get('last_name').value,
      status: this.addUserForm.get('selectedUserStatus').value,
      // user_type: this.addUserForm.get('selectedUserType').value,
      user_type: 'RGU',
    };

    this.spinner.show('editUserLoading');

    this.settingService.updateUser(obj).subscribe(
      (res) => {
        if (res.status == 'ok') {
          this.toastr.success('User updated successfully!');
          this.spinner.hide('editUserLoading');
          this._router.navigate(['/pages/user-management/users']);
        } else {
          this.spinner.hide('editUserLoading');
        }
      },
      (err) => {
        this.spinner.hide('editUserLoading');
      }
    );
  }

  Cancel() {
    this.addUserForm.reset();
    this.editCase = false;
  }

  WatchDetailsDialog(data) {
    let userData = [];
    this.users.map((res) => {
      if (res.id == data.id) {
        userData.push(res);
      }
    });
    if (userData && !userData.length) {
      userData = [data];
    }
    const organization = this.MapValueOfOrganization(userData[0].organization);

    const dataPassing = [
      { key: 'ID', value: userData[0].id },
      {
        key: 'Full Name',
        value:
          userData[0].first_name || userData[0].last_name
            ? this.CombineNames(userData)
            : '-',
      },
      {
        key: 'Organization',
        value: organization
          .toLowerCase()
          .split(' ')
          .map((word) => {
            return word.charAt(0).toUpperCase() + word.slice(1);
          })
          .join(' '),
      },
      { key: 'Status', value: this.MapValues(userData[0].status, true) },
      {
        key: 'Joined Date',
        value: userData[0].date_joined ? userData[0].date_joined : '-',
      },
      {
        key: 'Created by',
        value: userData[0].created_by
          ? this.TransformUser(userData[0].created_by)
          : '-',
      },
    ];

    this.dialog.open(UserDetailsDialogComponent, {
      panelClass: 'modalWidth',
      data: {
        userDetails: dataPassing,
        organizations: this.organizations,
      },
    });
  }

  CombineNames(data) {
    let str = '';
    str = data[0].first_name + ' ' + data[0].last_name;

    return str;
  }

  MapValueOfOrganization(id) {
    let name = '';
    this.organizations.map((res) => {
      if (res.value == id) {
        name = res.viewValue;
      }
    });
    return name;
  }

  TransformUser(id) {
    let name = '';
    this.users.map((res) => {
      if (res.id == id) {
        name = res.username;
      }
    });
    return name;
  }

  MapId(name) {
    let id: number;
    this.organizations.map((res) => {
      if (res.val.toLowerCase() == name.toLowerCase()) {
        id = res.value;
      }
    });
    return id;
  }

  MapValues(name, keyword) {
    let value = '';
    if (keyword) {
      this.statuses.map((res) => {
        if (res.value.toLowerCase() == name.toLowerCase()) {
          value = res.viewValue;
        }
      });
    } else {
      this.statuses.map((res) => {
        if (res.viewValue.toLowerCase() == name.toLowerCase()) {
          value = res.value;
        }
      });
    }

    return value;
  }

  UnassignRole(role, userId) {
    this.settingService.roleAssignment(userId, role.id, 0).subscribe(
      (res) => {
        if (res.status == 'ok') {
          this.userAgainstGroup.map((res) => {
            if (res.id == userId) {
              const index = res.roles.findIndex(
                (temp) => temp.id == this.assignRoleForm.get('role').value
              );
              res.roles.splice(index, 1);
            }
          });
          this.toastr.success(
            'Role ' + role.name + ' unnassigned successfully!'
          );
        }
      }
    );
  }

  onFocus(ev) {
    this.focused = true;
  }

  AddRole() {
    this.dialog
      .open(AddRoleDialogComponent, {
        panelClass: 'modalWidth',
      })
      .afterClosed()
      .subscribe(
        (data) => {
          if (data && data.status) {
            if (data.newRole.status != 'D') {
              this.roles = [...this.roles, data.newRole];
              this.rolesForEditCase = Array.from(this.roles);
            }
            this.toastr.success('Role added successfully!');
          }
        }
      );
  }

  editRole() {
    this.dialog.open(EditRoleDialogComponent, {
      panelClass: 'modalWidth',
    });
  }

  popperAssignShow(event: Event, user) {
    setTimeout(() => {
      this.userAgainstGroup.forEach((element) => {
        if (element.id == user.id) {
          this.userId = user.id;
        }
      });
    }, 0);
  }

  AssignRole() {
    this.spinner.show('assignRoleLoading');
    this.settingService
      .roleAssignment(this.userId, this.assignRoleForm.get('role').value, 1)
      .subscribe(
        (res) => {
          if (res.status == 'ok') {
            let name = '';
            this.roles.map((res) => {
              if (res.id == this.assignRoleForm.get('role').value) {
                name = res.name;
              }
            });

            this.userAgainstGroup.map((res) => {
              if (res.id == this.userId) {
                if (!res.roles) {
                  res.roles = [];
                }
                res.roles.push({
                  id: this.assignRoleForm.get('role').value,
                  name,
                });

                res.roles = Array.from(new Set(res.roles.map((a) => a.id))).map(
                  (id) => {
                    return res.roles.find((a) => a.id === id);
                  }
                );
              }
            });
            this.toastr.success('Role ' + name + ' assigned successfully!');
            // this.assignRolePopper.hide();
            this.assignRoleForm.get('role').setValue('');
            this.spinner.hide('assignRoleLoading');
            this.userId = undefined;
          } else {
            this.assignRoleForm.get('role').setValue('');
            // this.assignRolePopper.hide();
            this.spinner.hide('assignRoleLoading');

            return;
          }
        },
        (err) => {
          this.assignRoleForm.get('role').setValue('');
          // this.assignRolePopper.hide();
          this.spinner.hide('assignRoleLoading');
        }
      );
  }

  open(): void {
    this.visible = !this.visible;
  }

  close(): void {
    this.visible = false;
  }

  hideOverlay(toggleDropdown) {
    if (toggleDropdown) {
      document.body.setAttribute('class', 'hide-dropdown-overlay');
    } else {
      setTimeout(() => {
        document.body.setAttribute('class', '');
      }, 300);
    }
  }

  goToUserList() {
    this._router.navigate(['/pages/user-management/users']);
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });

    this.errorMessageService.errorMessage.next('');
  }
}
