import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PageNotFoundComponent } from './components/errors/pageNotFound/pageNotFound.component';
import { ForgotPasswordComponent } from './auth/forgot-password/forgot-password.component';
import { NonLfdAdminComponent } from './components/errors/non-lfd-admin/non-lfd-admin.component';
import { RedirectGuard } from './auth/guards/redirect.guard';
import { AuthGuard } from './auth/guards/login.guard';
import { PagesAuthGuard } from './auth/guards/pages-auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    canActivate: [RedirectGuard],
    canLoad: [RedirectGuard],
    loadChildren: () =>
      import('./auth/modules/login.module').then((m) => m.LoginModule),
  },
  // {
  //   path: 'signup',
  //   component:SignUpComponent
  // },
  {
    path: 'forgotPassword',
    component: ForgotPasswordComponent
  },
  {
    path: 'no-admin-access',
    component: NonLfdAdminComponent
  },
  {
    path: 'pages',
    canActivate: [PagesAuthGuard],
    canLoad: [PagesAuthGuard],
    loadChildren: () =>
      import('./modules/corePages.module').then((m) => m.CorePagesModule),
  },
  { path: '**', redirectTo: 'pageNotFound' },
  { path: 'pageNotFound', component: PageNotFoundComponent },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
